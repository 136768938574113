import React from 'react'
import Menus from '@/common/components/Menus'
import { menus } from '@/lib/menus'

export const getMenuData = (activeTab: number) => {
  let menuData: any = menus
  return menuData
}
export default function MenusWrapper() {

  return (
    <Menus menus={menus} useintl={false} />
  )
}
