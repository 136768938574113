
// 当前代码为自动生成, 切勿修改或者手动新增！！
export default {
" Ads Activity": `Ads Activity`,
"+ Add Email Template": `+ Add Email Template`,
"+ Add SMS Template": `+ Add SMS Template`,
"A password reset email has been sent to your mailbox": `A password reset email has been sent to your mailbox`,
"AI Model": `AI Model`,
"ALI": `All`,
"Account": `Account`,
"Account Management": `Account Management`,
"Account/User Name/Email/Phone": `Account/User Name/Email/Phone`,
"Account/User Name/Email/Role": `Account/User Name/Email/Role`,
"Actions": `Actions`,
"Activate": `Activate`,
"Active": `Active`,
"Activity Logs": `Activity Logs`,
"Ad Generation": `Ad Generation`,
"Add": `Add`,
"Add Account": `Add Account`,
"Add Application": `Add Application`,
"Add Attributes": `Add Attributes`,
"Add Description": `Add Description`,
"Add Email Template": `Add Email Template`,
"Add Folder": `Add Folder`,
"Add Frequency": `Add Frequency`,
"Add Graphic message": `Add Graphic message`,
"Add MP QR Code": `Add MP QR Code`,
"Add Measurement Family": `Add Measurement Family`,
"Add Measurement Unit": `Add Measurement Unit`,
"Add New Automatic Reply": `Add New Automatic Reply`,
"Add New Catalog": `Add New Catalog`,
"Add New Chat": `Add New Chat`,
"Add New Page": `Add New Page`,
"Add New Page Categories": `Add New Page Categories`,
"Add New Posts": `Add New Posts`,
"Add New QR Code": `Add New QR Code`,
"Add New Reply Content": `Add New Reply Content`,
"Add Org": `Add Org`,
"Add Post": `Add Post`,
"Add Product": `Add Product`,
"Add Reward": `Add Reward`,
"Add Role": `Add Role`,
"Add Secondary Goal": `Add Secondary Goal`,
"Add Shipping Method": `Add Shipping Method`,
"Add Transition Method": `Add Transition Method`,
"Add URL": `Add URI`,
"Add User": `Add User`,
"Add Users": `Add Users`,
"Add Video": `Add Video`,
"Add Warehouse": `Add Warehouse`,
"Add a New Blank Page": `Add a New Blank Page`,
"Add application": `Add Application`,
"Add email to blocklist": `Add email to blocklist`,
"Add ip to blocklist": `Add ip to blocklist`,
"Add related content": `Add related content`,
"Add related products": `Add related products`,
"Add tax zone": `Add tax zone`,
"Additional": `Additional`,
"Additional quantity (item)": `Additional quantity (item)`,
"Address": `Address`,
"Ads Activity": `Ads Activity`,
"Adyen": `Adyen`,
"After shipment": `After shipment`,
"After-sale": `After-sale`,
"Al Reco Models": `Al Reco Models`,
"AlReco": `AlReco`,
"Ali": `Ali`,
"All": `All`,
"All Channel": `All Channel`,
"All Events": `All Events`,
"All Logs": `All Logs`,
"All Requests": `All Requests`,
"All Subscription": `All Subscription
`,
"All goods in the order must be sent from the same warehouse. In this case it is not allowed to split the order.": `All goods in the order must be sent from the same warehouse. In this case it is not allowed to split the order.`,
"All plan type": `All Process Type`,
"All posts": `All posts`,
"Allow Callback URIs": `Allow Callback URIs`,
"Allow Wildcard * in sign-in URI redirect": `Allow Wildcard * in sign-in URI redirect`,
"App Description": `App Description`,
"App Push": `App Push`,
"App Resource": `App Resource`,
"App Role": `App Role`,
"Application": `Application`,
"Application List": `Application List`,
"Application Name": `Application Name`,
"Application Setting": `Application Setting`,
"Application/Client ID": `Application/Client ID`,
"Applications": `Applications`,
"Applications.Applications": `Applications`,
"Apply Hold Order Setting": `Apply Hold Order Setting`,
"Approve": `Approve`,
"Are you sure delete this feild?": `Are you sure delete this feild?`,
"Are you sure disable it?": `Are you sure disable it?`,
"Are you sure to clear the conversation": `Are you sure to clear the conversation?`,
"Are you sure to deliver immediately?": `Are you sure to generate an order immediately?`,
"Asset Size": `Asset Size`,
"Assets Management": `Assets Management`,
"Assign Users": `Assign Users`,
"Associate": `Associate`,
"Associate Media": `Associate Media`,
"Associate Media Note": `Note: If this method is used, only external link is associated`,
"Associated Logistic Company": `Associated Logistic Company`,
"Associated Physical Warehouse": `Associated Physical Warehouse`,
"Associated Store": `Associated Store`,
"Associated logical warehouse": `Associated logical warehouse`,
"Attempts during broken integration": `Attempts during broken integration`,
"Attribute Name": `Attribute Name`,
"Attributes": `Attributes`,
"Attributes Basic Info": `Attributes Basic Info`,
"Audit Order": `Audit Order`,
"Audit Status": `Audit Status`,
"Audit Users": `Audit Users`,
"Authenticator": `Authenticator`,
"Authorization Code": `Authorization Code`,
"Automatic Replies": `Automatic Replies`,
"Automation gives": `Automation gives`,
"Back Order": `Back Order`,
"Back Order tip": `The total number of orders marked as back orders`,
"Basic Information": `Basic Information`,
"Basic information": `Basic information`,
"Batch Delete": `Batch Delete`,
"Batch Export": `Imp & Exp`,
"Batch Time / Batch ID": `Batch Time / Batch ID`,
"Behaviour": `Behaviour`,
"Billing address": `Billing address`,
"Bind": `Bind`,
"Bind Services": `Bind Services`,
"Blog Engine": `Blog Engine`,
"Branch": `Branch`,
"Brand New Store": `Brand New Store`,
"Brand New Store Information": `Brand New Store Information`,
"Brand.com": `Brand.com`,
"Brand.com tips": `Brand.com is a dynamic content website that showcases in-depth information about brands’ products, services, and unique stories. With engaging articles, expert reviews, and captivating visuals, it offers a comprehensive platform for consumers and enthusiasts to explore and connect with their favorite brands.`,
"Brightness": `Brightness`,
"Broken": `Broken`,
"Build landing pages for ecommerce promotions, paid ad campaigns": `Build landing pages for ecommerce promotions, paid ad campaigns.`,
"Business ID": `Business ID`,
"Business qualification": `Business qualification`,
"CDN URL": `CDN URL`,
"CHANNEL": `Channel`,
"COMING SOON！": `COMING SOON！`,
"CRM": `CRM`,
"CRM AI Conversation": `CRM AI Conversation`,
"Calculate Tax Based on": `Calculate Tax Based on`,
"Campaign Detail": `Campaign Detail`,
"Campaign Engagement": `Campaign Engagement`,
"Campaign Plan": `Campaign Plan`,
"Campaign Plan Overview": `Campaign Plan Overview`,
"Cancel": `Cancel`,
"Canceled": `Canceled`,
"Capture payment after shipping": `Capture payment after shipping`,
"Carrier Setting": `Carrier Setting`,
"Catalog Name": `Catalog Name`,
"Categories": `Categories`,
"Categories: All": `Categories: All`,
"Category": `Category`,
"Category Detail": `Category Detail`,
"Ceate Tags": `Add Tags`,
"Change Logistic Company": `Change Logistic Company`,
"Change Warehouse": `Change Warehouse`,
"Channel": `Channel`,
"Channel Options": `Channel Options`,
"Channel Warehouse": `Channel Warehouse`,
"Character Settings": `Character Settings`,
"Chinese": `简体中文`,
"Choose Media": `Choose Media`,
"Choose Website Type": `Choose Website Type`,
"Choose the characteristics of the orders you want this rule to handle.": `Choose the characteristics of the orders you want this rule to handle.`,
"City": `City`,
"Click to Generate Token": `Click to Generate Token`,
"Click to send": `Click to send`,
"Client Credentials": `Client Credentials`,
"Client ID": `Client ID`,
"Client Name": `Client Name`,
"Client Secret": `Client Secret`,
"Client acting on behalf of a user": `Client acting on behalf of a user`,
"Client acting on behalf of itself": `Client acting on behalf of itself`,
"Clone": `Clone`,
"Close sidebar": `Close sidebar`,
"Code": `Family Code`,
"Collection Name": `Collection Name`,
"Collection Type": `Collection Type`,
"Collections": `Collections`,
"Coming soon": `Coming soon`,
"Comments": `Comments`,
"Compare characteristics of this order with fraudulent orders observed in the past.": `Compare characteristics of this order with fraudulent orders observed in the past.
`,
"Component Name": `Component Name`,
"Configuration": `Configuration`,
"Configure Store Basic Info": `Configure Store Basic Info`,
"Confirm": `Confirm`,
"Connect New Data Stream": `Connect New Data Stream`,
"Consumer": `Consumer`,
"Consumer Detail": `Consumer Detail`,
"Consumer Email is required": `Consumer Email is required`,
"Consumer Experience": `Consumer Experience`,
"Consumer ID": `Consumer ID`,
"Consumer Insights": `Consumer Insights`,
"Consumer Insights List": `Consumer Insights List`,
"Consumer Name is required": `Consumer Name is required`,
"Consumer info": `Consumer info`,
"Consumer paid": `Consumer paid`,
"Consumer phone number is credential personal...": `Consumer phone number is credential personal information data. Please make sure you have the permission to access this data or you may be involved into`,
"Contact": `Contact`,
"Contact Account": `Contact Account`,
"Contact Detail": `Contact Detail`,
"Contact Information": `Contact Information`,
"Contact Insights": `Contact Insights`,
"Contact Insights List": `Contact Insights List`,
"Contact List": `Contact List`,
"Contacts Account": `Contacts Account`,
"Content Experience": `Content Experience`,
"Content Name": `Content Name`,
"Content Types": `Content Types`,
"Contents": `Contents`,
"Continue Shopping": `Continue Shopping`,
"Contrast": `Contrast`,
"Conversational BI Analysis tips": `Conversational BI Analysis, Natural Data Exploration Experience and Intelligent Attribution Tool.`,
"Conversion Operation": `Conversion Operation`,
"Copied": `Copied`,
"Copy": `Copy`,
"Copy Link": `Copy Link`,
"Copy from Current Page Type": `Copy from Current Page Type`,
"Copy from Existed Store Information": `Copy from Existed Store Information`,
"Copy to clipboard": `Copy to clipboard`,
"Country": `Country`,
"Country based": `Country based`,
"Cover Video": `Cover Video`,
"Craft email/ SMS newsletters": `Craft email/ SMS newsletters campaigns and promotions that connect with your customers. Customize your communication to offer exactly what they are looking for.`,
"Creat a Campaign": `Create a Campaign`,
"Creat a Task": `Create a Task`,
"Creat and manage your own promotion for your shop and products on Dtc Center": `Creat and manage your own promotion for your shop and products on Dtc Center`,
"Create Marketing Automation Campaign": `Create Marketing Automation Campaign`,
"Create New Promotion": `Create New Promotion`,
"Create New Segments": `Add New Segments`,
"Create New Voucher": `Create New Voucher`,
"Create Process Canvas": `Create Process Canvas`,
"Create Role": `Create Role`,
"Create SMS Template": `Create SMS Template`,
"Create Task": `Create Task`,
"Create Website": `Create Website`,
"Create a process canvas": `Create a process canvas to customize complex user journeys and marketing strategies.`,
"Create a return order": `Create a return order`,
"Create a task triggerred": `Create a task triggered immediately or later and one time or in a pattern.`,
"Create and manage logistic sourcing rules to determine the most appropriate logistic company for shipping to reduce shipping cost and improve efficiency.": `Create and manage logistic sourcing rules to determine the most appropriate logistic company for shipping to reduce shipping cost and improve efficiency.`,
"Create and manage warehouse sourcing rules to control what node or warehouse should be considered for out-bounding to increase automation capabilities.": `Create and manage warehouse sourcing rules to control what node or warehouse should be considered for out-bounding to increase automation capabilities.`,
"Create collection": `Create collection`,
"Create collection to manage collection template": `Create collection to manage collection template.`,
"Create collections": `Create collections`,
"Create collections for tables of data to be referenced": `Create collections for tables of data to be referenced.`,
"Create component to compose dynamic landing pages without a developer": `Create component to compose dynamic landing pages without a developer.`,
"Create components": `Create components`,
"Create page type to manage page templates": `Create page type to manage page templates.`,
"Create page types": `Create page types`,
"Create pages": `Create pages`,
"Create posts": `Create posts`,
"Create posts for ecommerce article or news": `Create posts for ecommerce article or news.`,
"CreateOrModifyTaxClass": `Create or modify Tax Class`,
"Created SMS template will be submitted to all to review to user as a template.": `Please be aware that this is a requirement by certain SMS vendors, and only reviewed templates can be used.`,
"Created Time": `Created Time`,
"Created time": `Created time`,
"Creation  Data": `Creation  Data`,
"Creation Data": `Creation Data`,
"Credentials & Secrets": `Credentials & Secrets`,
"Credentials & Secrets-alert": `Once the current window is closed the current key cannot be copied. Please save it properly.`,
"Credentials & Secrets-info": `A secret string that the application uses to prove its identity when requesting a token. Also can be referred to as application password.`,
"Currency": `Currency`,
"Currency Format": `Currency Format`,
"Currency format": `Currency format`,
"Currency info": `Maintain the uniform currency type decimal and currency display format of the store`,
"Currency symbol": `Currency symbol`,
"Current Language": `Current Language`,
"Current theme": `Current theme`,
"Custom Product Restrictions": `Custom Product Restrictions`,
"Custom Region Restrictions": `Custom Region Restrictions`,
"Custom logistic priority": `Custom logistic priority`,
"Custom shipping fee": `Custom shipping fee`,
"Custom warehouse weight": `Custom warehouse weight`,
"Customer Insights": `Customer Insights`,
"Customer Insights List": `Customer Insights List`,
"Customer Service": `Customer Service`,
"DATA SOURCE": `DATA SOURCE`,
"DAYS": `DAYS`,
"DELIVRD": `Delivrd`,
"DTC": `DTC`,
"DTC Platform": `DTC Platform`,
"DaoXin": `DaoXin`,
"Data Connector Type": `Data Connector Type`,
"Data Flow": `Data Flow`,
"Data Ingestion": `Data Ingestion`,
"Data Ingestion tips": `Data Ingestion is the first step of building your own CDP.It moves and replicates your consumer source data into a target landing with minimal transformation from various channels.`,
"Data Management": `Data Management`,
"Data Stream Name": `Data Stream Name`,
"Data Stream Status": `Data Stream Status`,
"Data Streams": `Data Streams`,
"Date & Time format": `Date & Time format`,
"Date format": `Date format`,
"Date&Time format": `Date&Time format`,
"Days": `Days`,
"DaysSincetheLastPurchase": `Days Since the Last Purchase`,
"Deactivated": `Inactive`,
"Dectivate": `Dectivate`,
"Default Value": `Default Value`,
"Default Weight": `Default Weight`,
"Default value will be displayed whenattributes value is null.": `Default value will be displayed when attributes value is null.`,
"Delete": `Delete`,
"Delete chat": `Delete chat`,
"Delivery area": `Delivery area`,
"Description": `Description`,
"Descriptions": `Descriptions`,
"Deselect All": `Deselect All`,
"Detail": `Detail`,
"Details": `Details`,
"Device": `Device`,
"Device ID": `Device ID`,
"Device Info": `Device Info`,
"Digit grouping": `Digit grouping`,
"Digital Data Assist": `Digital Data Assist`,
"Disable": `Disable`,
"Discount Promotions": `Discount Promotions`,
"Display timezone": `Display timezone`,
"Distance": `Distance`,
"Distribute Coupon": `Distribute Coupon`,
"Document Type": `Document Type`,
"Domain name is required": `Domain name is required`,
"Done": `Done`,
"Down payment": `Down payment`,
"Download Later": `Download Later`,
"Download invoice": `Download invoice`,
"Draft": `Draft`,
"Drag and Edit Board": `Visual Edit`,
"Duplicate": `Duplicate`,
"Duration of stay": `Duration of stay`,
"EC Activity": `EC Activity`,
"EMAIL": `EMAIL`,
"EXPIRED": `Expired`,
"EXTERNAL": `External`,
"Ecommerce": `Ecommerce`,
"Ecommerce tips": `E-commerce is a thriving online marketplace that enables brands to buy and sell products or services through a digital platform, providing a seamless and accessible shopping experience from the comfort of their homes.`,
"Edit": `Edit`,
"Edit Account": `Edit Account`,
"Edit Automatic Reply": `Edit Automatic Reply`,
"Edit Board": `Edit Board`,
"Edit Catalog": `Edit Catalog`,
"Edit Email Template": `Edit Email Template`,
"Edit Frequency": `Edit Frequency`,
"Edit Measurement Family": `Edit Measurement Family`,
"Edit Measurement Unit": `Edit Measurement Unit`,
"Edit Model": `Edit Model`,
"Edit Org": `Edit Org`,
"Edit Page Categories": `Edit Page Categories`,
"Edit Reply Content": `Edit Reply Content`,
"Edit SMS Template": `Edit SMS Template`,
"Edit Shipping Method": `Edit Shipping Method`,
"Edit Tags": `Edit Tags`,
"Edit Task": `Edit Task`,
"Edit Transition Method": `Edit Transition Method`,
"Edit error massage": `Edit error massage`,
"Edit tax zone": `Edit tax zone`,
"Email": `Email`,
"Email BlockList": `Email BlockList`,
"Email Template": `Email Template`,
"Email Template Description": `Email Template Description`,
"Email Template Detail": `Email Template Detail`,
"Email Template Name": `Email Template Name`,
"End": `End`,
"End date": `End date`,
"Endrollment date": `Endrollment date`,
"Enter Q&A Chatbot details": `Enter Q&A Chatbot details`,
"Enter price": `Enter price`,
"Entity": `Entity`,
"Entity Detail": `Entity Detail`,
"Entity List": `Entity List`,
"Entity.Entity ID": `Entity ID`,
"Entity.Entity Name": `Entity Name`,
"Entity.Entity Type": `Entity Type`,
"Entry Setting": `Entry Setting`,
"Error": `Error`,
"Error Message": `Error Message`,
"Event": `Event`,
"Event Attribute": `Event Attribute`,
"Event Detail": `Event Detail`,
"Event Edit": `Event Edit`,
"Event Type": `Event Type`,
"Event Type  App  Account": `Event Type/App/Account`,
"EventAdd-Info": `Only lowercase numbers and underscores in English are supported and must start with an English letter and cannot underscores ending`,
"EventGrid": `EventGrid`,
"Events": `Events`,
"Every Day": `Every Day`,
"Every Month": `Every Month`,
"Every Week": `Every Week`,
"Except for the specified region the default freight is used for other regions": `Except for the specified region the default freight is used for other regions`,
"Exception handling": `Exception handling`,
"Exception type": `Exception type`,
"Exclusive of tax": `Exclusive of tax`,
"Expire Date": `Expire Date`,
"Expires": `Expires`,
"Export status": `Export status`,
"External URL": `External URL`,
"Facebook": `Facebook`,
"Fail": `Fail`,
"Failure": `Failure`,
"Fans Detail": `Fans Detail`,
"Fans Management": `Fans Management`,
"Faster editing": `Faster editing`,
"Faster time to market": `Faster time to market`,
"Featured Image": `Featured Image`,
"Featured Image Alt Text": `Featured Image Alt Text`,
"File name": `File name`,
"Filters & Sort": `Filters & Sort`,
"Finance": `Finance`,
"First Name": `First Name`,
"First name": `First name`,
"Fold": `Fold`,
"Follow-up": `Follow-up`,
"Follow-up.content": `Please contact the consumer to verify their identity and the purchase before you fulfill order`,
"Forever": `Forever`,
"Format": `Format`,
"Fraud Analysis": `Fraud Analysis`,
"Fraud Criteria": `Fraud Criteria`,
"Freight rule": `Freight rule`,
"Frequency": `Frequency`,
"Frequency setting": `Frequency setting
`,
"Friday": `Friday`,
"From customers": `From {arg1} customers`,
"Fulfilled": `Fulfilled`,
"Fulfilled tip": `Total quantity of orders that order is "shipped", "completed"`,
"Fulfillment": `Fulfillment`,
"Fulfillment Setting": `Fulfillment Setting`,
"Fulfillmentlist": `Inventory Network`,
"Fulfilment Experience": `Fulfillment Experience`,
"Full Access": ` Full Access`,
"Function": `Function`,
"General": `General`,
"General Setting": `General Setting`,
"Generate New Secret": `Generate New Secret`,
"Generate New Token": `Generate New Token`,
"Generate New Token Info1": `1. In the DTC Verify authenticator application, click + and enter the settings keyin sequence.`,
"Generate New Token Info2": `2. Enter your email address or phone number and the following key (spaces donot affect).`,
"Generate New Token Info3": `3. Click 'Add' to complete the settings`,
"Generate Token": `Generate Token`,
"Generate Website": `Generate Website`,
"Generate pictures": `Generate pictures`,
"Gift Card Management": `Gift Card Management`,
"Give priority to delivery from warehouse with large inventory": `Give priority to delivery from warehouse with large inventory`,
"Give priority to delivery from warehouse with low freight cost": `Give priority to delivery from warehouse with low freight cost`,
"Global Setting": `Global Setting`,
"Goal Completion Rate": `Goal Completion Rate`,
"Goal Setting": `Goal Setting`,
"Grant Type": `Grant Type`,
"Group": `Group`,
"Guest": `Guest`,
"HOURS": `HOURS`,
"Header": `Header`,
"Headless CMS": `Headless CMS`,
"Height": `Height`,
"High.Risk": `High Risk`,
"Hold": `Hold`,
"Hold tip": `The total number of orders applied hold`,
"Hours": `Hours`,
"How would you like to get started": `How would you like to get started?`,
"ID Mapping": `ID Mapping`,
"ID Mapping tips": `Generate Unique ID to connect different ID from different channels`,
"IDENTIFICATION": `IDENTIFICATION`,
"INTERNAL": `Internal`,
"IP": `IP`,
"If nothing is selected below, then by default only ordeline fields are exported": `If nothing is selected below, then by default only ordeline fields are exported`,
"If the following conversion events are completed within": `If the following conversion events are completed within `,
"Image Preview": `Image Preview`,
"Implicit (hybrid)": `Implicit (hybrid)`,
"In Progress": `In Progress`,
"Inactive Subscription": `Cancel Subscription`,
"Inactive Subscription.description": `Are you sure you want to cancel this subscription?`,
"Inclusive of tax": `Inclusive of tax`,
"Information": `Information`,
"Initial": `Initial`,
"Initial quantity (item)')": `Initial quantity (item)')`,
"Input Account": `Input Account`,
"Input Address": `Input Address`,
"Input Application Name": `Input Application Name`,
"Input City": `Input City`,
"Input Client ID": `Input Client ID`,
"Input Client Secret": `Input Client Secret`,
"Input Country": `Input Country`,
"Input Description": `Input Description`,
"Input Email": `Input Email`,
"Input First Name": `Input First Name`,
"Input Last Name": `Input Last Name`,
"Input Legal Entity": `Input Legal Entity`,
"Input Length": `Input Length`,
"Input Phone Number": `Input Phone Number`,
"Input Postcode": `Input Postcode`,
"Input Store name": `Input Store name`,
"Input Value": `Input Value`,
"Input prefix": `Input prefix`,
"Input suffix": `Input suffix`,
"Insert Attributes": `Insert Attributes`,
"Instagram": `Instagram`,
"Integration Portal": `Integration Portal`,
"Intelligent Recommendation": `Intelligent Recommendation`,
"Interface ID": `Interface ID`,
"Interface Name": `Interface Name`,
"International/ Hongkong Macao or Taiwan Messages": `International/ Hongkong Macao or Taiwan Messages`,
"Inventory": `Inventory`,
"Inventory Management": `Inventory Management`,
"Inventory Setting": `Inventory Setting`,
"Inventory Setup": `Inventory Setup`,
"Inventory depth": `Inventory depth`,
"Invoice List": `Invoice List`,
"Invoker": `Invoker`,
"Job Center": `Job Center`,
"Job Detail": `Job Detail`,
"Job List": `Job List`,
"Judge the distance between the warehouse and the order receiving address and give priority to shipping from the nearest warehouse": `Judge the distance between the warehouse and the order receiving address and give priority to shipping from the nearest warehouse`,
"KDL": `KDL`,
"Kernel sampling (top_p)": `Kernel sampling (top_p)`,
"Kernel tips": `Similar to randomness, but do not change it together with randomness`,
"Key Highlights Of Our Functions": `Key Highlights Of Our Functions`,
"Key Highlights Of Our Functions.four": `Developed using key ecosystem player LLMs, including <span class="font-bold">NVIDIA, Google, AWS</span>, and <span class="font-bold">OpenAI</span>`,
"Key Highlights Of Our Functions.one": `Crafted to align with the sector’s <span class="font-bold">unique transformational drivers</span> and<span class="font-bold"> focus areas</span>, while leveraging <span class="font-bold">Gen AI Solutions</span>`,
"Key Highlights Of Our Functions.subTitle": `Crafted to align with the sector’s unique transformational drivers and focus areas, while leveraging Gen AI Solutions`,
"Key Highlights Of Our Functions.three": `Designed to be <span class="font-bold">readily deployable</span> and can be<span class="font-bold"> easily customized</span> to suit the unique needs of your organization`,
"Key Highlights Of Our Functions.two": `Developed to showcase benefits, that help you make an informed choice on how GenAI can <span class="font-bold">add value</span> to your organization`,
"LOGICAL": `Logical`,
"Label": `Label`,
"Language": `Language`,
"Language: All": `Language: All`,
"Last 180 Days": `Last 180 Days`,
"Last 30 Days": `Last 30 Days`,
"Last 7 Days": `Last 7 Days`,
"Last Modified": `Last Modified`,
"Last Name": `Last Name`,
"Last Outbound Time": `Last Outbound Time`,
"Last edited": `Last edited`,
"Last name": `Last name`,
"Last180Days": `Last 180 Days`,
"Last30Days": `Last 30 Days`,
"Last7Days": `Last 7 Days`,
"Layout: YYYYMMDDHHMMSS": `Layout: YYYYMMDDHHMMSS`,
"Legal Entity": `Legal Entity`,
"Length": `Length`,
"Live Streaming": `Live Streaming`,
"Locale Information": `Locale Information`,
"Location": `Location`,
"Log Date": `Log Date`,
"Logical": `Logical`,
"Logical  Warehouse Name": `Logical  Warehouse Name`,
"Logical Warehouse": `Logical Warehouse`,
"Logical Warehouse Name": `Logical Warehouse Name`,
"Logistic": `Logistic`,
"Logistic Company": `Logistic Company`,
"Logistic Company Name": `Logistic Company Name`,
"Logistic Priority": `Logistic Priority`,
"Logistic Sourcing Rule": `Logistic Sourcing Rule`,
"Logistic Sourcing Rule List": `Logistic Sourcing Rule List`,
"Logistic Sourcing Rules": `Logistic Sourcing Rules`,
"Logistics Company Name": `Logistics Company Name`,
"Logistics information synchronization": `Logistics information synchronization`,
"Low Freight Cost": `Low Freight Cost`,
"Low.Risk": `Low Risk`,
"Loyalty Programs": `Loyalty Programs`,
"MA Experience": `MA Experience`,
"MFA": `MFA`,
"MINI_PROGRAM": `Wechat Mini Program`,
"MINUTES": `MINUTES`,
"MKT Experience": `MKT Experience`,
"MONTHS": `MONTHS`,
"MP Banner Add": `MP Banner Add`,
"MP Banner Detail": `MP Banner Detail`,
"MP QR Code Detail": `MP QR Code Detail`,
"Mail sending failed": `Mail sending failed. Please try again later`,
"Main": `Main`,
"Main Process": `Main Process`,
"Manage": `Manage`,
"Marketing": `Marketing`,
"Marketing Automation Experience": `Marketing Automation Experience`,
"Marketing Calendar": `Marketing Calendar`,
"Marketing Center": `Marketing Center`,
"Marketing Experience": `Marketing Experience`,
"Measurement Family": `Measurement Family`,
"Measurement Family info": `The Measurement Family mainly defines different types of unitseach family type will define a standard unit as the benchmark`,
"Measurement Unit": `Measurement Unit`,
"Measurement families": `Measurement families`,
"Measurements": `Measurements`,
"Measurements info": `A Unit of measure is the actual unit in which the associated values are measured that is used as a standard for measurement of the same kind of quantity.`,
"Media Name": `Media Name`,
"Medium.Risk": `Medium Risk`,
"Member": `Member`,
"Menu Management": `Menu Management`,
"MenuManage Add": `MenuManage Add`,
"MenuManage Detail": `Menu Manage Detail`,
"Merge Order": `Merge Order`,
"Merge Order Setting": `Merge Order Setting`,
"Message Center": `Message Center`,
"Message Detail": `Message Detail`,
"Message list": `Message list`,
"Meta Data": `Meta Data`,
"Meta Description": `Meta Description`,
"Method": `Method`,
"Middle Layer": `Middle Layer`,
"Middle name": `Middle name`,
"Mini Program": `Mini Program`,
"Mini Program Banner": `Mini Program Banner`,
"Mini Program QR Code": `Mini Program QR Code`,
"Mins": `Mins`,
"Missing Error Message": `Missing Error Message`,
"Missing email": `Missing email`,
"Missing ip": `Missing ip`,
"Missing validate": `Missing validate`,
"Model Detail": `Model Detail`,
"Module": `Module`,
"Monday": `Monday`,
"Months": `Months`,
"Multi-FactorAuthentication is enabled": `Multi-FactorAuthentication is enabled`,
"My Orders": `My Orders`,
"My Shipment": `My Shipment`,
"My Subscriptions": `My Subscriptions`,
"My device": `My device`,
"Name": `Name`,
"New Page": `Create New Page`,
"New Return Order": `New Return Order`,
"New Sales Order": `New Sales Order`,
"New Tag": `New Tag`,
"New chat": `New chat`,
"New tax zone": `New tax zone`,
"Next Refill Date": `Next Refill Date`,
"No Access": `No Access`,
"No restrictions on the same warehouse": `No restrictions on the same warehouse`,
"No results found": `No results found`,
"No technology lock-in": `No technology lock-in`,
"No.": `No.`,
"No. of digits after decimal": `No. of digits after decimal`,
"Node": `Node`,
"Not Started": `Not Started`,
"Note:  These information needs to be updated in Organization Module of Global Setting": `Note:  These information needs to be updated in Organization Module of Global Setting`,
"Note: The value is filled in as an integer and the priority is greater than 0; The lower the value the higher the priority.": `Note: The value is filled in as an integer and the priority is greater than 0; The lower the value the higher the priority.`,
"Notification": `Notification`,
"Notification SMS": `Notification SMS`,
"Number Format": `Number Format`,
"Number format": `Number format`,
"OKTA": `OKTA`,
"OS": `OS`,
"Objects": `Objects`,
"Omni-channel": `Omni-channel`,
"One Time": `One Time`,
"One Year": `One Year`,
"Online Store Creation": `Online Store Creation`,
"Online store": `Online store`,
"Open Platform": `Open Platform`,
"Open sidebar": `Open sidebar`,
"Operation": `Operation`,
"Options": `Options`,
"Order": `Order`,
"Order Audit": `Order Audit`,
"Order Characteristics": `Order Characteristics`,
"Order Close Time": `Order Close Time`,
"Order Detail": `Order Detail`,
"Order Flow Name": `Order Flow Name`,
"Order Line": `Order Line`,
"Order List": `Order List`,
"Order Management System (OMS)": `Order Management System (OMS)`,
"Order Management System (OMS) description": `It mainly manages the entire life cycle of sales and return orders. OMS platforms provide a single centralized system for managing orders from multiple sales channels. It simplifies the buying process for customers and makes it easier to manage orders inventory fulfillment and return for business.`,
"Order Metrics": `Order Metrics`,
"Order Monitor Details": `Order Monitor Details`,
"Order Setting": `Order Setting`,
"Order With 0 Price": `Order With 0 Price`,
"Order date": `Order date`,
"Order export status": `Order export status`,
"Order flows.description": `Order flows allow to combine and arrange a group of statuses into a logical order flow.`,
"Order source": `Order source`,
"Order with Promotion": `Order with Promotion`,
"Order.Total amount": `Total amount`,
"Orders": `Orders`,
"Orders with Voucher": `Orders with Voucher`,
"Orders.Card type": `Card type`,
"Org": `Org`,
"Org Code": `Org Code`,
"Org Detail": `Org Detail`,
"Org Management": `Org Management`,
"Org Management info": `Mainly provide organization management capability for the platform including management of Organization Brand Users Roles Company Setting Permission etc.`,
"Org Name": `Org Name`,
"Org Setting": `Org Setting`,
"Org Setting info": `Provide organization management capability for the platform.`,
"Org Type": `Org Type`,
"Organizations": `Organizations`,
"Original URL": `Original URL`,
"Outbound Management": `Outbound Management`,
"Ownership": `Ownership`,
"PCDP100000": `Are you sure to delete tag {arg1}?`,
"PCDP100001": `Are you sure to delete segment {arg1}?`,
"PFE100000": `Are you sure to apply hold order {arg1}?`,
"PFE100001": `Are you sure to resolve hold for order {arg1}?`,
"PFE100002": `Are you sure to delete {arg1} warehouse?`,
"PFE100003": `Are you sure to delete {arg1} warehouse rule?`,
"PFE100004": `Are you sure to delete {arg1} logistic rule?`,
"PGS100000": `Are you sure to activate user {arg1}?`,
"PGS100001": `Are you sure to deactivate user {arg1}?`,
"PGS1000018": `Are you sure to disable DTC Tracking?`,
"PGS100002": `Are you sure to resend password email for {arg1}?`,
"PGS100003": `Are you sure to delete {arg1} ?`,
"PGS100004": `Are you sure to delete {arg1}?`,
"PGS100005": `Are you sure to enable {arg1}?`,
"PGS100006": `Are you sure to delete {arg1}?`,
"PGS100007": `Are you sure to copy {arg1}?`,
"PGS100008": `Are you sure to delete {arg1}?`,
"PGS100009": `Are you sure to enable DTC Tracking?`,
"PGS100010": `Are you sure to delete {arg1}?`,
"PGS100011": `Are you sure to enable {arg1}?`,
"PGS100012": `Are you sure to enable {arg1}?`,
"PGS100013": `Are you sure to enable {arg1}?`,
"PGS100014": `Are you sure to delete {arg1}?`,
"PGS100015": `Are you sure to enable  {arg1}?`,
"PGS100016": `Are you sure to enable {arg1}?`,
"PGS100017": `Are you sure to disable {arg1}?`,
"PGS100019": `Are you sure to disable {arg1}?`,
"PGS100020": `Are you sure to disable {arg1}?`,
"PGS100021": `Are you sure to disable {arg1}?`,
"PGS100022": `Are you sure to disable {arg1}?`,
"PGS100023": `Are you sure to disable {arg1}?`,
"PHYSICAL": `Physical`,
"PMA100000": ``,
"PMA100001": `Are you sure to pause the campaign {arg1}?`,
"PMA100002": `Are you sure to restart the campaign {arg1}?`,
"POWERFUL MARKETING AUTOMATION": `POWERFUL MARKETING AUTOMATION`,
"PSE100000": `Are you sure to delete {arg1}?`,
"PSE100001": `Are you sure to delete {arg1} order flow?`,
"PSE100002": `Are you sure to complete order {arg1}?`,
"PSE100003": `Are you sure to reject return order {arg1}?`,
"PSE100004": `Are you sure to confirm return order {arg1}  has been shipped?`,
"PSE100005": `Are you sure to confirm return order {arg1}  has  been received?`,
"PSE100006": `Change the first sentence to: For return order {arg1},`,
"PSE100007": `Are you sure to delete {arg1} frequency?`,
"PSE100008": `Are you sure to pause subscription {arg1}?`,
"PSE100009": `Are you sure to restart subscription {arg1}?`,
"PSE100010": `Are you sure to inactive subscription {arg1}?`,
"PSE100011": `Are you sure to deliver subscription {arg1} immediately?`,
"PSE100012": `Are you sure to disable {arg1}?`,
"PSE100013": `Are you sure to enable {arg1}?`,
"PSE100014": `Are you sure to delete {arg1} from your cart?`,
"PSE100015": `Are you sure to remove all products from your cart?`,
"PSE100016": ``,
"PSE100017": `Are you sure to disable top suggestions {arg1}?`,
"PSE100018": `Are you sure to enable tag {arg1}?`,
"PSE100019": `Are you sure you want to end the voucher {arg1}?`,
"PSE100020": `Are you sure to approve return order {arg1}?`,
"PSE100021": `Are you sure to enable top suggestions {arg1}?`,
"PSE100024": `Are you sure to  disable tag {arg1}?`,
"PSE100025": `Are you sure to disable All Top Search?`,
"PSE100026": `Are you sure to enable All Top Search?`,
"PSP Payment status": `PSP Payment status`,
"Page Categories": `Page Categories`,
"Page Categories Name": `Page Categories Name`,
"Page List": `Page List`,
"Page Name": `Page Name`,
"Page Publish Notification": `Page Publish Notification`,
"Page Type": `Page Type`,
"Pages": `Pages`,
"Parcellab": `Parcellab`,
"Parent category": `Parent category`,
"Partially delivered": `Partially delivered`,
"Partner": `Partner`,
"Partner Detail": `Partner Detail`,
"Partner List": `Partner List`,
"Partner Type": `Partner Type`,
"Partner.Address": `Address`,
"Partner.Description": `Description`,
"Partner.Latitude": `Latitude`,
"Partner.Longitude": `Longitude`,
"Partner.Partner Address": `Partner Address`,
"Partner.Partner ID": `Partner ID`,
"Partner.Partner Name": `Partner Name`,
"Partner.Partner Type": `Partner Type`,
"Partner.Prescriber status": `Prescriber status`,
"Partner.Remove from partner list?": `Remove from partner list?`,
"Partner.Type ID": `Type ID`,
"Partner.Type Name": `Type Name`,
"Partners": `Partners`,
"Pass": `Pass`,
"Password": `Password`,
"Paused": `Paused`,
"Payload": `Payload`,
"Payment": `Payment`,
"Payment Settings": `Payment Settings`,
"Payment amount": `Payment amount`,
"Payment successful": `Payment successful`,
"Pending Review": `Pending Review`,
"Permissions": `Permissions`,
"Personal Information": `Personal Information`,
"Personalize your contents": `Personalize your contents`,
"Pesonalized Content Marketing": `Pesonalized Content Marketing`,
"Pet Detail": `Pet Detail`,
"Phone": `Phone`,
"Phone Number": `Phone Number`,
"Physical": `Physical`,
"Physical Warehouse Name": `Physical Warehouse Name`,
"Platform": `Platform`,
"Platform type": `Platform type`,
"Please Select": `Please Select`,
"Please confirm to execute which audit option.": `Please confirm to execute which audit option.`,
"Please enter any questions you want to enter": `Please enter any questions you want to enter`,
"Please enter correct email": `Please enter correct email`,
"Please enter the correct URL": `Please enter the correct URL`,
"Please enter your phone number": `Please enter your phone number`,
"Please input Account!": `Please input Account!`,
"Please input Application": `Please input Application`,
"Please input Application Name!": `Please input Application Name!`,
"Please input Client ID!": `Please input Client ID`,
"Please input Client Secret!": `Please input Client Secret`,
"Please input Description!": `Please input Description!`,
"Please input Email!": `Please input Email!`,
"Please input First Name!": `Please input First Name!`,
"Please input Last Name!": `Please input Last Name!`,
"Please input Legal Entity!": `Please input Legal Entity!`,
"Please input Length!": `Please input Length`,
"Please input Number format!": `Please input Number format!`,
"Please input Phone Number!": `Please input Phone Number!`,
"Please input Role Name!": `Please input Role Name!`,
"Please input Store name!": `Please input Store name!`,
"Please input URL": `Please input URL`,
"Please input Value!": `Please input Value!`,
"Please input default value": `Please input default value`,
"Please input prefix": `Please input prefix`,
"Please input suffix": `Please input suffix`,
"Please input task ID ": `Please input recipient contact`,
"Please search plan name": `Please search campaign plan name`,
"Please select City!": `Please select City!`,
"Please select Country!": `Please select Country!`,
"Please select Currency format!": `Please select Currency format!`,
"Please select Date format!": `Please select Date format!`,
"Please select Default Weight!": `Please select Default Weight!`,
"Please select Digit grouping!": `Please select Digit grouping!`,
"Please select Expires!": `Please select Expires!`,
"Please select Language!": `Please select Language!`,
"Please select No of digits after decimal!": `Please select No of digits after decimal!`,
"Please select Region": `Please select Region`,
"Please select Tag": `Please select`,
"Please select Time format!": `Please select Time format!`,
"Please select Unit System!": `Please select Unit System!`,
"Please select User Type!": `Please select User Type!`,
"Please select attribute name": `Please select attribute name`,
"Points": `Points`,
"Points of Sales": `Points of Sales`,
"Post Content": `Post Content`,
"Post Detail": `Post Detail`,
"Postal Address": `Postal Address`,
"Postcode": `Postcode`,
"Posts": `Posts`,
"Pre shipment": `Pre shipment`,
"Preffered language": `Preferred language`,
"Preview": `Preview`,
"Preview on mobile": `Preview on mobile`,
"Pricing method": `Pricing method`,
"Primary Goal": `Primary Goal`,
"Principal Attribute": `Principal Attribute`,
"Priority": `Priority`,
"Process Canvas": `Process Canvas`,
"Process Category": `Process Category`,
"Process Description": `Process Description`,
"Process Name": `Process Name`,
"Process Name Group": `Status/Name/Start and End Time`,
"Process Order": `Process Order`,
"Process Return Order": `Process Return Order`,
"Process status": `Process status`,
"Process type": `Process type`,
"Product": `Product`,
"Product Categories": `Product Categories`,
"Product Category": `Product Category`,
"Product Categrey": `Product Category`,
"Product Detail": `Product Detail`,
"Product List": `Product List`,
"Product Restrictions": `Product Restrictions`,
"Product Search": `Product Search`,
"Product category": `Product category`,
"Product is required": `Product is required`,
"Profile": `Profile`,
"Profile Attributes": `Profile Attributes`,
"Promotion": `Promotion`,
"Promotion Detail": `Promotion Detail`,
"Promotion calculation": `Promotion calculation`,
"Promotional SMS": `Promotional SMS`,
"Promotions.delete": `Delete`,
"Promotions.start": `Restart`,
"Promotions.stop": `Stop`,
"Promotions.termination": `Termination`,
"Provide suggestions when consumers enter address": `Provide suggestions when consumers enter address`,
"Provider": `Provider`,
"Province": `Province`,
"Publish": `Publish`,
"Publish Confirmation": `Publish Confirmation`,
"Publish Date": `Publish Date`,
"Published": `Published`,
"Push Category": `Push Category`,
"Push Channel": `Push Channel`,
"Push again": `Push again`,
"Q&A Chatbot": `Q&A Chatbot`,
"Q&A Chatbot tips": `Please feel free to ask any questions regarding the DTC platform.`,
"QR Code Management": `QR Code Management`,
"Quality": `Quality`,
"Quality Base": `Quality Base`,
"Quantity must be greater than 1": `Quantity must be greater than 1
`,
"Qucik Task": `Qucik Task`,
"Quick Task": `Quick Task`,
"R12PurchaseAmount": `R12 Purchase Amount`,
"R12PurchaseFrequency": `R12 Purchase Frequency`,
"RUNNING": `Running`,
"Random Number": `Random Number`,
"Randomness (temperature)": `Randomness (temperature)`,
"Randomness tips": `The higher the value, the more random the reply will be`,
"Rate": `Rate`,
"Re-try requests automatically": `Re-try requests automatically`,
"Read Access": `Read Access`,
"Real Time Edit": `Real Time Edit`,
"Recommendation History": `Recommendation History`,
"Recommended:180 days(6 months)": `Recommended:180 days(6 months)`,
"Reduce technical debt": `Reduce technical debt`,
"Reference ID": `Reference ID`,
"Referrals": `Referrals`,
"Refresh Token": `Refresh Token`,
"Region": `Region`,
"Region / Langeuage": `Region / Langeuage`,
"Region / Language": `Region / Language`,
"Region / Language info": `Set a unified region language and time zone configuration for the store`,
"Region Restrictions": `Region Restrictions`,
"Region/ Language": `Region/ Language`,
"Reject": `Reject`,
"Related Contents": `Related Contents`,
"Remain": `Remain`,
"Rename": `Rename`,
"Renewal fee": `Renewal fee`,
"Repeated": `Repeated`,
"Reply Content": `Reply Content`,
"Request Detail": `Request Detail`,
"Request ID": `Request ID`,
"Request Time": `Request Time`,
"Request Url": `Request Url`,
"Request body": `Request body`,
"Resend Password Email": `Resend Password Email`,
"Resend in": `Resend in`,
"Resources": `Resources`,
"Response": `Response`,
"Response body": `Response body`,
"Return Order": `Return Order`,
"Reviewing": `Reviewing`,
"Reward Detail": `Reward Detail`,
"Reward History": `Reward History`,
"Reward Settings": `Reward Settings`,
"Risk Level Setting": `Risk Level Setting`,
"Role": `Role`,
"Role Code": `Role Code`,
"Role Name": `Role Name`,
"Role Name/Role code": `Role Name/Role code`,
"Roles": `Roles`,
"Roles that belong to the current application": `Roles that belong to the current application`,
"Rotate": `Rotate`,
"Rule Name": `Rule Name`,
"Rule Setting": `Rule Setting`,
"Running": `Running`,
"SCDP100000": `Single Event {arg1} has been added successfully.`,
"SCDP100001": `Event Attribute {arg1} has been added successfully.`,
"SCDP100002": `Principal Attribute {arg1} has been added successfully.`,
"SCDP100003": `Transaction Attribute {arg1} has been added successfully.`,
"SCDP100004": `Tag {arg1} has been edited successfully.`,
"SCDP100005": `Tag {arg1} has been deleted successfully.`,
"SCDP100006": `Tag {arg1} has been updated successfully.`,
"SCDP100007": `Tag {arg1} has been added successfully.`,
"SCDP100008": `Segment {arg1} has been added successfully.`,
"SCDP100009": `Segment {arg1} has been deleted successfully.`,
"SCDP100010": `Segment {arg1} has been edited successfully.`,
"SCDP100011": `Segment {arg1} has been added successfully.`,
"SCDP100012": `Job {arg1} has been paused/run successfully.`,
"SCE100000": `Theme has been switched to {arg1} .`,
"SCE100001": `Post {arg1} has been cloned successfully.`,
"SCE100002": `Post {arg1} has been deleted successfully.`,
"SCE100003": `Post {arg1} has been published ssuccessfully.`,
"SCE100004": `Post {arg1} has been saved as draft successfully.`,
"SCE100005": `Post {arg1} has been unpublished successfully.`,
"SCE100006": `Post category {arg1} has been created successfully.`,
"SCE100007": `Post category {arg1} has been deleted successfully.`,
"SCE100008": `Post tag {arg1} has been created successfully.`,
"SCE100009": `Post tag {arg1} has been deleted successfully.`,
"SCE100010": `Page {arg1} has been published successfully. Please be aware that this page needs to be audited before display in the shop.`,
"SCE100011": `Page {arg1} has been published successfully.`,
"SCE100012": `Page {arg1} has been audited successfully,`,
"SCE100013": `Page {arg1} has been offline successfully.`,
"SCE100014": `Page {arg1} has been online successfully.`,
"SCE100015": `Page {arg1} has been deleted successfully.`,
"SCE100016": `Page folder {arg1} has been created successfully.`,
"SCE100017": `Page folder {arg1} info has been updated successfully.`,
"SCE100018": `Page folder {arg1} has been deleted successfully.`,
"SCE100019": `Collection {arg1} has been deleted successfully.`,
"SCE100020": `Collection {arg1} has been saved as draft successfully.`,
"SCE100021": `Collection {arg1} has been published successfully.`,
"SCE100022": `Components have been synchronized successfully.`,
"SCE100023": `Collection {arg1} has been created successfully.`,
"SCE100024": `Collection {arg1} has been deleted successfully.`,
"SCE100025": `Collection {arg1} has been updated successfully.`,
"SCE100026": `Component {arg1} has been created successfully.`,
"SCE100027": `Component {arg1} has been deleted successfully.`,
"SCE100028": `Component {arg1} has been updated successfully.`,
"SCE100029": `Page type {arg1} has been created successfully.`,
"SCE100030": `Page type {arg1} has been deleted successfully.`,
"SCE100031": `Page type {arg1} has been updated successfully.`,
"SCE100032": `Meta data has been updated successfully.`,
"SCE100033": `Assets catalog {arg1} has been created successfully.`,
"SCE100034": `Assets catalog {arg1} has been updated successfully.`,
"SCE100035": `Assets catalog {arg1} has been deleted successfully.`,
"SCE100036": `Assets {arg1} has been uploaded successfully.`,
"SCE100037": `Assets {arg1} has been deleted successfully.`,
"SCE100038": `Meta data {arg1} has been deleted successfully.`,
"SCE100039": `Meta data {arg1} has been created successfully.`,
"SCE100040": `Meta data {arg1} has been created successfully`,
"SCE100041": `Webhook changes have been saved successfully.`,
"SCE100042": `Webhook {arg1} changes have been deleted successfully.`,
"SCE100043": `CDN {arg1} has been turned {arg2} successfully.`,
"SENDGRID": `SENDGRID`,
"SEO Title": `SEO Title`,
"SEO/SEM": `SEO/SEM`,
"SFE100000": `Order {arg1} has been created successfully.`,
"SFE100001": `Tag {arg2} has been added to Order {arg1} successfully.`,
"SFE100002": `Order {arg1} has been approved successfully.`,
"SFE100003": `Order {arg1} has been rejected successfully.`,
"SFE100004": `Order {arg1} has been held successfully.`,
"SFE100005": `Order {arg1} has been removed hold successfully.`,
"SFE100006": `Order {arg1} has been updated to back order.`,
"SFE100007": `Order {arg1} has been cancelled successfully.`,
"SFE100008": `remark in Order {arg1} have been changed successfully.`,
"SFE100009": `Invoice {arg1} has been dowloaded successfully.`,
"SFE100010": `Audit Order {arg1} has been created successfully.`,
"SFE100011": `Back Order {arg1} has been created successfully.`,
"SFE100012": `{arg1} has been changed successfully.`,
"SFE100013": `Outbound number {arg1} has been copied successfully.`,
"SFE100014": `Remark of outbound inventory {arg1} has been changed successfully.`,
"SFE100015": `{arg1} has been outbound successfully.`,
"SFE100016": `{arg1} has been inbound successfully.`,
"SFE100017": `{arg1} has been created successfully.`,
"SFE100018": `Remark of inbound inventory {arg1} have been changed successfully.`,
"SFE100019": `A new warehouse {arg1} has been created successfully.`,
"SFE100020": `Warehouse {arg1} has been deleted successfully.`,
"SFE100021": `{arg1} is associated with {arg2} successfully.`,
"SFE100022": `A new warehouse sourcing rule {arg1} has been added successfully.`,
"SFE100023": `{arg1} has been changed successfully.`,
"SFE100024": `{arg1} has been deleted successfully.`,
"SFE100025": `A new logistic sourcing rule {arg1} has been added successfully.`,
"SFE100026": `{arg1} has been changed successfully.`,
"SFE100027": `{arg1} has been deleted successfully.`,
"SFE100028": `remark in Order  {arg1} have been added successfully.`,
"SFE100029": ` {arg1}  has been disabled successfully.`,
"SFE100030": ` {arg1}  has been enabled successfully.`,
"SFE100031": `Warehouse {arg1} has been updated successfully.`,
"SGS100000": `{arg1} has been updated successfully.`,
"SGS100001": `Region has been updated successfully to {arg1} .`,
"SGS100002": `Time zone has been updated successfully to {arg1} , then Store Front and DTC Platform times will now be displayed according to the latest time zone.`,
"SGS100003": `The language has been updated successfully to {arg1} .`,
"SGS100004": `Currency symbol has been updated successfully to {arg1} , the Store Front and DTC Platform time will now be displayed based on the latest Currency symbol.
Kind reminder: It does not involve currency exchange rate conversion.`,
"SGS100005": `Currency format has been updated successfully to {arg1} , the Store Front and DTC Platform time will now be displayed based on the latest Currency format.`,
"SGS100006": `No. of digits after decimal has been updated successfully to {arg1} , and Store Front and DTC Platform currency values will now be displayed based on the latest No. of digits after decimal.
Kind reminder: Keep decimal places by rounding`,
"SGS100007": `Date&Time format hase been updated successfully to {arg1} , the Store Front and DTC Platform time will now be displayed based on the latest Data&Time format.`,
"SGS100008": `No. of digits after decimal has been updated successfully to {arg1} , and Store Front and DTC Platform non monetary values will now be displayed based on the latest number of decimal places.
Kind reminder: Keep decimal places by rounding`,
"SGS100009": `Digit grouping hase been updated successful to {arg1} , the Store Front and DTC Platform non monetary values will now be displayed based on the latest digit grouping format.`,
"SGS100010": `{arg1} has been updated successfully.`,
"SGS100011": `Measurement Family {arg1} has been added successfully.`,
"SGS100012": `Measurement Unit {arg1} has been added successfully.`,
"SGS100013": `Measurement Family {arg1} has been edited successfully.`,
"SGS100014": `Measurement Unit {arg1} has been edited successfully.`,
"SGS100015": `Measurement Family {arg1} has been deleted successfully.`,
"SGS100016": `Measurement Unit {arg1} has been deleted successfully.`,
"SGS100017": `Transition Method {arg1} has been added successfully.`,
"SGS100018": `Transition Method {arg1} has been added successfully.`,
"SGS100019": `Transition Method {arg1} has been edited successfully.`,
"SGS100020": `Transition Methods {arg1} Status has been {arg2} successfully.`,
"SGS100021": `Shipping Template {arg1} has been added successfully.`,
"SGS100022": `Shipping Template {arg1} has been edited successfully.`,
"SGS100023": `Shipping Template {arg1} has been copied successfully.`,
"SGS100024": `Shipping Template {arg1} has been deleted successfully.`,
"SGS100025": `DTC Tracking has been edited successfully.`,
"SGS100026": `DTC Tracking Status has been {arg1} successfully.`,
"SGS100027": `Carriers company {arg1} has been added successfully.`,
"SGS100028": `Carriers company {arg1} has been edited successfully.`,
"SGS100029": `Carriers company {arg1} has been deleted successfully.`,
"SGS100030": `Carriers company {arg1} status has been {arg2} successfully.`,
"SGS100031": `Org {arg1} has been added successfully.`,
"SGS100032": `Org {arg1} has been edited successfully.`,
"SGS100033": `Org {arg1} status has been {arg2} successfully.`,
"SGS100034": `Tax zone {arg1} has been updated successfully.`,
"SGS100035": `Tax zone {arg1} has been edited successfully.`,
"SGS100036": `Tax zone {arg1} has been deleted successfully.`,
"SGS100037": `Tax zone {arg1} status has been {arg2} successfully.`,
"SGS100038": `Tax class has been updated successfully.`,
"SGS100039": `{arg1} has been edited successfully.`,
"SGS100040": `{arg1} Status has been {arg2} successfully.`,
"SGS100041": `A new user {arg1} has been added successfully.`,
"SGS100042": `A new password email for user {arg1} has been sent successfully.`,
"SGS100043": `User {arg1} has been activated successfully.`,
"SGS100044": `User {arg1} has been deactivate secceeded.`,
"SGS100045": `{arg1} has been copied successfully.`,
"SGS100046": `A new application {arg1} has been added.`,
"SGS100047": `{arg1} has been turned on successfully.`,
"SGS100048": `{arg1} has been turned off successfully.`,
"SGS100049": `{arg1} has been chenged successfully.`,
"SGS100050": `A new role {arg1} has been added successfully.`,
"SGS100051": `A new user {arg1} has been assigned successfully.`,
"SGS100052": `{arg1} has been selected as {arg2} successfully.`,
"SGS100053": `{arg1} has been deleted successfully.`,
"SGS100054": `A new secret has been added successfully.`,
"SGS100055": `Key {arg1} translated successfully.`,
"SMA100000": `Campaign {arg1} has been added successfully.`,
"SMA100001": `Task {arg1} has been approved successfully.`,
"SMA100002": `Task {arg1} has been rejected successfully.`,
"SMA100003": `Process Canvas {arg1} has been paused successfully.`,
"SMA100004": `Process Canvas {arg1} has been run successfully.`,
"SMA100005": `Task {arg1} has been published successfully`,
"SMA100006": `Campaign {arg1} has been approved successfully.`,
"SMA100007": `Campaign {arg1} has been rejected successfully.`,
"SMA100008": `task {arg1} has been paused successfully.`,
"SMA100009": `Process Canvas {arg1} has been published successfully.`,
"SMA100010": `Process Canvas {arg1} has been saved as draft successfully.`,
"SMA100011": `Process Canvas {arg1} has been approved successfully.`,
"SMA100012": `Process Canvas {arg1} has been rejected successfully.`,
"SMA100013": `Template {arg1} has been added successfully.`,
"SMA100014": `Template {arg1} has been edited successfully.`,
"SMA100015": `Template {arg1} has been edited successfully.`,
"SMA100016": `Template {arg1} has been deleted successfully.`,
"SMA100017": `Template {arg1} has been added successfully.`,
"SMA100018": `Template {arg1} has been edited successfully.`,
"SMA100019": `Template {arg1} has been edited successfully.`,
"SMA100020": `Template {arg1} has been deleted successfully.`,
"SMA100021": `Message {arg1} has been set successfully.`,
"SMA100022": `Task {arg1} has been run successfully.`,
"SMA100023": `Process Canvas {arg1} has been saved as draft successfully.`,
"SMA100024": `Process Canvas {arg1} has been updated successfully.`,
"SMA100025": `Process Canvas {arg1} has been deleted successfully.`,
"SMA100026": `Task {arg1} has been saved as draft successfully.`,
"SMA100027": `Task {arg1} has been updated successfully.`,
"SMA100028": `Task {arg1} has been deleted successfully.`,
"SMS": `SMS`,
"SMS Template": `SMS Template`,
"SMS Template Content": `SMS Template Content`,
"SMS Template Description": `SMS Template Description`,
"SMS Template Detail": `SMS Template Detail`,
"SMS Template Name": `SMS Template Name`,
"SMS-ALI": `SMS-ALI`,
"SSE100000": `Comment {arg1} added successfully.`,
"SSE100001": `Order {arg1} invoice downloaded successfully.`,
"SSE100002": `Order {arg1} has been cancelled successfully.`,
"SSE100003": `Order {arg1} has been shipped successfully.`,
"SSE100004": `Order {arg1} has been completed successfully.`,
"SSE100005": `Order {arg1} has been approved successfully.`,
"SSE100006": `Order {arg1} has been rejected successfully.`,
"SSE100007": `Orders exported successfully and can be found in Imp&Exp.`,
"SSE100008": `Order {arg1} applied return successfully with return number {arg2} .`,
"SSE100009": `Return order {arg1} has been approved successfully.`,
"SSE100010": `Return order {arg1} has been rejected successfully.`,
"SSE100011": `Return order {arg1} has been shipped from consumer successfully.`,
"SSE100012": `Return order {arg1} has received from consumer successfully.`,
"SSE100013": `Return order {arg1} has been efunded {arg2} successfully.`,
"SSE100014": `General order setting has been changed successfully.`,
"SSE100015": `Email blocklist has been changed successfully.`,
"SSE100016": `Choice {arg1} has been saved successfully.`,
"SSE100017": `Order number format has been changed successfully.`,
"SSE100018": `New sales order flow {arg1} has been added successfully.`,
"SSE100019": `New return order flow {arg1} has been added successfully.`,
"SSE100020": `Order flow {arg1} has been updated successfully.`,
"SSE100021": `Order flow {arg1} has been deleted successfully.`,
"SSE100022": `Product {arg1} added to basket successfully.`,
"SSE100023": `Product {arg1} deleted successfully.`,
"SSE100024": `Subscription {arg1} has been paused successfully.`,
"SSE100025": `Subscription {arg1} has been restarted successfully.`,
"SSE100026": `Subscription {arg1} has been cancelled successfully.`,
"SSE100027": `Subscription {arg1} has changed carrier address successfully.`,
"SSE100028": `Subscription {arg1} will generate a new order on {arg2} .`,
"SSE100029": `Order {arg1} has been generated successfully.`,
"SSE100030": `Subscriptions exported successfully and can be found in Imp&Exp.`,
"SSE100031": `Frequency {arg1} has been edited successfully.`,
"SSE100032": `Frequency {arg1} has been deleted successfully.`,
"SSE100033": `New frequency {arg1} has been added successfully.`,
"SSE100034": `Subscription number format have been changed successfully.`,
"SSE100035": `{arg1} has been disabled successfully.`,
"SSE100036": `{arg1} has been enabled successfully.`,
"SSE100037": `{arg1} has been changed successfully.`,
"SSE100038": `Invoice {arg1} has been generated successfully.`,
"SSE100039": `Invoice {arg1} has been downloaded successfully.`,
"SSE100040": `{arg1} has been disabled successfully`,
"SSE100041": `{arg1} has been abled successfully`,
"SSE100042": `Changes in {arg1} have been saved successfully`,
"SSE100043": `Subcategory {arg1} has been added under {arg2} successfully`,
"SSE100044": `Category {arg1} has been deleted successfully`,
"SSE100045": `Category {arg1} has been added successfully`,
"SSE100046": `Mini program has been abled successfully`,
"SSE100047": `Mini program has been disabled successfully`,
"SSE100048": `Shop sitemap downloaded successfully`,
"SSE100049": `Changes in preferences have been saved successfully`,
"SSE100050": `Product {arg1} has been added to basket successfully.`,
"SSE100051": `Product {arg1} has been deleted successfully.`,
"SSE100052": `All products in cart have been removed successfully.`,
"SSE100053": `Language has been changed to {arg1} successfully.`,
"SSE100054": `Partner {arg1} has been added successfully.`,
"SSE100055": `Partner {arg1} has been removed successfully.`,
"SSE100056": `File {arg1} has been downloaded successfully.`,
"SSE100057": `Shipping {arg1} has been arranged successfully.`,
"SSE100058": `Invoice {arg1} has been downloaded successfully.`,
"SSE100059": `Export {arg1} successfully.`,
"SSE100060": `Enable {arg1} successfully.`,
"SSE100061": `Edit {arg1} successfully.`,
"SSE100062": `Download {arg1} successfully.`,
"SSE100063": `Product {arg1} has been added successfully`,
"SSE100064": `Product {arg1} has been edited successfully`,
"SSE100065": `Product {arg1} has been onlist successfully`,
"SSE100066": `Product {arg1} has been delete successfully`,
"SSE100067": `Product {arg1} has been added successfully`,
"SSE100068": `Top Search {arg1} has been added successfully`,
"SSE100069": `Top Search {arg1} has been deleted successfully`,
"SSE100070": `Top Search {arg1} has been show successfully`,
"SSE100071": `Top Search {arg1} have been all show successfully`,
"SSE100072": `Synonyms {arg1} has been added successfully`,
"SSE100073": `Synonyms {arg1} has been delete successfully`,
"SSE100074": `Synonyms {arg1} has been edited successfully`,
"SSE100075": `Description has been binded successfully`,
"SSE100076": `Attributes has been binded successfully`,
"SSE100077": `Description {arg1} has been added successfully`,
"SSE100078": `Description {arg1} has been edited successfully`,
"SSE100079": `Description {arg1} has been deleted successfully`,
"SSE100080": `Attributes {arg1} has been added successfully`,
"SSE100081": `Attributes {arg1} has been edited successfully`,
"SSE100082": `Attributes {arg1} has been deleted successfully`,
"SSE100083": `Attribute Filter {arg1} has been added successfully`,
"SSE100084": `Attribute Filter {arg1} has been deleted successfully`,
"SSE100085": `Tag Filter {arg1} has been added successfully`,
"SSE100086": `Tag Filter {arg1} has been deleted successfully`,
"SSE100087": `Customized Filter {arg1} has been deleted successfully`,
"SSE100088": `Sort by {arg1} has been enabled successfully`,
"SSE100089": `Search Platform {arg1} has been enabled successfully`,
"SSE100090": `Search Platform {arg1} has been edited successfully`,
"SSE100091": `Customer {arg1} has been deleted successfully`,
"SSE100092": `Customer‘s tag {arg1} has been edited successfully`,
"SSE100093": `Tag {arg1} has been added successfully`,
"SSE100094": `Tag {arg1} has been enabled successfully`,
"SSE100095": `Tag {arg1} has been added customers {arg2} successfully`,
"SSE100096": `Tag {arg1} has been deleted customers {arg2} successfully`,
"SSE100097": `Voucher {arg1} has been added successfully`,
"SSE100098": `Code {arg1} has been generated successfully`,
"SSE100099": `Voucher {arg1} has been ended successfully`,
"SSE100100": `Discount Promotion {arg1} has been added successfully`,
"SSE100101": `Discount Promotion {arg1} has been terminate successfully`,
"SSE100102": `Template {arg1} has been added successfully`,
"SSE100103": `Template {arg1} has been deleted successfully`,
"SSE100104": `Account {arg1} has been added successfully`,
"SSE100105": `Account {arg1} has been edited successfully`,
"SSE100106": `Account {arg1} has been deleted successfully`,
"SSE100107": `Sort by {arg1} has been disabled successfully`,
"SSE100108": ` Fraud detection criteria has been saved successfully.`,
"SSE100109": `Order audit has been changed successfully.`,
"SSE100110": `Risk Level of order audit has been changed successfully.`,
"SSE100111": `Order With 0 Price of order audit has been changed successfully.`,
"SSE100112": `Product Categories	of order audit has been changed successfully.`,
"SSE100113": `Search Platform {arg1} has been disabled successfully`,
"SSE100114": `Ip blocklist has been changed successfully.`,
"SSE100115": `Tag {arg1} has been disabled successfully`,
"SSE100116": `Disnable {name} successfully.`,
"SSE100117": `Product {arg1} has been delist successfully`,
"SSE100118": `Top Search {arg1} has been hide successfully`,
"SSE100119": `Top Search  have been all hide successfully`,
"SSE100120": `Frequency {arg1} has been disabled successfully.`,
"SSE100121": `Frequency {arg1} has been enabled successfully.`,
"SSE100122": `Comment {arg1} deleted successfully.`,
"SSE100123": `Discount code {arg1} has been restarted successfully.`,
"SSE100124": `Discount code {arg1} has been paused successfully.`,
"STEP": `STEP`,
"Sales Experience": `Sales Experience`,
"Sales Order": `Sales Order`,
"Sales Order Flow": `Sales Order Flow`,
"Same Warehouse Limit": `Same Warehouse Limit`,
"Saturday": `Saturday`,
"Save": `Save`,
"Save Draft": `Save Draft`,
"Scenario Description": `Scenario Description`,
"Scenario Link": `Scenario Link`,
"Scheduled": `Scheduled`,
"Search Engine Preview": `Search Engine Preview`,
"Search Media Name": `Search Media Name`,
"Search Warehouse Name": `Search Warehouse Name`,
"Search for posts": `Search for posts`,
"Search functionality's introduction.": `Our search functionality allows you to configure top suggestions and synonyms in a second. It enables customers to input keywords, phrases, or other search criteria to retrieve relevant results.`,
"Search sourcing rule name": `Search sourcing rule name`,
"Second Goal": `Second Goal`,
"Secret Value": `Secret Value`,
"Security": `Security`,
"Segments Detail": `Segments Detail`,
"Segments List": `Segments List`,
"Segments Management": `Segments Management`,
"Select  Logical  warehouse": `Select  Logical  warehouse`,
"Select  Logistics Company": `Select  Logistics Company`,
"Select  Physical warehouse": `Select  Physical warehouse`,
"Select  Store": `Select  Store`,
"Select Campaign Process Type": `Select Campaign Process Type`,
"Select Date": `Select Date`,
"Select Number format": `Select Number format`,
"Select Related Main Warehouse": `Select Related Main Warehouse`,
"Select Specific Period": `Select Specific Period`,
"Select product": `Select product`,
"SelectSpecificPeriod": `Select Specific Period`,
"Selected Subscription": `Selected Subscription`,
"Self-built": `Self-built`,
"Seller": `Seller`,
"Send To Node": `Send To Node`,
"Send To Node tip": `Total quantity of orders generated for outbound orders`,
"Send alert notification email automatically": `Send alert notification email automatically`,
"Send exactly what your": `Send exactly what your`,
"Send this email after the last attempt": `Send this email after the last attempt`,
"Sendgrid Template Name": `Sendgrid Template Name`,
"Sequence Number": `Sequence Number`,
"Service Name": `Service Name`,
"Service description": `Service description`,
"Session": `Session`,
"Session ID": `Session ID`,
"Set Domain": `Set Domain`,
"Set as guest": `Set as guest`,
"Set rule  for specific products and except for the specified products the other products is used for other logistic company": `Set rule  for specific products and except for the specified products the other products is used for other logistic company`,
"Set rule for specific regions and except for the specified region the other region is used for other logistic company": `Set rule for specific regions and except for the specified region the other region is used for other logistic company`,
"Set specific weight of all logistics. Weight is filled in as an integer and the weight is greater than 0; The higher the value the higher the priority.": `Set specific weight of all logistics. Weight is filled in as an integer and the weight is greater than 0; The higher the value the higher the priority.`,
"Set specific weight of all warehouses. Weight is filled in as an integer and the weight is greater than 0; The higher the value the higher the priority.": `Set specific weight of all warehouses. Weight is filled in as an integer and the weight is greater than 0; The higher the value the higher the priority.`,
"Set up a welcome series": `Make it easy to run your marketing campaigns. Set up a welcome series with email automation segment your contacts or use simple automation workflows to save time and money.`,
"Set up your own rule": `Set up your own rule`,
"Setting": `Setting`,
"Setting Application": `Setting Application`,
"Setting your sourcing based on what is most important for the orders that will be processed by this rule.": `Setting your sourcing based on what is most important for the orders that will be processed by this rule.`,
"Setting-Shipping Method": `Shipping Method`,
"Setting-Shipping Method info": `The shipping template configuration is effective for the whole store configuration. Different freight templates  implement freight rules and are associated with specific goods`,
"Setup a mobile web or IoT application to use platform for Authentication": `Setup a mobile web or IoT application to use platform for Authentication`,
"Sharpen": `Sharpen`,
"Shipment": `Shipment`,
"Shipping": `Shipping`,
"Shipping Method": `Shipping Method`,
"Shipping Method Please input": `Please input`,
"Shipping Method info": `Shipping methods are the rates and services that customers can choose from at checkout. They’re often based on the carriers you work with and determine what shoppers pay for shipping and how quickly they’ll receive their purchase.`,
"Shipping Methods": `Shipping Methods`,
"Shipping address": `Shipping address`,
"Shipping to": `Shipping to`,
"Shipping.Consumer": `Consumer`,
"Shipping.Consumer:": `Consumer:`,
"Shipping.Edit Express 100 setting": `Edit Express 100 setting`,
"Shipping.Express 100": `Express 100`,
"Shipping.Key": `Key`,
"Shipping.Key:": `Key:`,
"Shipping.Please input callbackUrl": `Please input callbackUrl`,
"Shipping.Please input consumer": `Please input consumer name`,
"Shipping.Please input key": `Please input key`,
"Shipping.Pull URL": `Request URL`,
"Shipping.Pull URL:": `Request URL:`,
"Shipping.Query URL": `Query URL:`,
"Shipping.Query URL:": `Query URL:`,
"Shipping.SCallback URL": `Callback URL`,
"Shipping.SCallback URL:": `Callback URL:`,
"Shipping.SEQ": `SEQ`,
"Shipping.Shipping Setting": `Shipping Setting`,
"Shipping.please input url": `Please input url`,
"Shipping.please input user name": `Please input user name`,
"Shop": `Shop`,
"Shop Category": `Shop Category`,
"Sign-in redirect URIs": `Sign-in redirect URIs`,
"Sign-out redirect URIs": `Sign-out redirect URLs`,
"Signature": `Signature`,
"Signed Product Category": `Signed Product Category`,
"Signed information": `Signed information`,
"Single Add": `Single Add`,
"Single product in the same warehouse first": `Single product in the same warehouse first`,
"Site Map": `Sitemap`,
"Six Months": `Six Months`,
"Size": `Size`,
"Smart Operation": `Smart Operation`,
"Source": `Source`,
"Sourcing Rule": `Sourcing Rule`,
"Sourcing Rule Name": `Sourcing Rule Name`,
"Sourcing rule is to help business parties express": `Sourcing rule is to help business parties express these allocation criteria in the form of system rules and find the most suitable warehouse and logistic for the order to reduce the cost 
of contract fulfillment and improve the utilisations rate of inventory resources. `,
"Split Order": `Split Order`,
"Split Order Setting": `Split Order Setting`,
"Standards and format": `Standards and format`,
"Standards and format info": `Maintain the date format digital display format and unit type of the store`,
"Start": `Start`,
"Start and end time": `Start and end time`,
"Start date": `Start date`,
"State": `State`,
"States based": `States based`,
"Status": `Status`,
"Stock availability": `Stock availability`,
"Stocking": `Stocking`,
"Store": `Store`,
"Store Basic Configuration": `Store Basic Configuration`,
"Store Basic Information": `Store Basic Information`,
"Store Currency": `Store Currency`,
"Store Details": `Store Details`,
"Store Name": `Store Name`,
"Store Region Language": `Store Region Language`,
"Store Setting": `Store Setting`,
"Store Setting Info": `Provide store configuration capability for stores including basic information locale measurement standard format etc.`,
"Store information": `Store information`,
"Store name": `Store name`,
"Submit": `Submit`,
"Subscription Date": `Subscription Date`,
"Subscription Detail": `Subscription Detail`,
"Subscription Setting": `Subscription Setting`,
"Subscriptions": `Subscriptions`,
"Success": `Success`,
"Summary": `Summary`,
"SunDay": `SunDay`,
"Support Subscription": `Support Subscription`,
"Switch": `Switch`,
"Switch to": `Switch to`,
"Symbal": `Symbol`,
"Symbol(eg:km)": `Symbol(eg:km)`,
"Synchronize Data": `Synchronize Data`,
"Synchronize assets info": `Synchronize assets info`,
"Synchronize contents info including pages": `Synchronize contents info including pages`,
"Synchronize data from Existed Store": `Synchronize data from Existed Store`,
"Synchronize products info": `Synchronize products info`,
"Synchronize store configuration info": `Synchronize store configuration info`,
"Synchronized Data": `Synchronized Data`,
"Synchronized Data option1": `Products including product description, attributes, products basic info`,
"Synchronized Data option2": `Contents including content types, collection, page content and emails`,
"Synchronized Data option3": `Assets including images, videos, audios and videos`,
"Synchronized Data option4": `Store Basic Information`,
"Synonyms": `Synonyms`,
"System": `System`,
"System Menu": `System Menu`,
"Tag Management": `Tag Management`,
"Tagging Details": `Tagging Details`,
"Tagging Setting": `Tagging Setting`,
"Tags": `Tags`,
"Tags List": `Tags List`,
"TagsDetail.Add New Option": `Add New Option`,
"TagsDetail.Create Tags": `Add Tags`,
"TagsDetail.Input Option Description": `Input Option Description`,
"TagsDetail.Input Option Name": `Input Option Name`,
"TagsDetail.Input Tag Label": `Input Tag Label`,
"TagsDetail.Option Name": `Option Name`,
"TagsDetail.Option description": `Option description`,
"TagsDetail.Select Tag Type": `Select Tag Type`,
"TagsDetail.Tag Basic Information": `Tag Basic Information`,
"TagsDetail.Tag Label": `Tag Label`,
"TagsDetail.Tag Rules": `Tag Rules`,
"TagsDetail.Tag Type": `Tag Type`,
"TagsDetail.Tags Detail": `Tags Detail`,
"TagsDetail.updateType.tip": `The tag is updated on a daily basis`,
"Take your ecommerce to the next level": `Take your ecommerce to the next level`,
"Take your ecommerce to the next level description": `All the features you need to streamline everything from acquiring products to selling shipping inventory and returns.`,
"Target Language": `Target Language`,
"Task Center": `Task Center`,
"Task Detail": `Task Detail`,
"Task List": `Task List`,
"Task List displays all records that were sent or planning to send to consumers.": `Message List displays all records that were sent or planning to send to consumers.`,
"Tax": `Tax`,
"Tax Managemen": `Tax Management`,
"Tax Management": `Tax Management`,
"Tax Management info": `Provide tax management for store  mainly including tax zone management tax setting tax calculation.`,
"Tax Setting": `Tax Setting`,
"Tax calculation": `Tax calculation`,
"Tax class": `Tax Class`,
"Tax class label": `Tax Class Label`,
"Tax rates": `Tax rates`,
"Tax setting": `Tax setting`,
"Tax setting info": `Provide tax management for store  mainly including tax zone management tax setting tax calculation.`,
"Tax zone description": `Tax zone description`,
"Tax zone management": `Tax zone management`,
"Tax zone name": `Tax zone name`,
"Tax zone type": `Tax zone type`,
"Template Message": `Template Message`,
"Template Message Detail": `Template Message Detail`,
"Template Type": `Template Type`,
"Template name": `Template name`,
"Templates": `Templates`,
"The export report will show in the Export report.": `The export report will show in the Export report.`,
"The maximum period is six months": `The maximum period is six months`,
"The stocking warehouse can share inventory or not but it cannot ship order.": `The stocking warehouse can share inventory or not but it cannot ship order.`,
"The whole document dimension and the single item dimension have no restriction that": `The whole document dimension and the single item dimension have no restriction that they must be sent from the same warehouse. 
 
They can be shipped as long as they can meet the inventory required by the order.`,
"Theme List": `Theme List`,
"Themes": `Themes`,
"These are all the resource and api scopes that this application uses.": `These are all the resource and api scopes that this application uses.`,
"Third-party": `Third-party`,
"This change will apply to future": `This change will apply to future`,
"This page is ready to be audited. Do you confirm to publish this page?": `This page is ready to be audited. Do you confirm to publish this page?`,
"This product is ready to be published. Do you confirm to publish this product?": `This product is ready to be published. Do you confirm to publish this product?`,
"This will delete": `This will delete {arg1}?`,
"This will replace your current theme to": `This will replace your current theme to`,
"Three Months": `Three Months`,
"Thursday": `Thursday`,
"Tier Setting": `Tier Setting`,
"Time": `Time`,
"Time Now": `Time Now`,
"Time Zone": `Time Zone`,
"Time format": `Time format`,
"Top Suggestions": `Top Suggestions`,
"Total": `Total`,
"Total Records": `Total Records`,
"Transaction": `Transaction`,
"Transaction Attribute": `Transaction Attribute`,
"Transfer Order": `Transfer Order`,
"Transformational Functions.three": `Creates marketing content for blog posts, email campaigns, social media posts and ad copy for different tones/ customer types `,
"Transformational Functions": `Transformational Functions`,
"Transformational Functions.one": `Efficiently search databases based on given user queries and generates on-demand insights including charts and visualizations from tabular databases`,
"Transformational Functions.subTitle": `Unlock the full potential of your organization with our integrated, innovative solutions.`,
"Transformational Functions.two": `Provide natural language answers to questions based on complex documents`,
"Transit Method": `Transit Method`,
"Transit time": `Transit time`,
"Transition Method": `Transition Method`,
"Transition Methods": `Transition Methods`,
"Transition Methods info": `Define the transition time for different services`,
"Translated": `Translated`,
"Translation Assistant": `Translation Assistant`,
"Translation Assistant tips": `Translation assistant helps you to translate all page content of this page. Please choose the desired language below and note that the whole translation process may last 2~3 minutes.`,
"Trust Origins": `Trust Origins`,
"Tuesday": `Tuesday`,
"Twitter": `Twitter`,
"Type": `Type`,
"UNKNOWN": `Unknown`,
"URL": `URL`,
"US/Eastern": `United States`,
"Unit Code": `Unit Code`,
"Unit System": `Unit System`,
"Unpublish": `Unpublish`,
"Untitled": `Untitled`,
"Untranslated": `Untranslated`,
"Update Return Order": `Update Return Order`,
"Update Sales Order": `Update Sales Order`,
"Upload Media": `Upload Media`,
"Upload More": `Upload More`,
"Upload a media": `Upload a media`,
"Upload a media tips": ` Note:
              <ul>
                <li>
                  1.Image
                  <ul className='list-disc ml-5'>
                    <li>Support file format: BMP, PNG,JPEG,JPG, GIF, SVG, WEBP,AVIG;</li>
                    <li>Size ≤ 20M</li>
                  </ul>
                </li>
                <li>
                  2.Audio
                  <ul className='list-disc ml-5'>
                    <li>Support file format: MP3, WMA, WAV, AMR;</li>
                    <li>Size ≤ 30M</li>
                  </ul>
                </li>
                <li>
                  3.Video
                  <ul className='list-disc ml-5'>
                    <li>Support file format: MP4, FLV,F4V, AVI;</li>
                    <li>Size ≤ 200M</li>
                  </ul>
                </li>
                <li>
                  4.File
                  <ul className='list-disc ml-5'>
                    <li>Support file format: DOC, DOCX, PPT, CSV, XLS, XLSX, PDF;</li>
                    <li>Size ≤ 20M</li>
                  </ul>
                </li>
              </ul>`,
"Upload digital assets": `Upload digital assets`,
"Upload images, videos to stay on-brand with a centralized media library": `Upload images, videos to stay on-brand with a centralized media library.`,
"Upload to Catalog": `Upload to Catalog`,
"Uptime": `Uptime`,
"Use Google maps service to autocompolete shipping and billing address fields": `Use Google maps service to autocompolete shipping and billing address fields
`,
"Use Google maps tips": `Use Google maps service to autocompolete shipping and billing address fields`,
"User": `User`,
"User Guide": `User Guide`,
"User List": `User List`,
"User Name": `User Name`,
"User Setting": `User Setting`,
"User Type": `User Type`,
"User already exists": `User already exists`,
"Username": `Username`,
"Users": `Users`,
"Users that have this apllication directly assigned.": `Users that have this apllication directly assigned.`,
"Valet Order": `Valet Order`,
"Valid period": `Valid period`,
"Validate until": `Validate until`,
"Verification Code": `Verification Code`,
"Version": `Version`,
"Version History": `Version History`,
"View Order": `View Order`,
"View Return Order": `View Return Order`,
"View full fraud analysis": `View full fraud analysis`,
"Volume Base": `Volume Base`,
"Vouchers": `Vouchers`,
"WECHAT_MINI_PROGRAM": `Mini-Program`,
"Warehouse": `Warehouse`,
"Warehouse Name": `Warehouse Name`,
"Warehouse Ownership": `Warehouse Ownership`,
"Warehouse Priority": `Warehouse Priority`,
"Warehouse Role": `Warehouse Role`,
"Warehouse Sourcing Rule": `Warehouse Sourcing Rule`,
"Warehouse Sourcing Rule List": `Warehouse Sourcing Rule List`,
"Warehouse Sourcing Rules": `Warehouse Sourcing Rules`,
"Warehouse Usage": `Warehouse Usage`,
"Warehouse name": `Warehouse name`,
"Warehouse type": `Warehouse type`,
"Warehouses": `Warehouses`,
"We can't find any item matching your search": `We can't find any item matching your search`,
"We suggest a ": `We suggest a `,
"WeChat Setting": `WeChat Setting`,
"WeCom Setting": `WeCom Setting`,
"Webhook": `Webhook`,
"Wechat Mini Program": `Wechat Mini Program`,
"Wechat Service Account": `Wechat Service Account`,
"Wednesday": `Wednesday`,
"Weight": `Weight`,
"Weight Base": `Weight Base`,
"What Store would you like to create": `What Store would you like to create?`,
"What is your desired domain": `What is your desired domain?`,
"What is your desired domain tips": `The domain name serves as the URL that consumers must input to access your online store. This unique web address is generated based on the chosen country and language settings below.`,
"When an order contains multiple goods the same goods must be sent from the same warehouse and different goods can be sent from different warehouses.": `When an order contains multiple goods the same goods must be sent from the same warehouse and different goods can be sent from different warehouses.`,
"Whole order in the same warehouse": `Whole order in the same warehouse`,
"Zip Code": `Zip Code`,
"Zone Name": `Zone Name`,
"Zone Type": `Zone Type`,
"Zone includes": `Zone includes`,
"activityLogs.subTile": `Display the log of user log in and log out of the application`,
"add page": `Inventory Network Add`,
"address.Add Field": `Add Field`,
"address.Add new schema": `Add new schema`,
"address.Edit area": `Edit area`,
"address.Field Name": `Field Name`,
"address.Field area": `Field area`,
"address.Field display name": `Field display name`,
"address.Format": `Format`,
"address.Visualization area": `Visualization area`,
"adyenTip": `Adyen is a payment platform aimed at simplifying global payments providing global payment solutions and covering payment methods that are familiar and trusted by all consumers worldwide.`,
"after the user enters the process the goal is  considered to be compleeted": `after the user enters the process the goal is  considered to be completed`,
"aiRecoModel.AAFCO": `Association of American Feed Control Officials (AAFCO)`,
"aiRecoModel.Detail": `Model Detail`,
"aiRecoModel.FEDIAF": `European Pet Food Industry Federation (FEDIAF)`,
"aiRecoModel.GB": `National Standards of the People's Republic of China (GB)`,
"aiRecoModel.NRC": `National Research Council (NRC)`,
"aiRecoModel.aiRecoModel": `AI Reco Model`,
"aiRecoModel.apply": `Apply`,
"aiRecoModel.baseInfo": `Basic Information`,
"aiRecoModel.buyHistory": `Buy History`,
"aiRecoModel.cancel": `Cancel`,
"aiRecoModel.drag_to_sort": `Drag to sort`,
"aiRecoModel.edit": `Edit Model`,
"aiRecoModel.editorName": `Editor Name`,
"aiRecoModel.foodDatabase": `Food Database`,
"aiRecoModel.foodDatabase2": `Food Database`,
"aiRecoModel.foodDatabaseLabel1": `United States Department of Agriculture (USDA) Food Database`,
"aiRecoModel.foodDatabaseLabel2": `Standard Tables of Food Composition in Japan`,
"aiRecoModel.foodDatabaseLabel3": `Food Database of Food Composition in New Zealand`,
"aiRecoModel.foodDatabaseLabel4": `Food Database of Food Composition in Australia`,
"aiRecoModel.history.date": `Date`,
"aiRecoModel.history.model": `Model`,
"aiRecoModel.history.modelNameOrId": `Model name/id`,
"aiRecoModel.history.phone": `Phone`,
"aiRecoModel.history.recProductNames": `Rec. Product Name(s)`,
"aiRecoModel.history.recomTime": `Rec. Time`,
"aiRecoModel.history.skus": `SKU(s)`,
"aiRecoModel.history.userName": `User Name`,
"aiRecoModel.history.userSearchingInput": `User Searching Input`,
"aiRecoModel.id": `Model ID`,
"aiRecoModel.inputPlaceholder": `Model Name Model ID`,
"aiRecoModel.lastMonth": `Last Month`,
"aiRecoModel.lastThreeMonths": `Last 3 Months`,
"aiRecoModel.lastWeek": `Last Week`,
"aiRecoModel.lastYear": `Last Year`,
"aiRecoModel.latestUpdate": `Latest Update`,
"aiRecoModel.list.subTitle": `The model could be turned on/off and after choosing the recommendation model the user could edit the parameters of within the model.`,
"aiRecoModel.maxNumber": `Max Rec. Number`,
"aiRecoModel.model_parameter_opti": `Model Parameter Optimization`,
"aiRecoModel.name": `Model Name`,
"aiRecoModel.nutritionBenchmark": `Nutrition Benchmark`,
"aiRecoModel.nutritionBenchmark2": `Nutrition Benchmark`,
"aiRecoModel.parameters": `Model Parameters`,
"aiRecoModel.petInfo": `Pet Information`,
"aiRecoModel.status": `Online Status`,
"aiRecoModel.type.customizedReco": `Customized Reco`,
"aiRecoModel.type.disable_tip": `Are you sure to disable this AI reco model?`,
"aiRecoModel.type.enable_tip": `Are you sure you to enable this AI reco model?`,
"aiRecoModel.type.relatedReco": `Related Reco`,
"aiRecoModel.userAndPetInfo": `User and Pet Information`,
"aiRecoModel.userInfo": `User Information`,
"aiRecoModel.viewHistory": `View History`,
"arrt.name": `Attributes Name`,
"arrt.value": `Attributes Type`,
"assets.Assets Type": `Assets Type`,
"assets.Audio": `Audio`,
"assets.Content Type": `Content Type`,
"assets.Files": `Files`,
"assets.Images": `Images`,
"assets.Videos": `Videos`,
"assets.catalog": `Catalog`,
"assets.mediaLibrary": `Media Library`,
"assets.mediaLibrary edit": `Edit Assets`,
"attbiutes": `Attributes`,
"attempts tips": `  <p>
                         Retry attempts happen regularly for up to 7 days, at increasing time
                         intervals: <br />
                         <strong>·</strong> 5 minutes <br />
                         <strong>·</strong> 10 minutes <br />
                         <strong>·</strong> 15 minutes <br />
                         <strong>·</strong> 30 minutes <br />
                         <strong>·</strong> 1 hour <br />
                         <strong>·</strong> 2 hours <br />
                         <strong>·</strong> 4 hours <br />
                         After that, retries happen every 8 hours for the following 7 days.
                       </p>`,
"autoMatedProcessDescription": `Through automated process personalized content and customized consumer journey we help the brand utilize current consumer segments and automatically trigger the conversion opportunities of additional sales and cross-sales.`,
"automated precision marketing": `automated precision marketing`,
"bind Descriptions": `Bind Descriptions`,
"bind attbiutes": `Bind Attributes`,
"blockList": `blockList`,
"campaign.Adjust Points": `Adjust Points`,
"campaign.Campaign Plan Overview": `Campaign Plan Overview`,
"campaign.Deliver Message": `Deliver Message`,
"campaign.Distribute Coupons": `Distribute Coupons`,
"campaign.abandon_push": `Abandon This Push`,
"campaign.add_condition": `Add Condition`,
"campaign.add_config": `Add confirguration`,
"campaign.add_segment": `Add Segment`,
"campaign.are_you_sure_delete": `Are you sure you want to delete this campaign?`,
"campaign.are_you_sure_pause": `Are you sure you want to pause this campaign?`,
"campaign.are_you_sure_restart": `Are you sure you want to restart this campaign?`,
"campaign.audit notification": `Audit Notification`,
"campaign.audit_log": `Audit Log`,
"campaign.audit_user_tip": `Please complete audit user setting`,
"campaign.belong_to": `Belong to`,
"campaign.branch": `Branch`,
"campaign.canvas_tip": `Please complete canvas setting`,
"campaign.complete_events": `Completed Events`,
"campaign.complete_tip": `Please complete setting`,
"campaign.confirm_audit_tip": `Please confirm to execute which audit option.`,
"campaign.confirm_publish_tip": `Are you sure you want to continue publishing?`,
"campaign.confirm_savedraft_tip": `Are you sure you want to save draft?`,
"campaign.date_period": `Date Period`,
"campaign.days": `day(s)`,
"campaign.delay_send": `Delayed Sending`,
"campaign.detail": `Campaign Detail`,
"campaign.dirturb_tip": `is a Do Not Disturb period. if it is triggered in this time period then`,
"campaign.distribution": `Distribution`,
"campaign.div_by_condition": `Diverge by user filtering conditions`,
"campaign.div_by_event": `Diverge by triggering events`,
"campaign.do_not_disturb": `Do not disturb`,
"campaign.enter_setting": `Entry Setting`,
"campaign.enter_time": `Enter Time`,
"campaign.enter_tip1": `set process type entry time and entry restrictions`,
"campaign.enter_tip2": `Fillter users entering the process`,
"campaign.entry_condition": `Entry Conditions`,
"campaign.event_meet_cnt": `Event attributes meet {count}`,
"campaign.has_child_node": `Should remove child node first`,
"campaign.missing_branch_tip": `Set at least one Sub-strategy or Splitter`,
"campaign.missing_parent_node": `Should complete parent node setting first`,
"campaign.multi": `Multiple Times`,
"campaign.not_belong_to": `Not belong to`,
"campaign.once": `Once`,
"campaign.operator_account": `Operator Account`,
"campaign.operator_category": `Operator Category`,
"campaign.operator_name": `Operator Name`,
"campaign.operator_time": `Operator Time`,
"campaign.participation_restrict": `Participation Restrictions`,
"campaign.process_canvas": `Process Canvas`,
"campaign.process_category": `Process Category`,
"campaign.process_description": `Process Description`,
"campaign.process_name": `Process Name`,
"campaign.process_type": `Process Type`,
"campaign.push_scope": `Push Scope`,
"campaign.running": `Running`,
"campaign.select_type": `Select Type`,
"campaign.send_after_": `Send After The Period Ends`,
"campaign.splitter": `Splitter`,
"campaign.splitter_tip": `Users will enter any branch randomly according to the traffic distribution radioand the total traffic is 100%`,
"campaign.splitter_tip1": `Users will enter any sub-strategy randomlywhich can be used for a simple A/B testing.`,
"campaign.sub_strategy": `Sub strategy`,
"campaign.sub_strategy_tip": `it is composed of three parts:user filteringdelay settingand reach postsand it supports engaging to specific users.`,
"campaign.sub_tactics": `Sub-tactics`,
"campaign.sub_tactics_type": `Sub-tactics type`,
"campaign.summary_tip": `There must be at least 2 braches and sum of distributions should be 100%`,
"campaign.target_user_tip": `Please complete target user setting`,
"campaign.the_same_user_": `The same user participate in the current process up to`,
"campaign.time_within": `time(s) within`,
"campaign.user_meet_cnt": `User attributes meet {count}`,
"campaign.user_satisfy_cnt": `User behaviour satisfies {count} items`,
"campaign.users_in": `Users in`,
"campaign.wait_time": `Wait Time`,
"campaign.white_user": `White List Users`,
"campaign.white_user_tips": `Enter test user account number and split by half-width comma between multiple users`,
"campaign.with_done": `Within done`,
"can_not_add_same_item": `You can not add same item`,
"carrierSetting.Add new company": `Add new company`,
"carrierSetting.Add/Edit carriers company": `Add/Edit carriers company`,
"carrierSetting.Carriers company code": `Carriers company code`,
"carrierSetting.Carriers company name": `Carriers company name`,
"carrierSetting.Carriers company settings": `Carriers company settings`,
"carrierSetting.Carriers company status": `Carriers company status`,
"carrierSetting.DTC Tracking": `DTC Tracking`,
"carrierSetting.Header Token": `Header Token`,
"carrierSetting.tip": `Manage your commonly used carriers companies easy to choose when ordering and shipping up to 20 carriers companies can be set up`,
"cdn.CDN Basic Settings": `CDN Basic Settings`,
"cdn.CDN Setting": `CDN Setting`,
"cdn.CDN Setting.tip": ` CDN setting provides the means to configure and fine-tune the behavior of a CDN to meet specific performance
  security and delivery requirements.`,
"cdn.Explanation": `Explanation`,
"cdn.OSS Prefix": `OSS Prefix`,
"cdn.Quality.explanation": `Specifies quality for images in JPEG WebP and AVIF ......formats. The quality is in a 1-100 scale.`,
"cdn.URL Prefix": `URL Prefix`,
"cdn.height.explanation": `Specifies maximum height of the image in pixels. Exact behavior depends on the fit mode.`,
"cdn.notification": `Please note that only 1 CDN could be opened at one time. Please make sure you turned off all other buttons
  before opening it.`,
"cdn.ossPrefix.explanation": `A fixed prefix that identifies that this is a special path handled by OSS's built-in Worker.`,
"cdn.urlPrefix.explanation": `An accelerated url prefix refers to a domain name that is added to the CDN which accelerates content delivery from the origin server and resource access.`,
"cdn.weight.explanation": `Specifies maximum width of the image in pixels. Exact behavior depends on the fit mode.`,
"channel_options.douyin": `Tik Tok`,
"channel_options.douyin.des": `Create a store for Tik Tok sell products and seamlessly manage orders on DTC Center.`,
"channel_options.facebook": `Facebook`,
"channel_options.facebook.des": `Create a store for Facebook sell products and seamlessly manage orders on DTC Center.`,
"channel_options.instagram": `Instagram`,
"channel_options.instagram.des": `Create a store for Instagram sell products and seamlessly manage orders on DTC Center.`,
"channel_options.online_store": `Online store`,
"channel_options.online_store.des": `Build a website with a beautiful and secure online store to sell products and manage orders.`,
"channel_options.points_of_sales": `Points of Sales`,
"channel_options.points_of_sales.des": `Support in-person sales and seamlessly checkout & manage orders on DTC Center.`,
"channel_options.twitter": `Twitter`,
"channel_options.twitter.des": `Create a store for Twitter sell products and seamlessly manage orders on DTC Center.`,
"channel_options.wechat_mini_program": `Wechat Mini Program`,
"channel_options.wechat_mini_program.des": `Sell products on WeChat mini program and seamlessly manage orders on DTC Center.`,
"channel_options.zhifubao": `Alipay mini program`,
"channel_options.zhifubao.des": `Sell products on Alipay mini program and seamlessly manage orders on DTC Center.`,
"cms.API Slug": `API Slug`,
"cms.Add Block": `Add Block`,
"cms.Add Component": `Add Component`,
"cms.Add some fields": `Add some fields`,
"cms.Annoucement Bar": `Annoucement Bar`,
"cms.Attribute Description": `Attribute Description`,
"cms.Attribute Name": `Attribute Name`,
"cms.Attribute Type": `Attribute Type`,
"cms.Audit": `audit`,
"cms.Basic Information": `Basic Information`,
"cms.Body": `Body`,
"cms.Change Date": `Change Date`,
"cms.Collection": `Collection`,
"cms.Component": `Component`,
"cms.Component Sync": `Component Sync`,
"cms.Component Synchronization": `Component Synchronization`,
"cms.Create dynamic landing pages using components": `Create dynamic landing pages using components`,
"cms.Delete": `Delete`,
"cms.Drag to Sort": `Drag to Sort`,
"cms.Edit Page Infor": `Edit Page Info`,
"cms.Enter Page Slug": `Enter Page Slug`,
"cms.Enter Page Title": `Enter Page Title`,
"cms.Footer": `Footer`,
"cms.Header": `Header`,
"cms.Hide": `Hide`,
"cms.How DolInsert Custom Components in the WYSIWYG Field?": `How DolInsert Custom Components in the WYSIWYG Field?`,
"cms.How to Modify Components": `How to Modify Components`,
"cms.Latest Saved Time": `Latest Saved Time`,
"cms.Learn": `Learn`,
"cms.Leave": `Leave`,
"cms.Metadata": `Metadata`,
"cms.More content modeling basics": `More content modeling basics`,
"cms.New Collection": `New Collection`,
"cms.New Component": `New Component`,
"cms.New Page Types": `New Page Types`,
"cms.Operation Log": `Operation Log`,
"cms.Page Audit": `Page Audit`,
"cms.Page Audit Tip": `This page is ready to be audited. Do you confirm to publish this page?`,
"cms.Page Publish Success Notification": `Page Publish Success Notification`,
"cms.Page Structure": `Page Structure`,
"cms.Page Title": `Page Title`,
"cms.PageType": `PageType`,
"cms.Please Enter Page API Slug": `Please Enter Page API Slug`,
"cms.Please Enter Page Name": `Please Enter Page Name`,
"cms.Publish By": `Publish By`,
"cms.Publish Date": `Publish Date`,
"cms.Publish History": `Publish History`,
"cms.Result": `Result`,
"cms.SEO": `SEO`,
"cms.Save Changes": `Save Changes`,
"cms.Save Page Metadata": `Save Page Metadata`,
"cms.Search Components": `Search Components`,
"cms.Select Metadata": `Select Metadata`,
"cms.Show": `Show`,
"cms.Slug Tips": `The API slug is used when querying the DTC API for this page’s content`,
"cms.Slug Tips1": `The API slug is used when querying the DTC API for this page’s content. Click  <a target='_blank' href={href} style='font-weight:bold; color:#999;text-decoration:underline'>here</a> to view this page.`,
"cms.Synchronize": `Synchronize`,
"cms.Target URL": `Target URL`,
"cms.Text": `Text`,
"cms.Theme Components": `Theme Components`,
"cms.Validation Parameters": `Validation Parameters`,
"cms.Version": `Version`,
"cms.View Later": `View Later`,
"cms.View Now": `View Now`,
"cms.You have created components sucessfully": `You have created components sucessfully.`,
"cms.You have deleted components sucessfully": `You have deleted components sucessfully.`,
"cms.attribute_create_tip": `Please choose the desired type of created attributes`,
"cms.contentType.New Content Type": `New Content Type`,
"cms.contentType.name": `Name`,
"cms.contentType.search": `Search`,
"cms.contentType.type": `Type`,
"cms.contentTypeSubTitle": `Effortlessly oversee all content types through a unified dashboard encompassing collections components and page types. For a sneak peek at the appearance of each component or page type simply click <a target='_blank' href={href} style='font-weight:bold; color:#999;text-decoration:underline'>here</a> to access Storybook.`,
"cms.min max length": `Min/Max length`,
"cms.offline": `Offline`,
"cms.online": `Online`,
"cms.page publish success desc": `Your Page [{pageTitle}] has been successfully published on our CMS platform. You can now access your live Page at [{pageUrl}].`,
"cms.save_tips": `Hey there! It looks like you have some unsaved changes. Just a heads up that if you exit now your changes won't be saved. Would you like to save your changes before leaving?`,
"cms.search.Search": `Search`,
"cms.search.collectionSeach": `Search`,
"cms.search.collectionType": `Collection Type`,
"cms.search.collections": `Collections`,
"cms.search.language": `Language`,
"cms.search.noData.tip1": `We couldn’t find any results for your search`,
"cms.search.noData.tip2": `You can try searching for different keywords.`,
"cms.search.page": `Pages`,
"cms.search.pageSearch": `Search`,
"cms.search.pageType": `Page type`,
"cms.search.placeholder": `Please input search keywords`,
"cms.search.tip": `Search your store and DTC's resources like products pages and posts.`,
"cms.titleSubInfo": `Build landing pages for ecommerce general page ecommerce promotions paid ad campaigns.`,
"cms.titleSubInfo1": `Create collection for tables of data to be referenced.`,
"cms.webhookSubTitle": `Get notified when content changes`,
"cms.webhookSubTitle1": `Configure webhooks to PosT change notifications to your application. View <a style={{ color: '#EF583A' }} to='#'>documentation</a> for details on Event types.`,
"cms.webhookSubTitle2": `documentation`,
"columns-Roles": `Roles`,
"comingSoon.text": `We are going to launch our new feature very soon`,
"comment.are_you_sure_to_delete": `Are you sure to delete this comment?`,
"comment.delete_comment": `Delete Comment`,
"comment.inputTip": `Please input your comment`,
"comment.placeholder": `Input comment`,
"comment.remarks": `Remarks`,
"consumer.Adding time": `Adding time`,
"consumer.Browsing Time": `Browsing Time`,
"consumer.Consent Info": `Consent Info`,
"consumer.Favorite Products": `Favorite Products`,
"consumer.NEWSLETTER OPT-IN": `NEWSLETTER OPT-IN`,
"consumer.Product Browsing History": `Product Browsing History`,
"consumer.opt-in": `Yes I'd like to receive the Malts.com newsletter and stay up to date on new releases and promotions.`,
"consumerInsights": `Consumer Insights`,
"consumerInsightsList": `Consumer Insights List`,
"consumers want": `consumers want`,
"consumptionPoints": `Consumption points`,
"copyStore is required": `copyStore is required`,
"coupon": `Coupon`,
"couponCode.length.rule": `Please enter uppercase letters or numbers, up to five characters`,
"create successfully": `create successfully`,
"customer.customer_service": `Consumer Service`,
"customer.dtc_center_support": `DTC Experience support Controls the enable/disable of the mobile consumer service.`,
"customer.mini_program": `Mini program`,
"dashboard": `dashboard`,
"dashboard.apidoc": `API Document`,
"dashboard.apidoc_desc": `Contain all the information required to work with the API with details about the functions classes return types arguments and more.`,
"dashboard.channel": `Channel`,
"dashboard.channel_desc": `Support the management of online and offline multi-channel including online store POS third-party channels etc`,
"dashboard.main": `Main Function`,
"dashboard.marketing": `Marketing Experience`,
"dashboard.marketing_desc": `Provide discounts vouchers and other marketing tools to help store drainage and drive consumers to re-purchase.`,
"dashboard.more": `Explore More`,
"dashboard.order": `Order`,
"dashboard.order_desc": `Staff can manage order status query order information and provide comments for orders.`,
"dashboard.product": `Product`,
"dashboard.product_desc": `Publish multiple types of products including bundles and regular and maintain product categories product images attributes and specifications.`,
"dashboard.shipment": `Shipment`,
"dashboard.shipment_desc": `Provide shipment tool for staff to achieve delivery of orders to consumers.`,
"dashboard.solution": `D2C Solution`,
"dashboard.solution_desc": `Solve our clients' toughest challenges by providing unmatched services in strategy consulting digital technology and operations.`,
"dashboard.subscription": `Subscription`,
"dashboard.subscription_desc": `Provide subscription tool for staff to query information modify the delivery time modify deliver address and add comment of subscription.`,
"dataManagement.actions": `Actions`,
"dataManagement.addEvent": `Add Event`,
"dataManagement.addEventAttribute": `Add Event Attribute`,
"dataManagement.addNewEvent": `Add New Event`,
"dataManagement.addNewEventAttribute": `Add New Event Attribute`,
"dataManagement.addNewPricipalAttribute": `Add New Principal Attribute`,
"dataManagement.addNewTransactionAttribute": `Add New Transaction Attribute`,
"dataManagement.addPrincipalAttribute": `Add Principal Attribute`,
"dataManagement.addTransactionAttribute": `Add Transaction Attribute`,
"dataManagement.all": `All`,
"dataManagement.applicationScenario": `Application scenario: This module mainly displays configures (view details or delete) and imports event information in single and batch including event name display name event description status so we can track the event in detail in the insight module`,
"dataManagement.associatedEvent": `Associated Event`,
"dataManagement.associatedEventAttribute": `Associated Event Attribute`,
"dataManagement.associatedEvents": `Associated Events`,
"dataManagement.attributeDescription": `Attribute Description`,
"dataManagement.attributeName": `Attribute Name`,
"dataManagement.bulkAdd": `Bulk Add`,
"dataManagement.bulkAddDescription": `Quickly upload multiple events and batch upload with one button.`,
"dataManagement.bulkAddDescriptionEventAttribute": `Quickly upload multiple event attributes and batch upload with one button.`,
"dataManagement.bulkAddDescriptionPricipalAttribute": `Quickly upload multiple principal attributes and batch upload with one button.`,
"dataManagement.bulkAddDescriptionTransactionAttribute": `Quickly upload multiple transaction attributes and batch upload with one button.`,
"dataManagement.clickDrag": `Click or drag file to this area to upload`,
"dataManagement.dataType": `Data Type`,
"dataManagement.description": `Description`,
"dataManagement.disabled": `Disabled`,
"dataManagement.displayName": `Display Name`,
"dataManagement.displayStatus": `Display Status`,
"dataManagement.downloadTemplate": `Download Template`,
"dataManagement.enabled": `Enabled`,
"dataManagement.eventAttribute": `Event Attribute`,
"dataManagement.eventAttributeDescription": `Application scenario: This module mainly displays configures (view details or delete) and imports event attribute information in single and batch including event attribute name display name event attribute description display status.`,
"dataManagement.eventAttributeDetails": `Event Attribute Details`,
"dataManagement.eventAttributeDisplayName": `Event Attribute Display Name`,
"dataManagement.eventAttributeInformation": `Event Attribute Information`,
"dataManagement.eventAttributeName": `Event Attribute Name`,
"dataManagement.eventAttributes": `Event Attributes`,
"dataManagement.eventDescription": `Event Description`,
"dataManagement.eventDetail": `Event Detail`,
"dataManagement.eventDetails": `Event Details`,
"dataManagement.eventDisplayName": `Event Attribute Display Name`,
"dataManagement.eventInformation": `Event Information`,
"dataManagement.eventName": `Event Name`,
"dataManagement.eventStatus": `Event Status`,
"dataManagement.events": `Events`,
"dataManagement.generalEvents": `General Events`,
"dataManagement.keywords": `Keywords`,
"dataManagement.locale": `Locale`,
"dataManagement.newEventAttributeDescription": `Event Attribute Description`,
"dataManagement.onlyOneAnd100M": `2.Only one file can be uploaded and ≤ 100M`,
"dataManagement.options": `Options`,
"dataManagement.preset": `Preset`,
"dataManagement.principalAttribute": `Principal Attribute`,
"dataManagement.principalAttributeDescription": `Principal Attribute Description`,
"dataManagement.principalAttributeDisplayName": `Principal Attribute Display Name`,
"dataManagement.principalAttributeName": `Principal Attribute Name`,
"dataManagement.principalAttributeScenarioDescription": `Application scenario: This module mainly displaysconfigures and imports principal attribute information in batchincluding principal attribute namedisplay nameprincipal attribute descriptiondisplay status.`,
"dataManagement.principalAttributes": `Principal Attributes`,
"dataManagement.singleAdd": `Single Add`,
"dataManagement.singleAddDescription": `Create new events one by one and flexibly modify the display name.`,
"dataManagement.singleAddDescriptionEventAttribute": `Create new event attribute one by one and flexibly modify the display name.`,
"dataManagement.singleAddDescriptionPricipalAttribute": `Create new principal attribute one by one and flexibly modify the display name.`,
"dataManagement.singleAddDescriptionTransactionAttribute": `Create new transaction attribute one by one and flexibly modify the display name.`,
"dataManagement.status": `Status`,
"dataManagement.supportFile": `1.Support file format: XLSX CSV`,
"dataManagement.transactionAttribute": `Transaction Attribute`,
"dataManagement.transactionAttributeDescription": `Transaction Attribute Description`,
"dataManagement.transactionAttributeDisplayName": `Transaction Attribute Display Name`,
"dataManagement.transactionAttributeName": `Transaction Attribute Name`,
"dataManagement.transactionAttributeScenarioDescription": `Application scenario: This module mainly displaysconfigures and imports transaction attribute information in batchincluding transaction attribute namedisplay nametransaction attribute descriptiondisplay status.`,
"dataManagement.transactionAttributes": `Transaction Attributes`,
"delete img tip": `Are you sure to delete asset {assetName}?`,
"deleteCategoryTip": `There’s a file stored under this folder. Please make sure to remove this file to other folders before deleting it.`,
"deleteConsumer": `Delete Consumer`,
"deleteConsumerAlert": `Are you sure to delete the consumer?`,
"delivery & payment info": `delivery & payment info`,
"desc.name": `Description Name`,
"description.Action": `Action`,
"description.AddNewDescription": `Add New Description`,
"description.CustomizedContent": `Customized content`,
"description.DescriptionDetail": `Description Detail`,
"description.DescriptionStatus": `Description Status`,
"description.DescriptionType": `Description Type`,
"description.Description_label": `Description Label`,
"description.Disable": `Disable`,
"description.DisplayName": `Display Name`,
"description.Enable": `Enable`,
"description.Status": `Status`,
"description.UnifiedContent": `Unified content`,
"description.cancel": `No`,
"description.confirm": `Yes`,
"description.deleteItemTip": `Deleted description can't be recovered.`,
"description.delete_item": `Are you sure to delete this description?`,
"description.descriptionLabelTip": `Please enter description label`,
"descrptions": `Descrptions`,
"detail page": `detail`,
"disabled": `disabled`,
"display name": `Display Name`,
"downloadLater": `Download Later`,
"edit": `edit`,
"edit page": `edit`,
"email": `Email`,
"empower_your_AI": `Empower your AI, Data and Digitalization journey!`,
"empower_your_AI.subTitle": `Smart Operation aims to empower individuals to achieve greater success in the dynamic fields of Artificial Intelligence, Data, and Digitalization. Our goal is to provide support and guidance to help people navigate and excel in these exciting areas of innovation and growth.`,
"enabled": `enabled`,
"endTime": `End Time`,
"event groups": `event groups`,
"export report task tips": `The export report task is currently in progress and will soon be available in the Sales Channel/Export report.`,
"filter.Attribute Filter": `Attribute Filter`,
"filter.Attribute Label": `Attribute Label`,
"filter.AttributeValue": `Attribute Value`,
"filter.Customized Filter": `Customized Filter`,
"filter.Display Name": `Display Name`,
"filter.DisplayName": `Display Name`,
"filter.FilterLable": `Attribute Label`,
"filter.My Filters & Sort": `My Filters & Sort`,
"filter.My Filters & Sort detail": `Filters are used by customer to filter to find desired products. Attribute filter tag filter and customized filter are supported and could be configured below! The enabled sort conditions will show in the ‘Sort by’ section for customer to sort products in desired orders.`,
"filter.SelectAttribute": `Select Attribute`,
"filter.SelectTag": `Select Tag`,
"filter.Sort By": `Sort By`,
"filter.SortFieldName": `Sort Field Name`,
"filter.SortFieldStatus": `Sort Field Status`,
"filter.Tag Filter": `Tag Filter`,
"filter.TagName": `Tag Name`,
"finance.application_period": `Application Period`,
"finance.choose_invoice_type": `Choose Invoice Type`,
"finance.invoice.actions": `Actions`,
"finance.invoice.all": `All`,
"finance.invoice.application_time": `Application Time`,
"finance.invoice.download": `Download`,
"finance.invoice.email_address": `Email Address`,
"finance.invoice.invoice_title": `Invoice Title`,
"finance.invoice.invoice_type": `Invoice Type`,
"finance.invoice.invoiced": `Invoiced`,
"finance.invoice.invoiced_failed": `Invoice failed`,
"finance.invoice.invoicing": `Invoicing`,
"finance.invoice.not_invoiced": `Not invoiced`,
"finance.invoice.order_amount": `Order Amount`,
"finance.invoice.order_number": `Order Number`,
"finance.invoice.status": `Status`,
"finance.invoice_list": `Invoice List`,
"finance.invoice_title": `Invoice Title`,
"finance.invoice_type": `Invoice Type`,
"finance.order_number": `Order Number`,
"finance.reset": `Reset`,
"finance.search": `Search`,
"fraud_analysis.rule1": `Compare characteristics of this order with fraudulent orders observed in the past.`,
"fraud_analysis.rule2": `Compare the customer’s IP address of placing order with the shipping address provided.`,
"goalTierSetting": `Goal Tier Setting`,
"hasBeenPaused": `{arg1} has been paused.`,
"hasBeenRestarted": ` {arg1} has been paused.
`,
"header.Blue": `Blue`,
"header.CDP Experience": `Consumer Experience`,
"header.Consumer Experience": `Consumer Experience`,
"header.Content Experience": `Content Experience`,
"header.Fulfilment Experience": `Fulfillment Experience`,
"header.Global Setting": `Global Setting`,
"header.MA Experience": `MA Experience`,
"header.Open Platform": `Open Platform`,
"header.Orange": `Orange`,
"header.Sales Experience": `Sales Experience`,
"header.Smart Operation": `Smart Operation`,
"header.Switch Language": `Switch Language`,
"header.Switch Theme": `Switch Theme`,
"header.back_to_login_page": `Back to Login Page`,
"header.dtc_center": `DTC Experience`,
"header.efficient_store_tool": `Efficient store management tools`,
"header.logout": `Logout`,
"input category name": `input category name`,
"input.consumer name": `Please input consumer name`,
"input.phone number": `Please input phone number`,
"insights": `insights`,
"insights.Activity": `Activity`,
"insights.Behavior": `Behavior`,
"insights.Behavior Tags": `Behavior Tags`,
"insights.Boolean": `Boolean`,
"insights.Date": `Date`,
"insights.Datetime": `Datetime`,
"insights.High": `High`,
"insights.Int": `Int`,
"insights.Low": `Low`,
"insights.Loyalty": `Loyalty`,
"insights.Manual": `Manual`,
"insights.Marketing Preferences": `Marketing Preferences`,
"insights.Medium": `Medium`,
"insights.Product Preferences": `Product Preferences`,
"insights.Profile Tags": `Profile Tags`,
"insights.Regular": `Regular`,
"insights.Segment": `Segment`,
"insights.String": `String`,
"insights.Tags": `Tags`,
"insights.Total Segments": `Total Segments`,
"insights.Total Tags": `Total Tags`,
"insights.Value": `Value`,
"insights.Value Tags": `Value Tags`,
"insights.age": `Age`,
"insights.baseId": `Base ID`,
"insights.birthday": `Birthday`,
"insights.city": `City`,
"insights.consumerId": `Member ID`,
"insights.email": `Email`,
"insights.gender": `Gender`,
"insights.lastLoginTime": `Last login time`,
"insights.memberTier": `Member Tier`,
"insights.name": `Consumer Name`,
"insights.numberOfConsumers": `Number Of Consumers`,
"insights.phoneNumber": `Phone Number`,
"insights.placeholder": `Base ID / Name / Email / Phone number / Member Tier`,
"inventory.Add Products": `Add Products`,
"inventory.Arrange Outbound": `Arrange Outbound`,
"inventory.Arrange Shipment": `Arrange Shipment`,
"inventory.Associated Logical warehouse": `Associated Logical warehouse`,
"inventory.Associated Physical warehouse": `Associated Physical warehouse`,
"inventory.Associated Store": `Associated Store`,
"inventory.Available": `Available`,
"inventory.Available Tip": `Available = On Hand - Locked - Reserved + In Transit - Safety`,
"inventory.Brand": `Brand`,
"inventory.Consumer Name": `Consumer Name`,
"inventory.EAN Code": `EAN`,
"inventory.Enable Safety Stock": `Enable Safety Stock`,
"inventory.In Transit": `In Transit`,
"inventory.Inbound": `Inbound`,
"inventory.Inbound Create Time": `Inbound Create Time`,
"inventory.Inbound Lines": `Inbound Lines`,
"inventory.Inbound Number": `Inbound Number`,
"inventory.Inbound Products": `Inbound Products`,
"inventory.Inbound Status": `Inbound Status`,
"inventory.Inbound Succeed": `Inbound Succeed`,
"inventory.Inbound Summary": `Inbound Summary`,
"inventory.Inbound Warehouse": `Inbound Warehouse`,
"inventory.Locked": `Locked`,
"inventory.On Hand": `On Hand`,
"inventory.Outbound": `Outbound`,
"inventory.Outbound Create Time": `Outbound Create Time`,
"inventory.Outbound Lines": `Outbound Lines`,
"inventory.Outbound Number": `Outbound Number`,
"inventory.Outbound Status": `Outbound Status`,
"inventory.Outbound Summary": `Outbound Summary`,
"inventory.Outbound Warehouse": `Outbound Warehouse`,
"inventory.Product Category": `Product Category`,
"inventory.Products Name": `Products Name`,
"inventory.Qty": `Qty`,
"inventory.Received Qty": `Received Qty`,
"inventory.Related Order Number": `Related Order Number`,
"inventory.Reserved": `Reserved`,
"inventory.SKU Code": `SKU`,
"inventory.SPU": `SPU`,
"inventory.Safety": `Safety`,
"inventory.Search Inbound Number": `Search Inbound Number`,
"inventory.Search Outbound Number": `Search Outbound Number`,
"inventory.Search SKU Code": `Search SKU`,
"inventory.Ship From": `Ship From`,
"inventory.Shipment Number": `Shipment Number`,
"inventory.Shipped Qty": `Shipped Qty`,
"inventory.Shipper Name": `Shipper Name`,
"inventory.To Receive Qty": `To Receive Qty`,
"inventory.To ship Qty": `To ship Qty`,
"inventory.Total Received Qty": `Total Received Qty`,
"inventory.Total Shipped Qty": `Total Shipped Qty`,
"inventory.Total To Receive Qty": `Total To Receive Qty`,
"inventory.Total To ship Qty": `Total To ship Qty`,
"inventory.Tracking Detail": `Tracking Detail`,
"inventory.Warehouse Name": `Warehouse Name`,
"inventory.Warehouse information": `Warehouse information`,
"inventory.inbound success Tip": `Are you sure to inbound successfully?`,
"inventory.outbound": `Outbound`,
"ip": `ip`,
"ip BlockList": `ip BlockList`,
"is mainly to generate base ID of Consumer Experience business module according to flexible business logic.": ` is mainly to generate base ID of Consumer Experience business module according to flexible business logic. Base ID is the unique user ID of Consumer Experience business module which mainly generated by mapping consumers' unique identification ID (consumer id phone number email IP etc.) of each channel (EC activity Ads activity etc.) according to reasonable logical rules.`,
"isMember is required": `isMember is required`,
"items": ` items`,
"items selected": `items selected`,
"last two years": `Last 2 Years`,
"last year": `Last Year`,
"loadMore": `Load More`,
"login.account_number": `Account number`,
"login.code_send_failed": `Validation code send failed please try again!`,
"login.confirm_password": `Confirm password`,
"login.did_your_receive": `Did not receive the code?`,
"login.donot_have_an_account": `Don't have an account?`,
"login.enter_new_password": `Enter new password`,
"login.enter_password": `Enter password`,
"login.enter_phone_number": `Enter phone number`,
"login.enter_user_name": `Enter user name`,
"login.enter_valid_phone": `Enter a valid Phone. example: 13101227768`,
"login.enter_verify_code": `Enter verification code`,
"login.expired_tip": `Login expired please login again!`,
"login.forget_password": `Forget password?`,
"login.incorrect_code": `Incorrect code!`,
"login.login": `Log In`,
"login.password": `Password`,
"login.password_should_match": `Two passwords should match!`,
"login.phone_not_registed": `Phone is not registed as a user!`,
"login.please_confirm_password": `Please confirm your password!`,
"login.please_enter_code": `Please input validation code`,
"login.please_input_account_number": `Please input your account number`,
"login.please_input_password": `Please input your password`,
"login.please_input_phone": `Please input your phone number`,
"login.please_input_username": `Please input your username`,
"login.please_select_brand": `Please select brand!`,
"login.please_select_brand_of_store": `Please select the brand of your store`,
"login.please_select_store": `Please select store`,
"login.please_select_store_of": `Please select a store of`,
"login.provide_better_service": `Let us provide you with better service`,
"login.register": `Register`,
"login.remember_me": `Remember me`,
"login.resend": `Resend`,
"login.resend_code": `Resend code`,
"login.select_brand": `Select brand`,
"login.select_store": `Select store`,
"login.signup": `Sign up`,
"login.user_wrong": `Username or password is wrong!`,
"login.verify_sent_to": `Your verification code is sent to`,
"logisticRuleAdd": `Add`,
"logisticRuleDetail": `Detail`,
"logisticRuleEdit": `Edit`,
"loyalProgram": `Loyalty Program`,
"loyalProgramDescription": `Program Description`,
"loyalProgramIcon": `Program Icon`,
"loyalProgramName": `Program Name`,
"loyalPrograms": `Loyalty Programs`,
"loyalPrograms.Create Loyalty Program": `Create Loyalty Program`,
"loyalPrograms.Create Rewards": `Create Rewards`,
"loyalPrograms.Customized Loyalty Program": `Go & Experience Your Customized Loyalty Program!`,
"loyalPrograms.Set up Points Rules": `Set up Points Rules`,
"loyalPrograms.Set up Tiers": `Set up Tiers`,
"loyalPrograms.Start From Here": `Start From Here`,
"loyalPrograms.add": `Add Program`,
"loyalPrograms.desc": `A loyalty program is a strategy that companies use to encourage continued consumer loyalty and long-term repeated business. Under this section you can set up your own customized loyalty program based on your real business needs! Multiple loyalty programs are supported simultaneously.`,
"loyalPrograms.description": `There’s no loyalty program created yet! Please be aware that a tailed loyalty program is one of the best way to maximize consumers’ engagement and increase lifetime value. Follow below steps to create your first loyalty program!`,
"loyalPrograms.step1": `STEP 1`,
"loyalPrograms.step2": `STEP 2`,
"loyalPrograms.step3": `STEP 3`,
"loyalPrograms.step4": `STEP 4`,
"loyalPrograms.step5": `STEP 5`,
"marketing.boost_sale_promo": `Boost Sales with Promotion`,
"marketing.boost_sale_promo_s1": `Increase orders by offering buyers reduced prices at checkout with vouchers`,
"marketing.boost_sale_promo_s2": `Set discounts on your products to boost Sales`,
"marketing.boost_sale_promo_s3": `Boost product sales by creating campaigns in your shop`,
"marketing.boost_sale_promo_t1": `Vouchers`,
"marketing.boost_sale_promo_t2": `Discount Promotions`,
"marketing.boost_sale_promo_t3": `Campaigns`,
"marketing.engage_petowner": `Engage with Your Consumer`,
"marketing.engage_petowner_s1": `Connect Live with your audience and answer shopper questions easily`,
"marketing.engage_petowner_t1": `Live Streaming`,
"marketing.marketing_center": `Marketing Experience`,
"marketing.recom_basic_setting": `Recommended Basic Settings`,
"marketing.recom_basic_setting_s1": `Manually set weight for products or ban certain products`,
"marketing.recom_basic_setting_t1": `Product Recommendation Management`,
"marketing.recom_spot_manage": `Recommended Spot management`,
"marketing.recom_spot_manage_s1": `Recommendation based on product relativity`,
"marketing.recom_spot_manage_s2": `Recommendation based on user interest`,
"marketing.recom_spot_manage_s3": `Top recommendation`,
"marketing.recom_spot_manage_s4": `Recommendation based on user interest`,
"marketing.recom_spot_manage_s5": `Recommendation based on user interest`,
"marketing.recom_spot_manage_s6": `Top recommendation`,
"marketing.recom_spot_manage_s7": `Recommendation based on product relativity`,
"marketing.recom_spot_manage_s8": `Recommendation based on user interest`,
"marketing.recom_spot_manage_t1": `Shopping Cart`,
"marketing.recom_spot_manage_t2": `Product Detail`,
"marketing.recom_spot_manage_t3": `Product List`,
"marketing.recom_spot_manage_t4": `My Account`,
"marketing.recom_spot_manage_t5": `Member Experience`,
"marketing.recom_spot_manage_t6": `Order Confirmation Page`,
"marketing.recom_spot_manage_t7": `Category`,
"marketing.recom_spot_manage_t8": `Category Recommendation Channels`,
"marketing.recom_strategy_manage": `Recommendation Strategy Management`,
"marketing.recom_strategy_manage_s1": `Sorting products according to the overall dimensional statistics which is usually used in Hot Sale.`,
"marketing.recom_strategy_manage_s2": `Recommend items related to the current item to enhance product co-sale. Commonly found in modules such as Related Recommendations on the product details page.`,
"marketing.recom_strategy_manage_s3": `Provide custom recommendation service including recommending products based on user interests which is often used in modules such as you may also like.`,
"marketing.recom_strategy_manage_s4": `Provide custom recommendation service including recommending products based on subscription type and pet information which is often used in modules such as product finder.`,
"marketing.recom_strategy_manage_t1": `Top Recommendation`,
"marketing.recom_strategy_manage_t2": `Recommendation based on product relativity`,
"marketing.recom_strategy_manage_t3": `Recommendation Based On Consumer Interest`,
"marketing.recom_strategy_manage_t4": `Product finder`,
"max of 140 chars": `max of 140 chars`,
"max of 55 chars": `max of 55 chars`,
"menu.Activity Logs": `Activity Logs`,
"menu.Campaign plan": `Campaign Plan`,
"menu.Categories": `Post Categories`,
"menu.Compaign plan": `Compaign plan`,
"menu.Consumer Experience": `Consumer Experience`,
"menu.Content Experience": `Content Experience`,
"menu.External SKU": `External SKU`,
"menu.Fulfilment Experience": `Fulfillment Experience`,
"menu.Global Setting": `Global Setting`,
"menu.JobList": `Job List`,
"menu.MA Experience": `MA Experience`,
"menu.Metadata": `Metadata`,
"menu.Open Platform": `Open Platform`,
"menu.Order Eligible For Return": `Order Eligible For Return`,
"menu.Process Canvas": `Process Canvas`,
"menu.Quick Task": `Quick Task`,
"menu.Return Order": `Return Order`,
"menu.Sales Experience": `Sales Experience`,
"menu.Smart Operation": `Smart Operation`,
"menu.Storybook": `Storybook`,
"menu.Sub SKU": `Sub SKU`,
"menu.Tags": `Post Tags`,
"menu.Tax Class": `Tax Class`,
"menu.User List": `User List`,
"menu.Weight Unit": `Weight Unit`,
"menu.Weight Value": `Weight Value`,
"menu.account_management": `Account Management`,
"menu.addNewPosts": `Add New Posts`,
"menu.addNewSegments": `Add New Segments`,
"menu.addNewTags": `Add New Tags`,
"menu.add_new_product": `Add New Product`,
"menu.address schema": `Address Schema`,
"menu.address scope": `Address Scope`,
"menu.adress_management": `Address`,
"menu.aireco": `AIReco`,
"menu.aireco_models": `Al Reco Models`,
"menu.allPosts": `All Posts`,
"menu.app_setting": `Application`,
"menu.assets_management": `Assets Management`,
"menu.attributes": `Attributes`,
"menu.auditOrder": `Audit Order`,
"menu.automatic_replies": `Automatic Replies`,
"menu.backOrder": `Back Order`,
"menu.basic_information": `Basic Information`,
"menu.bulkAdd": `Bulk Add`,
"menu.carousel2": `Carousel2`,
"menu.channel": `Channel`,
"menu.channel_options": `Channel Options`,
"menu.cmsAsets": `Assets`,
"menu.cmsCollections": `Collections`,
"menu.cmsMateData": `Mate Data`,
"menu.cmsPages": `Pages`,
"menu.cmsPages.addNewPage": `Add New Page`,
"menu.cmsPages.pageList": `Page List`,
"menu.cmsPosts": `Posts`,
"menu.consumerInsights": `Consumer Insights`,
"menu.contentTypes": `Content Types`,
"menu.customer_service": `Consumer Service`,
"menu.dashboard": `Dashboard`,
"menu.dataIngestion": `Data Ingestion`,
"menu.dataManagement": `Data Management`,
"menu.description": `Descriptions`,
"menu.emailList": `Email Template`,
"menu.eventAttribute": `Event Attribute`,
"menu.eventAttributes": `Event Attributes`,
"menu.events": `Events`,
"menu.exports": `Imp & Exp`,
"menu.fans_management": `Fans Management`,
"menu.filter": `Filters & Sort`,
"menu.finance": `Finance`,
"menu.fulfillment": `Fulfillment`,
"menu.generalEvents": `General Events`,
"menu.idMapping": `ID Mapping`,
"menu.inboundMgt.": `Inbound Mgt.`,
"menu.insights": `Insights`,
"menu.intelligent_recommendation": `Intelligent Recommendation`,
"menu.interface": `Interface`,
"menu.inventory": `Inventory`,
"menu.invoice_list": `Invoice List`,
"menu.jobCenter": `Job Center`,
"menu.log": `Log`,
"menu.logical": `Logical`,
"menu.loyalty": `Loyalty`,
"menu.loyaltyPrograms": `Loyalty Programs`,
"menu.loyalty_program": `Loyalty Program`,
"menu.marketing": `Marketing`,
"menu.marketing_center": `Marketing Center`,
"menu.measurement_family": `Measurement Family`,
"menu.measurements_manage": `Measurements`,
"menu.menu_management": `Menu Management`,
"menu.messageCenter": `Message Center`,
"menu.messagelist": `Message List`,
"menu.metaData": `Meta Data`,
"menu.mini_program_banner": `Mini Program Banner`,
"menu.mini_program_qr_code": `Mini Program QR Code`,
"menu.my_orders": `My Orders`,
"menu.my_pet_owner": `Consumer List`,
"menu.my_products": `Product List`,
"menu.my_shipment": `My Shipment`,
"menu.my_subscriptions": `My Subscriptions`,
"menu.nodeAssociation": `Inventory Network`,
"menu.online_store": `Online Store`,
"menu.order": `Order`,
"menu.order monitor": `Order monitor`,
"menu.order_flow": `Order Flow`,
"menu.order_setting": `Order Setting`,
"menu.orders": `Orders`,
"menu.org": `Org Setting`,
"menu.org_management": `Org Management`,
"menu.outboundMgt.": `Outbound Mgt.`,
"menu.partner": `Partner`,
"menu.partner_list": `Partner List`,
"menu.partner_type": `Partner Type`,
"menu.payment_settings": `Payment Settings`,
"menu.pet_owner": `Consumer`,
"menu.physical": `Physical`,
"menu.points": `Points`,
"menu.points_of_sales": `Points of Sales`,
"menu.preferences": `Preferences`,
"menu.principalAttribute": `Principal Attribute`,
"menu.principalAttributes": `Principal Attributes`,
"menu.product": `Product`,
"menu.product.categrey": `Product Category`,
"menu.productSetting": `Product Setting`,
"menu.qr_code_management": `QR Code Management`,
"menu.quickStart": `Quick Start`,
"menu.recommendation_history": `Recommendation History`,
"menu.referrals": `Referrals`,
"menu.region_language": `Region / Language`,
"menu.reply_content": `Reply Content`,
"menu.rewardHistory": `Reward History`,
"menu.rewardSettings": `Reward Settings`,
"menu.salesOrder": `Sales Order`,
"menu.search": `Search`,
"menu.segmentsList": `Segments List`,
"menu.segmentsManagement": `Segments Management`,
"menu.setting": `Setting`,
"menu.setting.cdn": `CDN`,
"menu.setting.privacy": `Privacy`,
"menu.setting.webhook": `Webhook`,
"menu.settings": `Webhook`,
"menu.shipment": `Shipment`,
"menu.shippingMethod": `Shipping method`,
"menu.shippingMethod.carrierSetting": `Carrier Setting`,
"menu.shippingMethod.shippingMethods": `Shipping Methods`,
"menu.shippingMethod.transitionMethods": `Transition Methods`,
"menu.shipping_setting": `Shipping Setting`,
"menu.shop": `Shop`,
"menu.shop_category": `Shop Category`,
"menu.singleAdd": `Single Add`,
"menu.site_map": `Sitemap`,
"menu.smart_operation": `Smart Operation`,
"menu.smsList": `SMS Template`,
"menu.social_media": `Sales Channel`,
"menu.sourcingRule": `Sourcing Rule`,
"menu.store_currency": `Currency`,
"menu.store_details": `Store Details`,
"menu.store_management": `Store`,
"menu.store_standards_format": `Standards and format`,
"menu.subscription": `Subscription`,
"menu.subscriptions_setting": `Subscription Setting`,
"menu.suggestions": `Address Suggestion`,
"menu.tagManagement": `Tag Management`,
"menu.tagging_setting": `Consumer Tagging`,
"menu.tagsList": `Tags List`,
"menu.taskCenter": `Task Center`,
"menu.taskList": `Task List`,
"menu.tax": `Tax Setting`,
"menu.tax_management": `Tax Management`,
"menu.templateManagement": `Templates`,
"menu.template_message": `Template Message`,
"menu.themes": `Themes`,
"menu.transactionAttribute": `Transaction Attribute`,
"menu.transactionAttributes": `Transaction Attributes`,
"menu.transferOrder": `Transfer Order`,
"menu.translation": `Translation`,
"menu.user": `User`,
"menu.valetorder": `Valet Order`,
"menu.warehouse": `Inventory Setup`,
"menu.wechat_mini_program": `Mini Program`,
"messageCenter.Campaign ID": `Voucher ID`,
"messageCenter.Campaign name": `Voucher name`,
"messageCenter.Consumer Account": `Consumer Account`,
"messageCenter.Consumer Email": `Consumer Email`,
"messageCenter.Consumer Phone Number": `Consumer Phone Number`,
"messageCenter.Consumer Type": `Consumer Type`,
"messageCenter.CreateAt": `Create Time`,
"messageCenter.Finish": `Finish`,
"messageCenter.Message Detail": `Message Detail`,
"messageCenter.Object No.": `Object No.`,
"messageCenter.Object Type": `Object Type`,
"messageCenter.Options": `Options`,
"messageCenter.Pending": `Pending`,
"messageCenter.Push Channel": `Push Channel`,
"messageCenter.Push type": `Push Type`,
"messageCenter.Reciplent": `Recipient`,
"messageCenter.Reciplents": `Recipients`,
"messageCenter.Satus": `Status`,
"messageCenter.SendStatus": `Send Status`,
"messageCenter.Signature": `Signature`,
"messageCenter.Source": `Source`,
"messageCenter.Task ID": `Task ID`,
"messageCenter.Template Name": `Template Name`,
"messageCenter.quickSend": `Quick Send`,
"messageCenter.refresh": `Refresh`,
"messageCenter.sentTime": `Sent Time`,
"noData": `no data`,
"noMoreData": `no more data`,
"obtainBaiduStatisticStep1": `1.Enter in <strong>Account Management Tab</strong>.`,
"obtainBaiduStatisticStep2": `2.Click 'Add New Website' and then input required info including website domain, website name etc.`,
"obtainBaiduStatisticStep3": `3.Enter in <strong>Code Acquisition Tab</strong>, and then script can be found & copied.`,
"off": `off`,
"oms.quickstart.Pending Review": `Pending Review`,
"oms.quickstart.Pending Review tip": `Total quantity of orders that order status is "pending review"`,
"on": `on`,
"operation.by": `By`,
"operation.log": `Operation log`,
"or Drag and Drop File": `or Drag and Drop File`,
"order.Accept": `Accept`,
"order.Accounts are optional": `Accounts are optional`,
"order.Accounts are required": `Accounts are required`,
"order.Amount Quantity": `Quantity`,
"order.Application time": `Application time`,
"order.Approve": `Approve`,
"order.Attachment information": `Attachment information`,
"order.Attachment information tip": `Please add your return credentials to the attachment. Supported picture formats: JPG JPEG PNG GIF file size no more than 5M and upload a maximum of 10`,
"order.Automatic Audit": `Automatic Audit`,
"order.Cancel order": `Cancel order`,
"order.Choose if you want to prompt your consumer to create an account when they check out": `Choose if you want to prompt your consumer to create an account when they check out`,
"order.Commodity": `Commodity`,
"order.Confirm Refund": `Confirm Refund`,
"order.Consumer account": `Consumer account`,
"order.Consumer name": `Consumer name`,
"order.Consumer type": `Consumer type`,
"order.Consumers will be able to check out with a consumer account or as a guest": `Consumers will be able to check out with a consumer account or as a guest`,
"order.Consumers will only be able to check out if they have a consumer account.": `Consumers will only be able to check out if they have a consumer account.`,
"order.Custom Checkout Account": `Custom Checkout Account`,
"order.Customize order number format and this change will apply to future orders": `Customize order number format and this change will apply to future orders`,
"order.Enable This Manual Audit Condition": `Enable This Manual Audit Condition`,
"order.Fill in logistics": `Fill in logistics`,
"order.From": `From`,
"order.Initiate after-sales application": `Initiate after-sales application`,
"order.Operation Category": `Operation Category`,
"order.Order Audit Setting": `Order Audit Setting`,
"order.Order Flow Setting": `Order Flow Setting`,
"order.Order meeting the following conditions requires manual audit": `Order meeting the following conditions requires manual audit`,
"order.Order number": `Order number`,
"order.Payment status": `Payment status`,
"order.Pending refund": `Pending refund`,
"order.Pending review": `Pending review`,
"order.Product Name": `Product Name`,
"order.Product name": `Product name`,
"order.Quantity returned": `Quantity returned`,
"order.Real refund": `Real refund`,
"order.Reason": `Reason`,
"order.Recipient": `Recipient`,
"order.Recipient accepted": `Recipient accepted`,
"order.Recipient phone": `Recipient phone`,
"order.Recipient rejected": `Recipient rejected`,
"order.Refund time": `Refund time`,
"order.Refunded amount": `Refunded amount`,
"order.Refused to refund": `Refused to refund`,
"order.Reject": `Reject`,
"order.Rejected": `Rejected`,
"order.Return Order Application": `Return Order Application`,
"order.Return Order Detail": `Return Order Detail`,
"order.Return description": `Return description`,
"order.Return list": `Return list`,
"order.Return method": `Return method`,
"order.Return order attachment": `Return order attachment`,
"order.Return order information": `Return order information`,
"order.Return order number": `Return order number`,
"order.Return order status": `Return order status`,
"order.Return quantity": `Return quantity`,
"order.Return reason": `Return reason`,
"order.Return unit price": `Return unit price`,
"order.Returnable number": `Returnable number`,
"order.SKU code": `SKU code`,
"order.Select Color": `Select Color`,
"order.Select return products": `Select return products`,
"order.Shipping status": `Shipping status`,
"order.Skip logistics": `Skip logistics`,
"order.Specification": `Specification`,
"order.Subtotal of return amount": `Subtotal of return amount`,
"order.To": `To`,
"order.To be delivered": `To be delivered`,
"order.To be received": `To be received`,
"order.To refund amount": `To refund amount`,
"order.Total returnable number": `Total returnable number`,
"order.Weight": `Weight`,
"order.arrangeShipment": `Arrange shipment`,
"order.carrier.all": `All Carrier`,
"order.carrier.best": `BEST Express`,
"order.carrier.sf": `SF Express`,
"order.carrier.sto": `STO Express`,
"order.carrier.yto": `YTO Express`,
"order.carrier.yunda": `Yunda Express`,
"order.carrier.zto": `ZTO Express`,
"order.carrierCompany": `Carrier company`,
"order.carrierCompanyTip": `Please select carrier company`,
"order.carrierNumber": `Carrier number`,
"order.carrierNumberTip": `Please input carrier number`,
"order.carrier_info": `Carrier information`,
"order.carrier_name": `Carrier Name`,
"order.carrier_number": `Carrier Number`,
"order.completeTip": `Are you sure to complete the order?`,
"order.completed": `Completed`,
"order.confirm refund p1": `Do you confirm the refund?`,
"order.confirm refund p2": `What is the amount and points of the refund?`,
"order.confirm refund p3": `The default is the amount that the actual amount for the product does not include servife fee and shipping fee.`,
"order.consumer_information": `Consumer Information`,
"order.create_date": `Order Creation Date`,
"order.export.download_tip": `Here are the reports you have not downloaded`,
"order.export.export": `Export`,
"order.export.latest_reports": `Latest Reports`,
"order.export.my_exports": `My Reports`,
"order.export.report_name": `Report name`,
"order.export.view_all": `View all in`,
"order.information": `Product Information`,
"order.operation_log": `Operation Log`,
"order.orderCompleted": `Order Completed`,
"order.orderId": `Order Number`,
"order.orderTime": `Order Time`,
"order.order_amount": `Order amount`,
"order.order_info": `Product Information`,
"order.order_status": `Order status`,
"order.order_total": `Order Total`,
"order.order_type": `Order Type`,
"order.orders": `Orders`,
"order.payChannelName": `payChannelName`,
"order.payWayName": `payWayName`,
"order.payment": `Payment`,
"order.payment_method": `Payment method`,
"order.payment_status": `Payment Status`,
"order.payment_time": `Payment time`,
"order.pet_owner_name": `Consumer Name`,
"order.process": `Order Process`,
"order.product_amount": `Products amount`,
"order.products": `Product(s)`,
"order.promotion_amount": `Promotion amount`,
"order.receiver": `Receiver`,
"order.rule1": `Place more than 10 orders within 10 mins`,
"order.rule2": `Consumer Blocklist`,
"order.rule3": `After order generation`,
"order.setting.Customize order number format and this change will apply to future orders": `Customize order number format and this change will apply to future orders`,
"order.setting.Fraud Detection Criteria": `Fraud Detection Criteria`,
"order.setting.Number format": `Number format`,
"order.setting.Order Number Format": `Order Number Format`,
"order.setting.Order not generated": `Order not generated`,
"order.setting.Return Order": `Return Order`,
"order.setting.Sales Order": `Sales Order`,
"order.setting.Value": `Value`,
"order.setting.general": `General order setting`,
"order.setting.prefix": `prefix`,
"order.setting.suffix": `suffix`,
"order.setting.tip_1": `After`,
"order.setting.tip_2": `minutesif the PO has not paid successfullythe order would be automatically cancelled`,
"order.setting.tip_3": `days of the order status is shippedthe order would be automatically completed`,
"order.shipmentDate": `Shipment Date`,
"order.shipmentDateTip": `Please select shipment Date`,
"order.shipping_address": `Shipping address`,
"order.shipping_fee": `Shipping fee`,
"order.status.cancel": `Order was cancelled`,
"order.status.completed": `Order was completed`,
"order.status.init": `Order was created`,
"order.status.pay": `Pay successfully`,
"order.status.ship": `Order was shipped`,
"order.status.tab.all": `All`,
"order.status.tab.canceled": `Cancellation`,
"order.status.tab.completed": `Completed`,
"order.status.tab.shipped": `Shipped`,
"order.status.tab.to_ship": `To ship`,
"order.status.tab.unpaid": `Unpaid`,
"order.subscriptionId": `Subscription Number`,
"order.transaction_id": `Transaction ID`,
"order.type.single": `Single Order`,
"order.type.subscription": `Subscription`,
"order.variation": `Variation`,
"order.viewDetail": `View order details`,
"order.view_amount_detail": `View order amount detail`,
"order.view_carrier_detail": `View carrier detail`,
"orders.Abnormal": `Abnormal`,
"orders.Abnormal address": `Abnormal address`,
"orders.Abnormal logistics": `Abnormal logistics`,
"orders.Abnormal product": `Abnormal product`,
"orders.Actual Ship Date": `Actual Ship Date`,
"orders.Add Tag": `Add Tag`,
"orders.Address": `Address`,
"orders.Amount": `Amount`,
"orders.Amount Paid": `Amount Paid`,
"orders.Amount Paid Tip": `Total order actual payment amount`,
"orders.Apply Hold": `Apply Hold`,
"orders.Audit Log": `Audit Log`,
"orders.Audit Log Detail": `Audit Log Detail`,
"orders.Audit Order": `Audit Order`,
"orders.Audit Order Notification": `Audit Order Notification`,
"orders.Audit order": `Audit order`,
"orders.Authorized Amount": `Authorized Amount`,
"orders.Authorized Time": `Authorized Time`,
"orders.Back Order": `Back Order`,
"orders.Back Order Comment": `Back Order Comment`,
"orders.Back Order Type": `Back Order Type`,
"orders.Bill to": `Bill to`,
"orders.Cancel Order": `Cancel Order`,
"orders.Cancel Order Note": `Note: After canceling the order the amount and point will be refunded in the original way the specific refund time is determined by PSP refund`,
"orders.Cancelled": `Cancelled`,
"orders.Captured Amount": `Captured Amount`,
"orders.Captured Time": `Captured Time`,
"orders.Carrier": `Carrier`,
"orders.Channel": `Channel`,
"orders.Charges": `Charges`,
"orders.City": `City`,
"orders.Collection": `Collection`,
"orders.Completed": `Completed`,
"orders.Confirm Delivery": `Confirm Delivery`,
"orders.Consumer Name": `Consumer Name`,
"orders.Country": `Country`,
"orders.Customer cancel order": `Customer cancel order`,
"orders.Delivered": `Delivered`,
"orders.Designated Fulfillment Method": `Designated Fulfillment Method`,
"orders.Designated logistic": `Designated logistic`,
"orders.Designated store": `Designated store`,
"orders.Designated warehouse": `Designated warehouse`,
"orders.Email Address": `Email Address`,
"orders.Expected Ship Date": `Expected Ship Date`,
"orders.External Order Created Time": `External Order Created Time`,
"orders.External Order Number": `External Order Number`,
"orders.Failed": `Failed`,
"orders.Fraudulent order": `Fraudulent order`,
"orders.Hold": `Hold`,
"orders.Invoice Amount": `Invoice Amount`,
"orders.Invoice Information": `Invoice Information`,
"orders.Invoice Method": `Invoice Method`,
"orders.Invoice Number": `Invoice Number`,
"orders.Invoice Status": `Invoice Status`,
"orders.Invoice Time": `Invoice Time`,
"orders.Invoice Title": `Invoice Title`,
"orders.Line Qty": `Line Qty`,
"orders.Locked": `Locked`,
"orders.Logistic Company": `Logistic Company`,
"orders.Logistic Detail": `Logistic Detail`,
"orders.Logistic Number": `Logistic Number`,
"orders.Manual Price Adjustment": `Manual Price Adjustment`,
"orders.Manual Price Adjustment Tip": `The total of manual price adjustments for orders and products`,
"orders.Manual Sourcing": `orders.Manual Sourcing`,
"orders.Method": `Method`,
"orders.Node": `Node`,
"orders.Not Sourcing": `Not Sourcing`,
"orders.Operation Log": `Operation Log`,
"orders.Operation Time": `Operation Time`,
"orders.Operation type": `Operation type`,
"orders.Operator": `Operator`,
"orders.Order Amount": `Order Amount`,
"orders.Order Amount Detail": `Order Amount Detail`,
"orders.Order Amount Tip": `Total order payable amount`,
"orders.Order Created Time": `Order Created Time`,
"orders.Order Discount": `Order Discount`,
"orders.Order Discount Tip": `Order discount including product and order level discount`,
"orders.Order Lines": `Order Lines`,
"orders.Order Number": `Order Number`,
"orders.Order Status": `Order Status`,
"orders.Order Summary": `Order Summary`,
"orders.Order Tag": `Order Tag`,
"orders.Order Type": `Order Type`,
"orders.Other": `Other`,
"orders.Out of stock": `Out of stock`,
"orders.Out of stock product item": `Out of stock product item`,
"orders.Outbound": `Outbound`,
"orders.Outbound Number": `Outbound Number`,
"orders.Outbound Qty": `Outbound Qty`,
"orders.Outbound Status": `Outbound Status`,
"orders.Outbound Warehouse": `Outbound Warehouse`,
"orders.Parcel Detail": `Parcel Detail`,
"orders.Partially Shipped": `Partially Shipped`,
"orders.Payment": `Payment`,
"orders.Payment Method": `Payment Method`,
"orders.Payment Process": `Payment Process`,
"orders.Payment Records": `Payment Records`,
"orders.Payment Time": `Payment Time`,
"orders.Pending Review": `Pending Review`,
"orders.Phone Number": `Phone Number`,
"orders.Please enter your remarks": `Please enter your remarks`,
"orders.Please input Order Number": `Please input Order Number`,
"orders.Post Code": `Post Code`,
"orders.Process": `Process`,
"orders.Product Amount": `Product Amount`,
"orders.Product Qty": `Product Qty`,
"orders.Products Name": `Products Name`,
"orders.Provider": `Provider`,
"orders.Reason for Cancellation": `Reason for Cancellation`,
"orders.Receiver": `Receiver`,
"orders.Receiver Name": `Receiver Name`,
"orders.Refund": `Refund`,
"orders.Refund Status": `Refund Status`,
"orders.Refund Time": `Refund Time`,
"orders.Related Order": `Related Order`,
"orders.Remark": `Remark`,
"orders.Requested Ship Date": `Requested Ship Date`,
"orders.Resolved Hold": `Resolved Hold`,
"orders.Resolved Hold Tip": `Are you sure to resolve hold this order?`,
"orders.Resourcing": `Resourcing`,
"orders.Result": `Result`,
"orders.Select Warehouse Sourcing Rule": `Select Warehouse Sourcing Rule`,
"orders.Seller": `Seller`,
"orders.Ship to": `Ship to`,
"orders.Shipment": `Shipment`,
"orders.Shipment Information": `Shipment Information`,
"orders.Shipment Number": `Shipment Number`,
"orders.Shipment Qty": `Shipment Qty`,
"orders.Shipped": `Shipped`,
"orders.Shipping Fee": `Shipping Fee`,
"orders.Shipping Fee Tip": `Shipping fee when product needs to be delivered to consumers.`,
"orders.Shipping Status": `Shipping Status`,
"orders.Shipping fee Discount": `Shipping fee Discount`,
"orders.Shipping fee Discount Tip": `Shipping fee discount`,
"orders.Sourcing Status": `Sourcing Status`,
"orders.Status": `Status`,
"orders.Success": `Success`,
"orders.Tag Preview": `Tag Preview`,
"orders.Text Content": `Text Content`,
"orders.To Ship": `To Ship`,
"orders.Total Amount": `Total Amount`,
"orders.Total Discount": `Total Discount`,
"orders.Total Order amount": `Total Order amount`,
"orders.Total Products Amount": `Total Products Amount`,
"orders.Total Tax": `Total Tax`,
"orders.Total Used Point": `Total Used Point`,
"orders.Tracking Detail": `Tracking Detail`,
"orders.UOM": `UOM`,
"orders.User": `User`,
"orders.View Product Detail": `View Product Detail`,
"orders.View Tracking Detail": `View Tracking Detail`,
"orders.apply hold Tip": `Are you sure to apply hold this order?`,
"orders.payChannelName": `PayChannelName`,
"org.address information": `Address Information`,
"org.buyer": `Buyer`,
"org.buyer_tip": `An organization is assigned the Buyer role when it purchases product from the Enterprise or other organizations set up as Sellers.`,
"org.carrier": `Carrier`,
"org.carrier_tip": `An organization is assigned the Carrier role when it provides delivery services between buyers sellers and customers.`,
"org.contacts name": `Contacts Name`,
"org.enterprice_tip": `An Enterprise brokers business. Each organization in an organizational structure must be either an Enterprise or designate an Enterprise as its primary Enterprise.`,
"org.enterprise": `Enterprise`,
"org.fixed telephone": `Fixed Telephone`,
"org.input organization name": `Input Organization Name`,
"org.mobile phone": `Mobile Phone`,
"org.node": `Node`,
"org.node_tip": `A Node represents a physical location (for example a manufacturing plant small stock room or warehouse). A node can also play the role of Buyer or Seller.`,
"org.open time": `open Time`,
"org.organization code": `Organization Code`,
"org.organization list": `Organization List`,
"org.organization logo": `Logo`,
"org.organization name": `Organization Name`,
"org.organization role": `Organization Role`,
"org.parent organization": `Parent Organization`,
"org.seller": `Seller`,
"org.seller_tip": `An organization is assigned the Seller role when it sells product to the Enterprise or other organizations set up as Buyers.`,
"pageTree.view": `View:`,
"partner.From to": `From to`,
"partner.Remove from partner list": `Remove from partner list`,
"payment.api_v3_key": `API_V3_KEY`,
"payment.appid": `App ID`,
"payment.certificate_number": `Certificate Number`,
"payment.merchant_account": `Merchant Account`,
"payment.private_key": `Private Key`,
"payment.provider": `Provider`,
"payment.public_key": `Public Key`,
"payment.status": `Status`,
"payment.supported_payment": `Payment Setting`,
"payment.supported_tip": `A payment method provided by a payment service provider approved by DTC Experience.`,
"payment.url": `URL`,
"peocessCanvas.Campaign Plan Overview": `Process Canvas`,
"petowner.Current Tier": `Current Tier`,
"petowner.Fisrt Order Date": `Fisrt Order Date`,
"petowner.Guest": `Guest`,
"petowner.Holding Points": `Holding Points`,
"petowner.Last Order Date": `Last Order Date`,
"petowner.Member": `Member`,
"petowner.Points": `Points`,
"petowner.Rewards History": `Rewards History`,
"petowner.Tier Change History": `Tier Change History`,
"petowner.Tier End Time": `Tier End Time`,
"petowner.Tier Start Time": `Tier Start Time`,
"petowner.Tier Start and End Time": `Points Valid Period`,
"petowner.address.address": `Address`,
"petowner.address.city": `City`,
"petowner.address.consumertype": `Consumer Type`,
"petowner.address.district": `District`,
"petowner.address.phone_number": `Phone Number`,
"petowner.address.postal_code": `Postal Code`,
"petowner.address.province": `Province`,
"petowner.address.receiver_name": `Receiver Name`,
"petowner.age": `Age`,
"petowner.basic_info": `Basic Information`,
"petowner.birth_date": `Birth Date`,
"petowner.breed": `Breed`,
"petowner.email": `Email`,
"petowner.follow_status": `Follow Status`,
"petowner.follow_status.followed": `Followed`,
"petowner.follow_time": `Followed Time`,
"petowner.gender": `Gender`,
"petowner.login_time": `Login Time`,
"petowner.loyaltyProgram": `Loyalty Program`,
"petowner.months": `months`,
"petowner.my_address": `My Address`,
"petowner.my_pet_owner": `Consumer List`,
"petowner.openid": `OpenId`,
"petowner.order_info": `Order Information`,
"petowner.pet_category": `Pet Category`,
"petowner.pet_info": `Pet Information`,
"petowner.pet_name": `Pet Name`,
"petowner.profile_photo": `Profile Photo`,
"petowner.smart_device": `Smart Device`,
"petowner.smart_device.locked_time": `Locked Time`,
"petowner.smart_device.name": `Smart Device Name`,
"petowner.smart_device.sku": `Smart Device SKU`,
"petowner.smart_device.subscription_no": `Subscription No.`,
"petowner.smart_device.subscription_time": `Subscription Time`,
"petowner.social_account": `Social Media Account`,
"petowner.sterillized": `Sterilized`,
"petowner.sterillized_status": `Sterillized Status`,
"petowner.subscription_info": `Subscription Information`,
"petowner.tagging": `Tagging`,
"petowner.tags": `Tagging`,
"petowner.unionid": `UnionId`,
"petowner.unsterillized": `Unsterilized`,
"petowner.user_type": `User Type`,
"petowner.voucher_info": `Voucher Information`,
"petowner.wechat_name": `Consumer Account`,
"please enter": `Please Enter`,
"please input SMS template content": `Please input SMS template content`,
"please input SMS template description": `Please input SMS template description`,
"please input SMS template name": `Please input SMS template name`,
"please input email template name": `Please input email template name`,
"please input right email": `please input right email`,
"please input right number": `please input right number`,
"please input right phone": `please input right phone`,
"please input scenario description": `Please input scenario description`,
"please input scenario link": `Please input scenario link`,
"please select": `Please Select`,
"please select Associated Store": `please select Associated Store`,
"please select Associated logical warehouse": `please select Associated logical warehouse`,
"please select Physical Warehouse": `please select Physical Warehouse`,
"please select push pushCategory": `Please select push category`,
"please select push pushChannel": `Please select push channel`,
"please select sendgrid template name": `Please select Sendgrid template`,
"please select signature": `Please select signature`,
"please select template type": `Please select template type`,
"points.Add ways to earn": `Add ways to earn`,
"points.Add ways to redeem": `Add ways to redeem`,
"points.Calendar Year": `Calendar Year`,
"points.Create Loyalty Program": `Create Loyalty Program`,
"points.Create Rewards": `Create Rewards`,
"points.Customized Loyalty Program": `Go & Experience Your Customized Loyalty Program!`,
"points.Earn Points": `Earn Points`,
"points.Earn Points Description": `Create ways your consumer can earn points when they join share and engage with your brand.`,
"points.Expired Points": `Expired Points`,
"points.Lifetime": `Lifetime`,
"points.Outstanding balance": `Outstanding balance`,
"points.Points": `Points`,
"points.Points Expiration Term": `Points Expiration Term`,
"points.Points Expiry": `Points Expiry`,
"points.Points Expiry Description": `Manage when consumers’ points expire. Expiring points is an effective way to re-engage customers who haven’t shopped with you in a while. There are three options available:`,
"points.Points Expiry Description1": `• Calendar Year: points expired at the end of the year.`,
"points.Points Expiry Description2": `• Rolling Year: points expired after 12-month period.`,
"points.Points Expiry Description3": `• Lifetime: points will not be expired.`,
"points.Points Summary": `Points Summary`,
"points.Points Summary Description": `Display points distribution and redemption summary.`,
"points.Points expiry is enabled": `Points expiry is enabled`,
"points.Redeem Points": `Redeem Points`,
"points.Redeem Points Description": `Create rewards your consumer can redeem with points they’ve earned.`,
"points.Redeemed Points": `Redeemed Points`,
"points.Rolling Year": `Rolling Year`,
"points.Set up Points Rules": `Set up Points Rules`,
"points.Set up Tiers": `Set up Tiers`,
"points.Start From Here": `Start From Here`,
"points.Total Distributed Points": `Total Distributed Points`,
"points.View and manage all points history": `View and manage all points history`,
"points.Ways to earn": `Ways to earn`,
"points.Ways to redeem": `Ways to redeem`,
"points.description": `There’s no loyalty program created yet! Please be aware that you need to create a loyalty program firstly and then set up points related rules. The expected creation flow is as below.`,
"points.step1": `STEP 1`,
"points.step2": `STEP 2`,
"points.step3": `STEP 3`,
"points.step4": `STEP 4`,
"points.step5": `STEP 5`,
"points.winning": `Winning loyalty reward points enthrall the consumers and motivate them to interact with your brand more often. The interactions thus further improve the revenue rate and show the increase in your consumer satisfaction level. You can check points distribution and redemption and configure your business-based points earning and redemption rule under this section.`,
"pointsSetting": `Points Setting`,
"pos.In stock": `In stock`,
"pos.Out of stock": `Out of stock`,
"pos.checkout.cash": `Cash`,
"pos.checkout.checkout": `Checkout`,
"pos.checkout.consent": `Consent`,
"pos.checkout.consent_rule": `This order is completed through our partners Intervino UK (AWRS XBAW00000104406). Please review and accept the  <a href=https://www.malts.com/en-gb/bsq-terms-of-sale-uk style={{ color: '#197bbd' }} target=\_black>Terms of Sale </a> for the purchase prior to continuing.`,
"pos.checkout.consent_rule_name": `Terms of Sale`,
"pos.checkout.consumer_information": `Consumer Information`,
"pos.checkout.discount": `Discount`,
"pos.checkout.email": `Email`,
"pos.checkout.items": `items`,
"pos.checkout.pay_now": `Pay Now`,
"pos.checkout.payment_method": `Payment Method`,
"pos.checkout.phone": `Phone`,
"pos.checkout.return_to_cart": `Return to cart`,
"pos.checkout.subtotal": `Subtotal`,
"pos.checkout.total": `Total`,
"pos.search_consumer_email": `Search Consumer Email`,
"pos.search_items": `Search items`,
"pos.shopping_cart": `Shopping Cart`,
"post.Meta Data": `Meta Data`,
"postAddTitlePlaceholder": `Please input post title`,
"posts.CategoriesButtonText": `Add Category`,
"posts.CategoriesDesc": `Create edit and manage post categories on your site.`,
"posts.CategoriesModalTitle": `Add New Category`,
"posts.CategoriesTitle": `Categories`,
"posts.CategoriesTitle2": `Categories`,
"posts.Category Name": `Category Name`,
"posts.Category Placeholder": `Please input`,
"posts.Category Slug": `Category Slug`,
"posts.Slug": `Slug`,
"posts.Slug Placeholder": `Please input`,
"posts.Tags Name": `Tags Name`,
"posts.Tags Placeholder": `Please input`,
"posts.Tags Slug": `Tags Slug`,
"posts.TagsButtonText": `Add Tags`,
"posts.TagsDesc": `Create edit and manage post tags on your site`,
"posts.TagsModalTitle": `Add New Tags`,
"posts.TagsTitle": `Tags`,
"posts.TagsTitle2": `Tags`,
"posts.allPosts": `All Posts`,
"posts.postsTitle": `Create edit and manage the posts on your site.`,
"potential legal risks": `potential legal risks`,
"preferences.Adroll": `Adroll`,
"preferences.Adroll pop title": `How to Obtain your Adroll ID?`,
"preferences.Analytics Integration": `Analytics & Retargeting Tools Integration`,
"preferences.Baidu Statistic ID": `Baidu Statistic`,
"preferences.Baidu Statistic pop title": `How to Obtain Baidu Statistic Script?`,
"preferences.Enable reCAPTCHA on contact": `Enable Google reCAPTCHA on contact`,
"preferences.Enable reCAPTCHA on login": `Enable Google reCAPTCHA on login and create account and password pages`,
"preferences.Facebook Pixel ID": `Facebook Pixel ID`,
"preferences.Facebook Pixel pop title": `How to Obtain Facebook Pixel ID?`,
"preferences.Featured Image": `Featured Image`,
"preferences.GTM ID": `GTM ID`,
"preferences.GTM pop title": `How to Obtain your Google Tag Manager (GTM) ID?`,
"preferences.Google Analytics ID": `Google Analytics ID`,
"preferences.Google Analytics pop title": `How to Obtain your Google Analytics ID?`,
"preferences.Image": `Image`,
"preferences.Preview": `Preview`,
"preferences.Recommended size": `(Recommended size: 1200 x 628 px)`,
"preferences.Social Sharing": `Social Sharing`,
"preferences.Social Sharing tip": `When you share a link to your store on social media an image is usually shown in your post.`,
"preferences.Spam Protection": `Spam Protection`,
"preferences.Spam Protection tip": `Protect your store from spam and abuse by enabling Google reCAPTCHA. This may require some customers to complete a reCAPTCHA task.`,
"privacy.Cookie Management": `Cookie Management`,
"privacy.Cookie Management.tip": `Cookie management setting allows you to integrate 3rd party cookies to display and manage cookie.`,
"privacy.Enable Cookie Banner": `Enable Cookie Banner`,
"privacy.Script": `Script`,
"privacy.title.tip": `Privacy settings provide content visibility and access control data collection and storage and cookie
  consent management. These settings enable you to establish privacy preferences and policies that align with
  your brands specific needs and legal requirements.`,
"product.Add New Ribbon": `Add New Ribbon`,
"product.Add New Top Search": `Add New Top Search`,
"product.Add a New Product": `Add a New Product`,
"product.Add add-on services": `Add add-on services`,
"product.Add-on Service": `Add-on Service`,
"product.Add-on Services": `Add-on Services`,
"product.Add-on Services info": `Add-on services are extra chargeable services applied based on product itself click below blue button to add your customized services to your products!`,
"product.Are you sure want to delete the following product ? Warning": `Are you sure to delete the following product ? `,
"product.Are you sure want to delete the following product ? Warning: You cannot undo this action!": `Are you sure to delete the following product?`,
"product.Are you sure want to delete the product(s) ? Warning": `Are you sure to delete the product(s) ? `,
"product.Are you sure want to delete the product(s) ? Warning: You cannot undo this action!": `Are you sure to delete the product(s) ?`,
"product.Audit": `Audit`,
"product.Bundle": `Bundle`,
"product.Change Date": `Change Date`,
"product.Choose Category": `Please select product category`,
"product.Choose Product Type": `Please select product type`,
"product.Delete Product": `Delete Product`,
"product.Delist": `Delist`,
"product.Delisted": `Delisted`,
"product.EAN": `EAN`,
"product.EANTip": `EAN:
  <p>EAN should be associated with SKUs and conform to coding rules</p>`,
"product.Edit History": `Edit History`,
"product.Edit Ribbon": `Edit Ribbon`,
"product.Edited By": `Edited By`,
"product.Feeding Days": `Feeding Days`,
"product.Functions": `Functions`,
"product.Gift Card": `Gift Card`,
"product.Hide": `Hide`,
"product.Input Product Name": `Please input product name`,
"product.Input SKU": `Input SKU`,
"product.Input SPU": `Input SPU`,
"product.Life Stage": `Life Stage`,
"product.List Price": `List Price`,
"product.Live": `Live`,
"product.Live/Dellist": `Live/Dellist`,
"product.Live/DellistTip": `Live/Delist:
   <p> Live: SKU on the shelf</p>
<p>Delist: SKU off the shelf</p>`,
"product.Modified Info": `Modified Info`,
"product.More": `More`,
"product.No category has been chosen": `No category has been chosen`,
"product.Not saleable": `Not saleable`,
"product.Please enter description name": `Please enter description name`,
"product.Please input stock": `Please input stock`,
"product.Preview": `Preview`,
"product.Priority": `Priority`,
"product.Product Audit": `Product Audit`,
"product.Product Type": `Product Type`,
"product.Products SKUs": `Products SKUs`,
"product.Publish": `Publish`,
"product.Regular": `Regular`,
"product.Related Content": `Related Content`,
"product.Related Products": `Related Products`,
"product.Related Products info": `Related products are recommendations in addition to an item your customer is currently viewing. Configured related products below will show up under product detail page.`,
"product.Relationship Type": `Relationship Type`,
"product.Ribbon color": `Ribbon color`,
"product.Ribbon font": `Ribbon font`,
"product.Ribbon name": `Ribbon name`,
"product.Saleable": `Saleable`,
"product.Sales Category": `Sales Category`,
"product.Save and Delist": `Save and Delist`,
"product.Save and Publish": `Save and Publish`,
"product.Select a option and change input text above": `Select a option and change input text above`,
"product.Shipping": `Shipping`,
"product.Size": `Size`,
"product.Sold out": `Sold out`,
"product.Sub-SKU": `Sub-SKU`,
"product.Sub-SKUTip": `Sub-SKU:<p>Sub-SKU should add the sub-products that the SKU needs to be bound and sold</p>`,
"product.Subscription Price": `Subscription Price`,
"product.SubscriptionTip": `Subscription:<p>Support subscription should configure Y</p>
  <p>Subscription not supported should be N</p>`,
"product.Summary": `Summary`,
"product.Support 100": `Support 100`,
"product.Technology": `Technology`,
"product.The currently selected": `The currently selected`,
"product.Tips": `Tips`,
"product.To Search Name": `To Search Name`,
"product.To Search Name input": `Please input Top Search Name`,
"product.Top Search Name": `Top Search Name`,
"product.Top Search is visible on shop": `Top Search is visible on shop`,
"product.Variation": `Variation`,
"product.Variations": `Variations`,
"product.addOption": `Add Option`,
"product.addVariation": `Add Variation`,
"product.add_category": `Add Category`,
"product.add_products": `Add Products`,
"product.add_subcategory": `Add subcategory`,
"product.adjust_seq": `Adjust Sequence`,
"product.attribute.Maximum characters": `Maximum characters`,
"product.attributeTip": `Product Attribute:
  <p>Product attributes are related to the product name and description</p>`,
"product.attribute_add_new_title": `Add a New Attribute`,
"product.attribute_basic_info": `Attribute Basic Info`,
"product.attribute_create_tip": `Please choose the desired type of created attribute`,
"product.attribute_display_name": `Display Name`,
"product.attribute_guidelines": `Attribute guidelines`,
"product.attribute_guidelines_title": `Attribute Guidelines`,
"product.attribute_label": `Attribute Label`,
"product.attribute_multi_select": `Multi SELECT`,
"product.attribute_no_options_tip": `Sorry there is no options!`,
"product.attribute_required": `Required`,
"product.attribute_single_select": `Single SELECT`,
"product.attribute_text": `Text`,
"product.attribute_type": `Type`,
"product.baseInfo": `Basic Infomation`,
"product.brand": `Brand`,
"product.breeds": `Breeds`,
"product.category": `Category`,
"product.category_basic_info": `Basic Information`,
"product.category_cannot_active": `This category cannot be activated as it contains no product`,
"product.category_delete_content_tip": `Deleted shop category can't be recovered.`,
"product.category_delete_title_tip": `Are you sure to delete this shop category?`,
"product.category_description": `Description`,
"product.category_displayName": `Display Name`,
"product.category_display_name": `Category Display Name`,
"product.category_filter": `Filter`,
"product.category_filter_display_name": `Display Name`,
"product.category_filter_status": `Filter Status`,
"product.category_image": `Category Image`,
"product.category_label": `Category Label`,
"product.category_name": `Please select product category`,
"product.category_seo_description": `Meta Description`,
"product.category_seo_keywords": `Meta Keywords`,
"product.category_seo_setting": `SEO Setting`,
"product.category_seo_slug": `Slug`,
"product.category_seo_title": `Meta Title`,
"product.category_type": `Category Type`,
"product.chooseCategoryTip": `Please choose the right category for your product`,
"product.create_by": `Created By`,
"product.description": `Product Description`,
"product.descriptionTip": `Product Description:
  Should like<br/>
  1. Brand<br/>
  2. Weight<br/>
  3. Benefits<br/>
  4. Feeding advice<br/>
  5. Products Recommended<br/>
  6. Product guarantee<br/>
  `,
"product.details": `Details`,
"product.display_on_off": `Display On/Off`,
"product.edit_filter_rule": `Edit Filtering Rules`,
"product.enter_category_name": `Enter a Category Name`,
"product.enter_display_name": `Enter a Category Display Name`,
"product.feedingDays": `Feeding Days:
<p>Feeding days should be filled reasonably according to the product specifications.</p>`,
"product.filter_results": `Filtering Results`,
"product.image": `Image`,
"product.listPriceTip": `List Price:
<p>List Price should include VAT and remain stable over time.</p>`,
"product.manual_selection": `Manual Selection`,
"product.manual_selection_tip": `Manually select the products you would like to include in your shop category`,
"product.market_price": `Marketing Price`,
"product.marketingPriceTip": `Marketing Price:
<p>Marketing Price should include VAT and remain stable over time.</p>`,
"product.miss_category_name": `Missing Category Name`,
"product.miss_display_name": `Missing Display Name`,
"product.my_shop_category": `My Shop Categories`,
"product.name": `Product Category Name`,
"product.opration": `Operation`,
"product.parcelSize": `Parcel Size`,
"product.parcelSizeTip": `Parcel Size:
  <p>Should input the parcel size not the product size so that the system can calculate the pick-up car accurately.</p>`,
"product.price": `Price`,
"product.price(s)": `Price(s)`,
"product.product": `Product`,
"product.productImage": `Product Image`,
"product.productImageTip1": `Every photos should have fine resolution - pixel doesn't appear breaking when zooming in`,
"product.productImageTip2": `Product image can add up to 9`,
"product.productNameRequired": `Product Name required`,
"product.productNameTip": `Product Name:<p>Briefly summarize the product</p>`,
"product.productRibbon": `Product Ribbon`,
"product.productTag": `Product Tag`,
"product.productThumbnail": `Product Thumbnail`,
"product.productTypeRequired": `Product Type required`,
"product.productVideo": `Product Video`,
"product.productVideoTip1": `1. Size: Max 30Mb resolution should not exceed 1280x1280px`,
"product.productVideoTip2": `2. Duration: 10s-60s`,
"product.productVideoTip3": `3. Format: MP4`,
"product.productVideoTip4": `4. Note: You can publish this listing while the video is being processed. Video <br /> will be shown in listing once successfully processed.`,
"product.product_category": `Product Category`,
"product.product_list": `Product List`,
"product.product_name": `Product Name`,
"product.product_s": `Products`,
"product.product_will_add_to_category": `If your products meet the filtering rule criteriathey will automatically be added into your shop category`,
"product.products": `Product(s)`,
"product.products_found": `{num} product(s) found`,
"product.products_selected": `{num} products selected`,
"product.rule_based_filter": `Rule-based Filtering`,
"product.rule_based_tip": `Products will be automatically selected based on the filters you have set up`,
"product.salesInfo": `Sales Infomation`,
"product.salesStatus": `Sales Status`,
"product.salesStatusTip": `Sales Status:
  <p>Products that need to be displayed and sold in the store should be set to Saleable</p>
  <p>Products that are not displayed and sold in the mall should be set to Not saleable</p>`,
"product.search_products": `Search Products`,
"product.select_opt_and_change": `Select an option and change input text above`,
"product.select_products": `Select Products`,
"product.set_filter_rule": `Set Filtering Rules`,
"product.skuName": `SKU Name`,
"product.skuNameTip": `SKU Name:
<p>SKU Name should be related to the variation option</p>`,
"product.skuTip": `SKU:<p>SKU should be unique and conform to coding rules</p>`,
"product.specification": `Specification`,
"product.specificationTip": `Fill in more attributes to boost the exposure of your product.`,
"product.spuTip": `SPU:<p>SPU should be unique and conform to coding rules.</p>`,
"product.stock": `Stock`,
"product.stockTip": `Stock:
  <p>Number of stocks should reflect the actual stock that is ready to ship.</p>
  <p>If out of stock please fill in 0 to avoid non-filfillment rate (NFR) or late shipment rate (LSR).</p>`,
"product.subscriptionPriceTip": `Subscription Price:
<p>Subscription price should include VAT and no more than the market price.</p>
<p>Subscription status is N and the subscription price cannot be entered.</p>`,
"product.support100Tip": `Support 100:
  <p>Product production date is set to Y within 100 days and set to N when not within 100 days</p>`,
"product.update": `Update`,
"product.variationImageTip": `Variation Image:
<p>Variation image should be clear and in line with the variation name</p>
<p>Image for each of the variations should also be in the same format</p>`,
"product.variationTip": `Can set up to two variations under which the first layer should be more important one`,
"product.weight": `Weight`,
"product.weightTip": `Product Weight:
  <p>Should input the weight when the product is ready to ship meaning product weight + package weight.</p>`,
"product.zone": `Zone`,
"productExportAlert": `The export report task is currently in progress and will soon be available in the Sales Channel/Export report.`,
"promotions.Actions": `Actions`,
"promotions.Add Gifts": `Add Gifts`,
"promotions.Add Products": `Add Products`,
"promotions.All": `All`,
"promotions.Applicable Products": `Applicable Products`,
"promotions.Automatic discount": `Automatic discount`,
"promotions.Basic Information": `Basic Information`,
"promotions.Buy X get Y": `Buy X get Y`,
"promotions.Consumers": `Consumers`,
"promotions.Create": `Create`,
"promotions.Create New Promotion": `Create New Promotion`,
"promotions.Date from": `Data from `,
"promotions.Discount Amount": `Discount Amount`,
"promotions.Discount Code": `Discount Code`,
"promotions.Discount Type": `Discount Type`,
"promotions.Discount Type & Amount": `Discount Type & Amount`,
"promotions.Expired": `Expired`,
"promotions.Fix Amount": `Fix Amount`,
"promotions.Free Shipping": `Free Shipping`,
"promotions.GSV": `GSV`,
"promotions.Gifted Products": `Gifted Products`,
"promotions.Key Metrics": `Key Metrics`,
"promotions.Manual Generation": `Manual Generation`,
"promotions.Minimum Basket price": `Minimum Basket price`,
"promotions.Ongoing": `Ongoing`,
"promotions.Order": `Order`,
"promotions.Order Discount": `Order Discount`,
"promotions.Order Type": `Order Type`,
"promotions.Please enter promotion type": `Please enter promotion type`,
"promotions.Product Discount": `Product Discount`,
"promotions.Promotion Description": `Promotion Description`,
"promotions.Promotion List": `Promotion List`,
"promotions.Promotion Method": `Promotion Method`,
"promotions.Promotion Name": `Promotion Name`,
"promotions.Promotion Title": `Promotion Title`,
"promotions.Promotion Type": `Promotion Type`,
"promotions.Promotion image": `Promotion image`,
"promotions.Recurrence": `Recurrence`,
"promotions.Rule Setting": `Rule Setting`,
"promotions.Status": `Status`,
"promotions.The recommended size for images is 100px *100px": `The recommended size for images is 100px *100px`,
"promotions.Total amount of all confirmed orders using promotion.": `Total amount of all confirmed orders using promotion.`,
"promotions.Total number of confirmed orders using promotion.": `promotions.Total number of confirmed orders using promotion.`,
"promotions.Total number of unique consumers who used promotion in confirmed orders.": `Total number of unique consumers who used promotion in confirmed orders.`,
"promotions.UTC+8": ` UTC+8`,
"promotions.Unlimited": `Unlimited`,
"promotions.Upcoming": `Upcoming`,
"promotions.Usage": `Usage`,
"promotions.Usage Limit": `Usage Limit`,
"promotions.Usage Quantity": `Usage Quantity`,
"promotions.Valid Period": `Valid Period`,
"promotions.actionFaild": `The operation is invalid. Please try again later`,
"promotions.image size should not exceed 1M.": `image size should not exceed 1M.`,
"promotions.max": `Need less than or equal stoke`,
"promotions.number": `Please enter number`,
"promotions.quantity": `Quantity`,
"promotions.to": ` to `,
"public.AND": `AND`,
"public.All": `All`,
"public.Are you sure you want to pause this subscription?": `Are you sure to pause this subscription?`,
"public.Are you sure you want to restart this subscription?": `Are you sure to restart this subscription?`,
"public.Close": `Close`,
"public.Condition": `Condition`,
"public.Detail": `Detail`,
"public.Disable": `Disable`,
"public.Edit": `Edit`,
"public.Export": `Export`,
"public.Failed": `Failed`,
"public.Field": `Field`,
"public.Hidedetails": `Hide Detail`,
"public.Import": `Import`,
"public.No data": `No data`,
"public.OR": `OR`,
"public.Operation": `Operation`,
"public.Operation Successful": `Operation Successful`,
"public.Operations": `Operations`,
"public.Options": `Options`,
"public.Please Input": `Please input`,
"public.Please Select": `Please Select`,
"public.Preview": `Preview`,
"public.SKU": `SKU`,
"public.SPU": `SPU`,
"public.Successed": `Successed`,
"public.Update": `Update`,
"public.Value": `Value`,
"public.action": `Action`,
"public.action_in_progress": `Action in progress`,
"public.actions": `Actions`,
"public.add": `Add`,
"public.approve": `Approve`,
"public.are_you_sure_delete": `Are you sure to delete the item ?`,
"public.are_you_sure_disable": `Are you sure to disable this item ?`,
"public.are_you_sure_enable": `Are you sure to enable this item ?`,
"public.audit": `Audit`,
"public.back": `Back`,
"public.cancel": `Cancel`,
"public.clear_all": `Clear All`,
"public.confirm": `Confirm`,
"public.confirm_delete": `Confirm Delete`,
"public.confirm_tip": `Are you sure to take this action?`,
"public.create": `Create`,
"public.date": `Date`,
"public.delete": `Delete`,
"public.deleteItemTip": `Are you sure to delete the item?`,
"public.delete_description": `Deleted item can’t be recovered.`,
"public.delete_item": `Delete Item`,
"public.details": `View Details`,
"public.disableItemTip": `Are you sure to disable the item ?`,
"public.disable_item": `Disable Item`,
"public.download": `Download`,
"public.edit": `Edit`,
"public.enable": `Enable`,
"public.enableItemTip": `Are you sure to enable the item?`,
"public.enable_item": `Enable Item`,
"public.field_required": `This field is required`,
"public.file_upload_failed": `file upload failed`,
"public.file_upload_only_jpg": `You can only upload JPG/PNG file!`,
"public.file_upload_smaller_than_2M": `Image must smaller than 2MB!`,
"public.file_upload_success": `file upload successfully`,
"public.input": `Input`,
"public.moreActions": `More Actions`,
"public.new": `New`,
"public.next": `Next`,
"public.next_step": `Next Step`,
"public.no": `No`,
"public.no.": `No.`,
"public.no_more_than_200": `No more than 200 characters`,
"public.notice": `Notice`,
"public.ok": `OK`,
"public.operate_fail": `Operation failure`,
"public.operate_success": `Operation success`,
"public.option": `Option`,
"public.pause": `Pause`,
"public.pleaseEnter": `Please input`,
"public.pleaseEnterAccordingToRules": `Please enter according to rules`,
"public.pleaseInput": `Please input`,
"public.pleaseSelect": `Please select`,
"public.preview": `preview`,
"public.previous_step": `Previous Step`,
"public.reject": `Reject`,
"public.reset": `Reset`,
"public.run": `Run`,
"public.save": `Save`,
"public.search": `Search`,
"public.select": `Select`,
"public.shop_sitemap_download": `Shop Sitemap Download`,
"public.status": `Status`,
"public.succeed": `Succeed!`,
"public.upload": `Upload`,
"public.yes": `Yes`,
"publishing and will be published within 10 minutes": `You page {arg1} is publishing and will be published within 10 minutes. You can access your live page at {arg2} later.`,
"qrCode.Add QR Code": `Add QR Code`,
"qrCode.Banner Name": `Banner Name`,
"qrCode.Click Type": `Click Type`,
"qrCode.Comment": `Comment`,
"qrCode.Default": `Default`,
"qrCode.Expired Time": `Expired Time`,
"qrCode.Mini Program": `Mini Program`,
"qrCode.Official Account": `Official Account`,
"qrCode.Path": `Path`,
"qrCode.Permanent integer parameter": `Permanent integer parameter`,
"qrCode.Permanent string parameter": `Permanent string parameter`,
"qrCode.Pic Location": `Pic Location`,
"qrCode.Please input QR Code Name!": `Please input QR Code Name`,
"qrCode.Please input Scenario ID!": `Please input Scenario ID`,
"qrCode.Please input Scenario STR and do not contain _": `Please input Scenario STR and do not contain _`,
"qrCode.Please select QR Code Type!": `Please select QR Code Type!`,
"qrCode.Please select Reply Content!": `Please select Reply Content!`,
"qrCode.QR Code Name": `QR Code Name`,
"qrCode.QR Code Type": `QR Code Type`,
"qrCode.Response Content": `Response Content`,
"qrCode.Scenario ID": `Scenario ID`,
"qrCode.Scenario STR": `Scenario STR`,
"qrCode.Sort": `Sort`,
"qrCode.Temporary integer parameter": `Temporary integer parameter`,
"qrCode.Temporary string parameter": `Temporary string parameter`,
"qrCode.Wechat Account": `Wechat Account`,
"quantity (item)": `quantity (item)`,
"quickstart.4 Key Capability Modules": `4 Key Capability Modules`,
"quickstart.4 Key Capability Modules.1": `Omni-channel Data Integration`,
"quickstart.4 Key Capability Modules.2": `Consumer Behavior Insight`,
"quickstart.4 Key Capability Modules.3": `Consumer Tag Management`,
"quickstart.4 Key Capability Modules.4": `Consumer Segment Management`,
"quickstart.Add New Product": `Add New Product`,
"quickstart.Attributes": `Attributes`,
"quickstart.Basic Setting": `Basic Setting`,
"quickstart.Bind a new inventory network": `Bind a new inventory network`,
"quickstart.Channel": `Channel`,
"quickstart.Consumer Experiences": `Consumer Experiences`,
"quickstart.Consumer Experiences.desc": `Consumer experience portal is to collect and unify first-party consumer data from multiple sources to build a singlecoherent complete view of each consumer and then segment consumers for other applications’ use.`,
"quickstart.Consumer Segment": `Consumer Segment`,
"quickstart.Consumer Segment.desc.1": `Segment list displays created segmentations and related segmentation details.`,
"quickstart.Consumer Segment.desc.2": `To add a new segmentation for your first`,
"quickstart.Consumer Segment.desc.3": `Export created segmentations for other applications’ uses.`,
"quickstart.Consumer Tags": `Consumer Tags`,
"quickstart.Consumer Tags.desc.1": `Tag list displays created tags and display related tag details.`,
"quickstart.Consumer Tags.desc.2": `Tag task displays updated rules of each created tags such as timing or manual.`,
"quickstart.Create New Channel Warehouse": `Create New Channel Warehouse`,
"quickstart.Create New Logical Warehouse": `Create New Logical Warehouse`,
"quickstart.Create New Physical Warehouse": `Create New Physical Warehouse`,
"quickstart.Create New Store": `Create New Store`,
"quickstart.Create New logistic": `Create New logistic`,
"quickstart.Data Management": `Data Management`,
"quickstart.Data Management.desc.1": `All meta data information normalization and configuration to ensure the accuracy of data ingestion.`,
"quickstart.Data Management.desc.2": `Break the data island and ingest omni-channel data across all platforms and channels.`,
"quickstart.Data Management.desc.3": `Different IDs mapping of consumers in different channels to generate base ID in consumer experience which provides support for refined marketing operation.`,
"quickstart.Insights": `Insights`,
"quickstart.Insights.desc": `Consumer insight help fully understand each unified consumers and deeply insight into each consumer behavior analyze and optimize consumer journey identify and reduce loss risk.`,
"quickstart.Inventory Network Configuration": `Inventory Network Configuration`,
"quickstart.Learn more": `Learn more`,
"quickstart.Logical": `Logical`,
"quickstart.Logistic Configuration": `Logistic Configuration`,
"quickstart.Maintenance cost price": `Maintenance cost price`,
"quickstart.Maintenance marketing price": `Maintenance marketing price`,
"quickstart.New Price Aging": `New Price Aging`,
"quickstart.Order": `Order`,
"quickstart.Physical": `Physical`,
"quickstart.Price": `Price`,
"quickstart.Product": `Product`,
"quickstart.Product Setting": `Product Setting`,
"quickstart.Products Capability": `Products Capability`,
"quickstart.Store Configuration": `Store Configuration`,
"quickstart.Warehouse": `Warehouse`,
"quickstart.Warehouse Configuration": `Warehouse Configuration`,
"referralAFriend": `Referral a Friend`,
"referrals.Add reward": `Add reward`,
"referrals.Minimum order amount": `Minimum order amount`,
"referrals.Place an order": `Place an order`,
"referrals.Referral": `Referral`,
"referrals.Referral reward": `Referral reward`,
"referrals.Referral rewards": `Referral rewards`,
"referrals.Referral rewards description1": `Rewards your existing consumers for referring their friends and encouraging them to try out your brand.`,
"referrals.Referral rewards description2": `The existing consumer gets their rewards once the friend makes its first order.`,
"referrals.Referral setting": `Referral setting`,
"referrals.Referral setting description": `Referral setting determines how your referring consumer and referred friend to get rewards and other related settings.`,
"referrals.Referred friend reward": `Referred friend reward`,
"referrals.Referred friend reward emptyText": `Add a reward for referred friends.`,
"referrals.Referring customer reward": `Referring consumer reward`,
"referrals.Referring customer reward emptyText": `Add a reward for consumers who refer their friends.`,
"referrals.Registration": `Registration`,
"referrals.Reward distribution threshold": `Reward distribution threshold:`,
"referrals.Reward distribution threshold:": `Reward distribution threshold:`,
"referrals.delete description": `Deleted rewards can’t be recovered.`,
"referrals.delete title": `Are you sure to delete this reward?`,
"reload": `Reload`,
"remaining": `remaining`,
"reply.Add New Reply Content": `Add New Reply Content`,
"reply.Are you sure you want to": `Are you sure you want to`,
"reply.Asset": `Asset`,
"reply.Assets Title": `Assets Title`,
"reply.Confirm Delete?": `Confirm Delete?`,
"reply.Content Description": `Content Description`,
"reply.Content description": `Content description`,
"reply.Create Time": `Create Time`,
"reply.Edit Reply Content": `Edit Reply Content`,
"reply.Graphic main cover": `Graphic main cover`,
"reply.Message content": `Message content`,
"reply.Picture": `Picture`,
"reply.Please input description!": `Please input description`,
"reply.Please select Asset!": `Please select Asset!`,
"reply.Please select reply type!": `Please select reply type!`,
"reply.Reply Type": `Reply Type`,
"reply.Select Assets": `Select Assets`,
"reply.Title": `Title`,
"reply.Video": `Video`,
"reply.Voice": `Voice`,
"reply.Wechat Assets ID": `Wechat Assets ID`,
"reply.graphicMessage": `Graphic message`,
"reply.pictureMessage": `Picture message`,
"reply.textMessage": `Text message`,
"reply.this item?": `this item?`,
"reply.videoMessage": `Video message`,
"reply.voiceMessage": `Voice message`,
"reportEmbed": `reportEmbed`,
"resetPassword.phoneNumber": `Phone number`,
"resetPassword.resetPassword": `Reset Password`,
"return-order": `Return Order`,
"returnOrder.description": `Adding and modifying the entire process for different statuses of return order`,
"rewardHistory.action": `Action`,
"rewardHistory.add": `Add`,
"rewardHistory.adjust_points": `Adjust Points`,
"rewardHistory.adjustment_reason": `Adjustment reason`,
"rewardHistory.adjustment_type": `Adjustment type`,
"rewardHistory.consumer_email": `Consumer Email`,
"rewardHistory.consumer_name": `Consumer Name`,
"rewardHistory.date": `Date`,
"rewardHistory.minus": `minus`,
"rewardHistory.points": `Points`,
"rewardHistory.points_value": `Points value`,
"rewardHistory.related_transaction": `Related transaction`,
"rewardSettings": `Reward Settings`,
"rewardSettings.add_new_reward": `Add New Rewards`,
"rewardSettings.add_perk": `Add New Perk`,
"rewardSettings.add_product": `Add Product`,
"rewardSettings.add_reward": `Add Reward`,
"rewardSettings.amount": `Amount`,
"rewardSettings.choose_a_product": `Choose a product`,
"rewardSettings.create_perk": `Create Perk`,
"rewardSettings.create_perk_description": `Perk description`,
"rewardSettings.create_perk_icon": `Perk icon`,
"rewardSettings.create_perk_name": `Perk name`,
"rewardSettings.create_perk_status": `Status`,
"rewardSettings.create_perk_status_active": `Active`,
"rewardSettings.create_perk_status_inactive": `Inactive`,
"rewardSettings.description": `Description`,
"rewardSettings.display_name": `Display name`,
"rewardSettings.edit_perk": `Edit Perk`,
"rewardSettings.free_product": `Free product`,
"rewardSettings.free_product_redeem_times": `Free product redeem times`,
"rewardSettings.free_shipping": `Free shipping`,
"rewardSettings.general": `General`,
"rewardSettings.info1": `1. Member tiers relate to member benefits. Members can enjoy the benefits corresponding to the tier.`,
"rewardSettings.info2": `2. Referral rewards are associated with benefits.`,
"rewardSettings.order_discount": `Order Discount`,
"rewardSettings.perk_name": `Perks name`,
"rewardSettings.perks": `Perks`,
"rewardSettings.points": `Points`,
"rewardSettings.points_value": `Points value`,
"rewardSettings.product_name": `Product Name`,
"rewardSettings.product_price": `Product Price`,
"rewardSettings.product_stock": `Product Stock`,
"rewardSettings.reward_history_tittle": `Reward history title`,
"rewardSettings.reward_icon": `Reward icon`,
"rewardSettings.reward_name": `Reward name`,
"rewardSettings.reward_number": `Reward Number`,
"rewardSettings.reward_value": `Reward Value`,
"rewardSettings.rewards": `Rewards`,
"rewardSettings.set_shipping_amount": `Set a minimum shipping amount this reward`,
"ribbon.delete confirm description": `Deleted ribbons can’t be recovered`,
"ribbon.delete confirm text": `Do you confirm to delete this ribbon?`,
"route.Content Experience": `Content Experience`,
"route.Sales Experience": `Sales Experience`,
"sales-order": `Sales Order`,
"salesOrder.description": `Adding and modifying the entire process for different statuses of sales order`,
"sales_channel.pos": `POS`,
"sales_channel.pos.direct_checkout": `Direct Checkout`,
"sales_channel.pos.language": `Language`,
"search.Add synonyms": `Add synonyms`,
"search.Edit synonyms": `Edit synonyms`,
"search.Missing phrase": `Missing phrase`,
"search.Please input Synonyms or delete this field": `Please input Synonyms or delete this field.`,
"search.Search phrase(When search for)": `Search phrase(When search for)`,
"search.Synonyms (also search for)": `Synonyms (also search for)`,
"search.Synonyms Are Repeated": `Synonyms Are Repeated`,
"searchPageNamePlaceholder": `Please input page name`,
"segment.Add condition": `Add Condition`,
"segment.Add condition group": `Add Condition Group`,
"segment.Create New Segments": `Add New Segments`,
"segment.Crowd Estimation": `Crowd Estimation`,
"segment.Crowd Number": `Crowd Number`,
"segment.Events": `Segments`,
"segment.Last Update Time": `Last Update Time`,
"segment.Please Input Segment Label": `Please Input Segment Label`,
"segment.Segment Basic Information": `Segment Basic Information`,
"segment.Segment Description": `Segment Description`,
"segment.Segment Label": `Segment Label`,
"segment.Segment Rules": `Segment Rules`,
"segment.Segments Detail": `Segments Detail`,
"segment.Segments List": `Segments List`,
"segment.Segments List.desc": `Segments are consumers groups with common attributes actions or behaviors. Go and define segments based on your business needs!`,
"segment.Update Type": `Update Type`,
"segment.Update Type.desc": `The segment is updated on a daily basis`,
"segment_update": `Segments {arg1} has been updated successfully.`,
"select City": `select City`,
"select Country": `select Country`,
"select Currency format": `select Currency format`,
"select Currency symbol": `select Currency symbol`,
"select Date format": `select Date format`,
"select Default Weight": `select Default Weight`,
"select Digit grouping": `select Digit grouping`,
"select Language": `select Language`,
"select No of digits after decimal": `select No of digits after decimal`,
"select No. of digits after decimal": `select No. of digits after decimal`,
"select Region": `select Region`,
"select Time Zone": `select Time Zone`,
"select Time format": `select Time format`,
"select Unit System": `select Unit System`,
"select User Type": `select User Type`,
"select your Signed Product Category": `select your Signed Product Category`,
"selected": `Selected `,
"setting-Org": `Org`,
"setting.General Setting": `General Setting`,
"setting.back": `Back`,
"setting.returnOrderNumber.tips": `Return order number format has been changed successfully.`,
"setting.shippingMethod": `Shipping method`,
"setting.shippingMethod.add": `Add`,
"setting.shippingMethod.addShippingMethod": `Add Shipping Template`,
"setting.shippingMethod.shippingMethod": `Shipping Method`,
"setting.shippingMethod.shippingMethods": `Shipping Methods`,
"setting.shippingMethod.status": `Status`,
"setting.shippingMethod.transitionMethod": `Transition Method`,
"setting.shippingMethod.transitionMethods": `Transition Methods`,
"shipping-method-name": `Name`,
"sitemap.description": `A sitemap is a structured list of all the pages and content of your shop designed to assist search engines and users in navigating and understanding the website's layout and hierarchy. Easily access and download your website's structured content list to optimize SEO, streamline content management, and enhance data analysis.`,
"sitemap.task_create_success": `Sitemap is downloading now. Please wait and you can download it late under Export section.
`,
"smartOperation.Activity Log": `Activity Log`,
"smartOperation.Actual Executed Time": `Actual Executed Time`,
"smartOperation.Actual Population": `Actual Population`,
"smartOperation.Add events": `Add events`,
"smartOperation.Audit Users Population": `Audit Users Population`,
"smartOperation.Audit Users are selected by segments": `Audit Users are selected by segments`,
"smartOperation.Basic Task Information": `Basic Task Information`,
"smartOperation.Behaviour": `Behaviour`,
"smartOperation.Conditions": `Conditions`,
"smartOperation.Create Task": `Create Task`,
"smartOperation.Detail Task": `Task Detail`,
"smartOperation.Email": `Email`,
"smartOperation.Goal": `Goal`,
"smartOperation.Goal Achieved Population": `Goal Achieved Population`,
"smartOperation.One Time": `One Time`,
"smartOperation.Operation": `Operation`,
"smartOperation.Operation Log": `Operation Log`,
"smartOperation.Planed Executed Time": `Planned Executed Time`,
"smartOperation.Planned Executed Time": `Planned Executed Time`,
"smartOperation.Population": `Population`,
"smartOperation.Process Step Name": `Process Step Name`,
"smartOperation.Push Channel": `Push Channel`,
"smartOperation.Push Type": `Push Type`,
"smartOperation.Repeated Time": `Repeated Time`,
"smartOperation.SMS": `SMS`,
"smartOperation.Save as Draft": `Save as Draft`,
"smartOperation.Start and End Time": `Start and End Time`,
"smartOperation.Target Population": `Target Population`,
"smartOperation.Target Users": `Target Users`,
"smartOperation.Target Users Population": `Target Users Population`,
"smartOperation.Target Users are selected by segments": `Target Users are selected by segments`,
"smartOperation.Task Description": `Task Description`,
"smartOperation.Task Label": `Task Label`,
"smartOperation.Task Step Name": `Task Step Name`,
"smartOperation.Task Type": `Task Type`,
"smartOperation.Template Name": `Template Name`,
"smartOperation.The Task will be executed once": `The Task will be executed once`,
"smartOperation.Trigger Events": `Trigger Events`,
"smartOperation.Triggering Time": `Triggering Time`,
"smartOperation.Update Result": `Update Result`,
"smartOperation.Update Task": `Edit Task`,
"smartOperation.audit.tips": `Note: Before confirming approval or rejection, please ensure that approval users have received this campaign information successfully and completed the testing`,
"smartOperation.please enter number": `Please enter number`,
"smartOperation.publish.tips": `Are you sure you want to publish this campaign: {arg1}?`,
"smartOperation.publish.tips.desc": `Once published, audit users will immediately receive corresponding notification as a reference for auditing.
`,
"smartOperation.update": `Update`,
"sourcingRule": `sourcing rule`,
"startTime": `Start time`,
"store Basic information info": `Maintain and manage the basic profile of the store mainly including store name address signed Product category etc`,
"subscription": `Subscriptions`,
"subscription.100": `100`,
"subscription.Actions": `Actions`,
"subscription.Age": `Age`,
"subscription.Benefit Type": `Benefit Type`,
"subscription.Breed": `Breed`,
"subscription.Carrier information": `Carrier information`,
"subscription.Completed": `Completed`,
"subscription.Consumer Name": `Consumer Name`,
"subscription.Consumer information": `Consumer information`,
"subscription.Frequency": `Frequency`,
"subscription.Freshness": `Freshness`,
"subscription.Inactive": `Cancel`,
"subscription.Ongoing": `Ongoing`,
"subscription.Order ID": `Order Number`,
"subscription.Order status": `Order status`,
"subscription.PauBenefit Namese": `Benefit Name`,
"subscription.Pause": `Pause`,
"subscription.Pause Subscription": `Pause Subscription`,
"subscription.Paused": `Paused`,
"subscription.Pet Detail": `Pet Detail`,
"subscription.Phone Number": `Phone Number`,
"subscription.Product": `Product`,
"subscription.Product Name": `Product Name`,
"subscription.Product(s)": `Product(s)`,
"subscription.Quantity": `Quantity`,
"subscription.Restart": `Restart`,
"subscription.Restart Subscription": `Restart Subscription`,
"subscription.SEQ": `SEQ`,
"subscription.Shipment date": `Shipment date`,
"subscription.Subscription Benefits": `Subscription Benefits`,
"subscription.Subscription Creation Date": `Subscription Creation Date`,
"subscription.Subscription Frequency": `Subscription Frequency`,
"subscription.Subscription ID": `Subscription Number`,
"subscription.Subscription Product": `Subscription Product`,
"subscription.Subscription Status": `Subscription Status`,
"subscription.Subscription Time": `Subscription Time`,
"subscription.Subscription Type": `Subscription Type`,
"subscription.Subscription information": `Subscription information`,
"subscription.Subtotal": `Subtotal`,
"subscription.To start": `To start`,
"subscription.Unit price": `Unit price`,
"subscription.Variation": `Variation`,
"subscription.View Order Detail": `View Order Detail`,
"subscription.addresses": `Addresses`,
"subscription.auto_ship": `Autoship`,
"subscription.half_year": `Half year`,
"subscription.info": `Subscription Information`,
"subscription.log.create_comment": `Add comment`,
"subscription.log.delete_comment": `Delete comment`,
"subscription.log.modify_address": `Modify delivery address`,
"subscription.log.modify_comment": `Modify comment`,
"subscription.log.modify_ship_date": `Modify shipment date`,
"subscription.normal": `Normal`,
"subscription.quarter": `Quarter`,
"subscription.setting.Customize subscription number format and this change will apply to future subscriptions": `Customize subscription number format and this change will apply to future subscriptions`,
"subscription.setting.Frequency Unit": `Frequency Unit`,
"subscription.setting.Frequency name": `Frequency name`,
"subscription.setting.Frequency value": `Frequency value`,
"subscription.setting.Status": `Status`,
"subscription.setting.Subscription Number Format": `Subscription Number Format`,
"subscription.shipping address": `shipping address`,
"subscription.sku id": `SKU ID`,
"subscription.status.completed": `Subscription was completed`,
"subscription.status.created": `Subscription was created`,
"subscription.status.pause": `Subscription was paused`,
"subscription.status.refill": `The refill order is created successfully.`,
"subscription.status.resume": `Subscription was restarted`,
"subscription.year": `Year`,
"switchLanguageTip": `You haven't created this page under chosen language beforeand we'll save modified pages under default
  languages and create new page of desired language for you.`,
"tag.Actions": `Actions`,
"tag.Add New Pet Owner": `Add New Consumer`,
"tag.Add New Tag": `Add New Tag`,
"tag.Display in Shop": `Display in Shop`,
"tag.Edit tag": `Edit tag`,
"tag.Enter a tagging name": `Enter a tagging name`,
"tag.Missing Display Name": `Missing Display Name`,
"tag.Options": `Options`,
"tag.Pet Owner List": `Consumer List`,
"tag.Pet Owner(s)": `Consumer(s)`,
"tag.Phone Number": `Phone Number`,
"tag.Price": `Price`,
"tag.Profile Photo": `Profile Photo`,
"tag.Select Pet Owner": `Select Consumer`,
"tag.Stock": `Stock`,
"tag.Tagging Name": `Tagging Name`,
"tag.Tagging Name:": `Tagging Name:`,
"tag.Tagging Status": `Tagging Status`,
"tag.WeChat Name": `WeChat Name`,
"tag.delete confirm description": `Deleted tags can’t be recovered`,
"tag.delete confirm text": `Do you confirm to delete this tag?`,
"tag.tagName": `Tag name`,
"tag.tagNameTip": `Enter tagging label`,
"tagList.Input Tag Description": `Please input tag description`,
"tagList.Please Input Tag Name": `Please input tag name`,
"tagList.Please Input segment Name": `Please input segment name`,
"tagList.Population": `Population`,
"tagList.Tag Description": `Tag Description`,
"tagList.Tag Detail": `Tag Detail`,
"tagList.Tag History": `Tag History`,
"tagList.Tag ID": `Tag ID`,
"tagList.Update time": `Update time`,
"tagsList.Tags Management": `Tags Management`,
"taskDetail.basicInfoJobId": `Job ID`,
"taskDetail.basicInfoJobStatus": `Job Status`,
"taskDetail.basicInfoReferenceLabel": `Reference Label`,
"taskDetail.basicInfoTaskId": `Task ID`,
"taskDetail.basicInfoTaskStatus": `Task Status`,
"taskDetail.basicInfoUpdateType": `Update Type`,
"taskDetail.basicInfoUsageType": `Usage Type`,
"taskDetail.job.title": `Job Basic Information`,
"taskDetail.operationLog": `Task Operation Log`,
"taskDetail.title": `Task Basic Information`,
"taskDetail.updateResult": `Update Result`,
"taskList.All": `All`,
"taskList.Data Type": `Data Type`,
"taskList.GeneralEventsTitleDescription": `Task list displays all tasks expedited regularly to update consumer tags and segments.`,
"taskList.Segment": `Segment`,
"taskList.Select Data Type": `Select Data Type`,
"taskList.Tag": `Tag`,
"taskList.Tag Description": `Tag Description`,
"taskList.Usage": `Usage`,
"taskList.generalEventsTitle": `Task List`,
"taskList.job.GeneralEventsTitleDescription": `Job list displays all tasks expedited regularly to update consumer tags and segments.`,
"taskList.job.Segment": `Segment Jobs`,
"taskList.job.Tag": `Tag Jobs`,
"taskList.job.generalEventsTitle": `Job List`,
"taskList.job.status": `Job Status`,
"taskList.job.totalEvents": `Jobs`,
"taskList.lastExcutionTime": `Next Execution Time`,
"taskList.lastUpdateTime": `Last Updated time`,
"taskList.options": `Options`,
"taskList.population": `Population`,
"taskList.referenceLabel": `Reference Label`,
"taskList.status": `Task Status`,
"taskList.totalEvents": `Tasks`,
"taskList.updateType": `Update Type`,
"taskList.usageType": `Usage Type`,
"taskListExecutionTime": `Options`,
"tax.Calculation url": `Calculation url`,
"tax.Company code": `Company code`,
"tax.Edit tax setting": `Edit tax setting`,
"tax.Password": `Password`,
"tax.Tax code": `Tax code`,
"tax.User name": `User name`,
"templateMessage.Add": `Add`,
"templateMessage.Add New Template": `Add New Template`,
"templateMessage.Cancel Reminder": `Cancel Reminder`,
"templateMessage.Description": `Description`,
"templateMessage.Graphical Representation": `Graphical Representation`,
"templateMessage.H5 Jump Path": `H5 Jump Path`,
"templateMessage.Industry": `Industry`,
"templateMessage.Jump Path": `Jump Path`,
"templateMessage.List Representation": `List Representation`,
"templateMessage.Mini Program AppId": `Mini Program AppId`,
"templateMessage.Modify": `Modify`,
"templateMessage.Please input Description": `Please input description`,
"templateMessage.Please input H5 jump path": `Please input H5 jump path`,
"templateMessage.Please input Mini Program Jump Path": `Please input Mini Program Jump Path`,
"templateMessage.Please input Mini Program appid": `Please input Mini Program appid`,
"templateMessage.Please input Primary Industry": `Please input Primary Industry`,
"templateMessage.Please input Secondary Industry": `Please input Secondary Industry`,
"templateMessage.Please input Template ID": `Please input template ID`,
"templateMessage.Please input Template Name": `Please input Template Name`,
"templateMessage.Primary Industry": `Primary Industry`,
"templateMessage.Scenario": `Scenario`,
"templateMessage.Secondary Industry": `Secondary Industry`,
"templateMessage.Select Products": `Select Products`,
"templateMessage.Select Scenario": `Select Scenario`,
"templateMessage.Select Template": `Select Template`,
"templateMessage.Shipped": `Shipped`,
"templateMessage.Status": `Status`,
"templateMessage.Synchronous": `Synchronous`,
"templateMessage.Template ID": `Template ID`,
"templateMessage.Template ID:": `Template ID:`,
"templateMessage.Template Name": `Template Name`,
"templateMessage.Template Name:": `Template Name:`,
"templateMessage.Template Title": `Template Title`,
"templateMessage.Title": `Title`,
"templateMessage.View Industries": `View Industries`,
"templateMessage.View Industry": `View Industry`,
"test": `test`,
"test11": `test`,
"th": ` Th`,
"three": ``,
"tierDowngrade": `Tier downgrade`,
"tierManagement": `Tier Management`,
"tierTerm": `Tier Term`,
"tierTerm.addRewards": `Add Rewards`,
"tierTerm.amountSpent": `Amount Spent`,
"tierTerm.baseTier": `Base Tier`,
"tierTerm.description": `Description`,
"tierTerm.earnedHistoryTitle": `Earned History Title`,
"tierTerm.entryRetention": `Entry Retention`,
"tierTerm.entryRetentionCheckText": `Entry retention is different compared to entry threshold.`,
"tierTerm.entryRewards": `Entry Rewards`,
"tierTerm.entryRewardsTip": `Add rewards that will be unlocked once a consumer enter this tier. These are only awarded once per consumer.`,
"tierTerm.entryThreshold": `Entry Threshold`,
"tierTerm.from": `From`,
"tierTerm.general": `General`,
"tierTerm.name": `Name`,
"tierTerm.previousLowerTier": `Previous Lower Tier`,
"tierTerm.radio1": `Calendar Year – Tier eligibility begins on Jan 1 and ends on Dec 31.`,
"tierTerm.radio2": `Rolling Year – A dynamic 12 months period starting when a consumer reaches a certain tier.`,
"tierTerm.radio3": `Lifetime – Consumer will always maintain their tire status and cannot be downgraded.`,
"tierTerm.rewards": `Rewards`,
"tierTerm.rewardsEmpty": `No rewards are added yet.`,
"tierTerm.spent": `Spent`,
"tierTerm.startDate": `Eligibility period start date`,
"tierTerm.tierIcon": `Tier icon`,
"tierTerm.to": `To`,
"time back to you": `time back to you`,
"translation.Add new key": `Add new key`,
"translation.Auto-translate": `Auto-translate`,
"translation.DTC Platform": `DTC Platform`,
"translation.DTC Platform desc": `translation of all the keys used within DTC Platform`,
"translation.Search Key": `Search`,
"translation.Store Front": `Store Front`,
"translation.Store Front desc": `translation of all the keys used within store front`,
"translation.Translated": `Translated`,
"translation.Untranslated": `Untranslated`,
"translation.desc": `Streamline your translation process with our high-quality hassle-free service. Easily manage commonly used keys in multiple languages and take advantage of unlimited machine translation for more efficient post-editing workflows.`,
"translation.key name": `key name`,
"translation.title": `Boost Translation Efficiency`,
"type is required": `type is required`,
"uom.delete uom": `Are you sure to delete the item ?`,
"update successfully": `update successfully`,
"user segments": `user segments`,
"user.subTile": `Platform User Management`,
"valetOrder.openWindow.description": `Please process to checkout in new window.if in the new window fails to open, click <span style="color:rgb(238, 77, 45);cursor:pointer">here</span>`,
"valetOrder.successfully placed for user": `The order is successfully placed for {name}`,
"volume (m³)": `volume (m³)`,
"voucher Details": `voucher Details`,
"voucher.Actions": `Actions`,
"voucher.Activities": `Activities`,
"voucher.Activity name": `Activity name`,
"voucher.Add Products": `Add Products`,
"voucher.All Category": `All Category`,
"voucher.Applicable Products": `Applicable Products`,
"voucher.Automatic Offer": `Automatic Offer`,
"voucher.Basic Information": `Basic Information`,
"voucher.Brand": `Brand`,
"voucher.By Percentage": `By Percentage`,
"voucher.Discount Type | Amount": `Discount Type | Amount`,
"voucher.Fix Amount": `Fix Amount`,
"voucher.GSV": `GSV`,
"voucher.GSVTip": `Total amount of all confirmed orders using vouchers.`,
"voucher.Generate Codes": `Generate Codes`,
"voucher.Image must smaller than 1M!": `Image must smaller than 1M!`,
"voucher.Image size should not exceed 1M.": `Image size should not exceed 1M.`,
"voucher.Issuance time": `Issuance time`,
"voucher.Minimum Basket Price": `Minimum Basket Price`,
"voucher.Note: One consumer can only use once.": `Note: One consumer can only use once.`,
"voucher.Number of codes": `Number of codes`,
"voucher.Orders": `Orders`,
"voucher.Picture upload failed!": `voucher Picture upload failed!`,
"voucher.Please enter a value between 1 and 99": `Please enter a value between 1 and 99`,
"voucher.Price(s)": `Price(s)`,
"voucher.Product Category": `Product Category`,
"voucher.Product Category:": `Product Category:`,
"voucher.Product Voucher": `Product Voucher`,
"voucher.Product(s) Selected": `Product(s) Selected`,
"voucher.Products": `Products`,
"voucher.Quantity": `Quantity`,
"voucher.Recurrence": `Recurrence`,
"voucher.Rule Settings": `Rule Settings`,
"voucher.SKU": `SKU`,
"voucher.SPU": `SPU`,
"voucher.Select a option and change input text above": `Select a option and change input text above`,
"voucher.Shop Voucher": `Shop Voucher`,
"voucher.Status": `Status`,
"voucher.Stock": `Stock`,
"voucher.The price cannot be less than the voucher discount amount.": `The price cannot be less than the voucher discount amount.`,
"voucher.The recommended size for images is 100px * 100px.": `The recommended size for images is 100px * 100px.`,
"voucher.Total number of coupon codes": `Total number of coupon codes`,
"voucher.Total usable voucher for all consumers": `Total usable voucher for all consumers`,
"voucher.Unlimited": `Unlimited`,
"voucher.Usage Quantity": `Usage Quantity`,
"voucher.Valid Period": `Valid Period`,
"voucher.Voucher Code": `Voucher Prefix`,
"voucher.Voucher Description": `Voucher Description`,
"voucher.Voucher Image": `Voucher Image`,
"voucher.Voucher Name": `Voucher Name`,
"voucher.Voucher Type": `Voucher Type`,
"voucher.Voucher Value": `Voucher Value`,
"voucher.end": `End`,
"voucher.keyMetrics": `Key Metrics`,
"voucher.list": `Vouchers List`,
"voucher.list.Are you sure you want to": `Are you sure you want to`,
"voucher.list.Create": `Create`,
"voucher.list.Data from": `Data from`,
"voucher.list.Expired": `Expired`,
"voucher.list.Ongoing": `Ongoing`,
"voucher.list.Status": `Status`,
"voucher.list.UTC+8": `UTC+8`,
"voucher.list.Upcoming": `Upcoming`,
"voucher.list.Valid Period": `Valid Period`,
"voucher.list.listDescription": `Create and manage your own vouchers for your shop and products on DTC Experience!`,
"voucher.list.the item?": `the item?`,
"voucher.list.to": `to`,
"voucher.list.usageLimit": `Usage Limit`,
"voucher.order": `Order`,
"voucher.orderTip": `Total number of confirmed orders using vouchers.`,
"voucher.orders": `orders`,
"voucher.orders.All": `All`,
"voucher.orders.All Products": `All Products`,
"voucher.orders.Applicable Products": `Applicable Products`,
"voucher.orders.BasicInformation": `Basic Information`,
"voucher.orders.Claimed": `Claimed`,
"voucher.orders.Collapse Voucher Information": `Collapse Voucher Information`,
"voucher.orders.DiscountAmount": `Discount Amount`,
"voucher.orders.MinimumBasketPrice": `Minimum Basket Price`,
"voucher.orders.More Voucher Information": `More Voucher Information`,
"voucher.orders.NormalSubscription": `Normal Subscription`,
"voucher.orders.OrderType": `Order Type`,
"voucher.orders.ProductVoucher": `Product Voucher`,
"voucher.orders.Products": `Products`,
"voucher.orders.Quantity": `Quantity`,
"voucher.orders.Search": `Search`,
"voucher.orders.ShopVoucher": `Shop Voucher`,
"voucher.orders.SingleOrder": `Single Order`,
"voucher.orders.TotalNumberOfVouchers": `Total number of vouchers`,
"voucher.orders.Usage": `Usage`,
"voucher.orders.Valid Period": `Valid Period`,
"voucher.orders.Voucher Type": `Voucher Type`,
"voucher.orders.VoucherName": `Voucher Name`,
"voucher.orders.claimed.tip": `Number of vouchers that have been claimed`,
"voucher.orders.usage.tip": `Number of vouchers that have been used (excluding cancelled and orders)`,
"voucher.petOwners": `Consumers`,
"voucher.petOwnersTip": `Total number of unique consumers who used voucher in confirmed orders.`,
"voucher.selectProduct": `Select Products`,
"voucher.spu": `SPU`,
"voucher.spu:": `spu:`,
"voucher.usageRate": `Usage Rate`,
"voucher.usageRateTip": `Total number of voucher usages in confirmed orders divide by total number of voucher claims.`,
"voucher.usageTip": `Number of vouchers that have been used (excluding cancelled orders)`,
"warehouseRuleAdd": `Add`,
"warehouseRuleDetail": `Detail`,
"warehouseRuleEdit": `Edit`,
"weight (kg)": `weight (kg)`,
"wx.abstract": `Abstract`,
"wx.abstract_tip": `optional if not filled the first line of 45 words will be grabbed by default`,
"wx.account_disable_tip1": `Are you sure that you want to disable the official account? If yes the other modules of Wechat Management（Fans managment Assets management Medule management...) would only be associated with the activied account.`,
"wx.account_disable_tip2": `Are you sure to disable this account?`,
"wx.account_enable_tip1": `Are you sure that you want to enable the official account? If yes the other modules of Wechat Management（Fans managment Assets management Medule management...) would only be associated with the activied account.`,
"wx.account_enable_tip2": `Are you sure to enable this account?`,
"wx.account_name": `Account Name`,
"wx.account_principal": `Account Principal`,
"wx.account_type": `Account Type`,
"wx.add_account": `Add Account`,
"wx.add_graphic_message": `Add Graphic Message`,
"wx.add_new_auto_replay": `Add New Automatic Reply`,
"wx.add_new_banner": `Add New MP Banner`,
"wx.add_new_menu": `Add New Menu`,
"wx.add_new_message": `Add a new message`,
"wx.add_new_qr_code": `Add New QR Code`,
"wx.add_picture_message": `Add Picture Message`,
"wx.add_video": `Add Video`,
"wx.add_video_message": `Add Video Message`,
"wx.add_voice_message": `Add Voice Message`,
"wx.all": `All`,
"wx.anchor_name": `Anchor Name`,
"wx.app_id": `App Id`,
"wx.app_secret": `AppSecret`,
"wx.are_you_sure_to_delete_message": `Are you sure to delete this message?`,
"wx.are_you_sure_to_publish": `Are you sure to publish the item?`,
"wx.are_you_sure_to_sync": `Are you sure to synchronize?`,
"wx.assets_id": `Wechat Assets ID`,
"wx.asso_account": `Associated Account`,
"wx.author": `Author`,
"wx.avatar": `avatar`,
"wx.banner_name": `Banner Name`,
"wx.certificate_path": `Certificate Path`,
"wx.channel": `Channel`,
"wx.channel_qr": `Channel QR code value`,
"wx.channel_type": `Channel Type`,
"wx.click_to_jump_link": `Subscribers click on this menu to jump to the following link`,
"wx.click_to_jump_miniprogram": `Subscribers click on this menu to jump to the following Mini Program`,
"wx.click_to_select": `Click to select`,
"wx.click_type": `Click Type`,
"wx.comment": `Comment`,
"wx.confirm_sync": `Confirm Synchronize`,
"wx.content": `Content`,
"wx.content_desc": `Content Description`,
"wx.cover_preview": `Cover Preview`,
"wx.create_time": `Create Time`,
"wx.description": `Description`,
"wx.edit_account": `Edit Account`,
"wx.edit_auto_reply": `Edit Automatic Reply`,
"wx.edit_banner": `Edit MP Banner`,
"wx.edit_menu": `Edit Menu`,
"wx.exact_matching": `Exact matching`,
"wx.expired": `Expired`,
"wx.fans_detail": `Fans Detail`,
"wx.file": `File`,
"wx.follow_time": `Follow Time`,
"wx.fuzzy_matching": `Fuzzy matching`,
"wx.gen_menu_data": `Generate menu data`,
"wx.graphic_main_cover": `Graphic main cover`,
"wx.graphic_message": `Graphic message`,
"wx.graphic_messages": `Graphic messages`,
"wx.home_page": `Home Page`,
"wx.is_member": `Is Member`,
"wx.joint_with_miniprogram": `Joint with Miniprogram`,
"wx.keywords": `Keywords`,
"wx.large_pic_size": `Large Pic: 900*500(Single image or multi-image first article)`,
"wx.link": `Link`,
"wx.link_for_article": `Link for "Read Original Article"`,
"wx.livestream_id": `Live Streaming ID`,
"wx.livestream_name": `Live Streaming Name`,
"wx.main_cover": `Main Cover`,
"wx.management_mode": `Management Mode`,
"wx.match_type": `Match Type`,
"wx.menu_item_delete_tip": `Are you sure to delete it?`,
"wx.menu_name": `Menu Name`,
"wx.menu_name_length": `No longer than 4 Chinese characters or 8 English characters are recommended`,
"wx.menu_valid_fail": `menu item validation failed`,
"wx.merchant_key": `Merchant Key`,
"wx.merchat_id": `Merchant ID`,
"wx.message_url": `Message Encryption Url`,
"wx.mini_program": `Mini Program`,
"wx.mini_program_id": `Mini Program ID`,
"wx.mini_program_path": `Mini Program Path`,
"wx.miniprogram_should_complete_setting": `Miniprogram menu should complete setting!`,
"wx.move_down": `Move Down`,
"wx.move_left": `Move Left`,
"wx.move_right": `Move Right`,
"wx.move_up": `Move Up`,
"wx.new_graphic": `New graphic`,
"wx.no_operation": `No operation`,
"wx.official_account_type": `Official Account Type`,
"wx.ongoing": `Ongoing`,
"wx.open_mp_page": `Open the MP page`,
"wx.open_other_mp_page": `Open other MP page`,
"wx.open_web_page": `Open the web page`,
"wx.openid": `OPENID`,
"wx.original_id": `Original ID`,
"wx.page": `Page`,
"wx.page_path": `Page Path`,
"wx.page_url": `Page URL`,
"wx.period": `Period`,
"wx.pic_location": `Pic Location`,
"wx.picture": `Picture`,
"wx.picture_assets": `Picture Assets`,
"wx.picture_message": `Picture message`,
"wx.pictures": `Pictures`,
"wx.please_input_account_name": `Please input Account Name`,
"wx.please_input_account_principal": `Please input Account Principal`,
"wx.please_input_app_secret": `Please input AppSecret`,
"wx.please_input_appid": `Please input AppID`,
"wx.please_input_keywords": `Please input keywords`,
"wx.please_input_message_url": `Please input Message Encryption Url`,
"wx.please_input_original_id": `Please input Original ID`,
"wx.please_input_token": `Please input Token`,
"wx.please_select_account_type": `Please select account type!`,
"wx.please_select_mode": `Please select Management Mode!`,
"wx.please_select_official_account_type": `Please select Official Account Type!`,
"wx.please_select_reply_content": `Please select Reply Content!`,
"wx.pls_add_menu": `Please add menu`,
"wx.pls_add_menu_name": `Please add menu name`,
"wx.pls_input_content": `Please input content`,
"wx.pls_input_desc": `Please input description`,
"wx.pls_input_title": `Please input title`,
"wx.pls_input_valid_url": `Please input a valid URL`,
"wx.pls_upload_asso_assets": `Please upload associate asset!`,
"wx.pls_upload_file": `Please upload file`,
"wx.pls_upload_thumb": `Please upload thumbnail picture!`,
"wx.publish": `Publish`,
"wx.publish_item": `Publish Item`,
"wx.push_server": `Push Server`,
"wx.qr_code_color": `QR Code Color`,
"wx.qr_code_detail": `QR Code Detail`,
"wx.qr_code_key_value": `QR Code Key Value`,
"wx.qr_code_path": `QR Code Path`,
"wx.qr_code_size": `QR Code Size`,
"wx.qr_code_size_recom": `Minimun280 Maximun1280`,
"wx.qr_code_type": `QR Code Type`,
"wx.qr_code_value": `QR Code Value`,
"wx.redirect_should_contain_url": `Web redirection menu should redirect to a valid url!`,
"wx.reply_desc": `Reply Description`,
"wx.reply_type": `Reply Type`,
"wx.response_desc": `Response Description`,
"wx.response_should_choose_asset": `Response message menu should choose assets!`,
"wx.response_type": `Response Type`,
"wx.save_and_publish": `Save and Publish`,
"wx.save_and_sync": `Save and Synchronize`,
"wx.scenario": `Scenario`,
"wx.scenario_type": `Scenario Type`,
"wx.select_exist_graphic": `Select an existing graphic`,
"wx.select_file": `Select file`,
"wx.select_from_assets": `Select from assets`,
"wx.select_reply_content": `Select Reply Content`,
"wx.send_response_message": `Send response message`,
"wx.share": `Share`,
"wx.shop_page": `Shop Page`,
"wx.single_mess_del_tip": `Single message cannot be deleted!`,
"wx.small_pic_size": `Small Pic: 200*200(Below the first picture and text of multiple pictures)`,
"wx.sort": `Sort`,
"wx.suggest_size": `Suggested Size`,
"wx.sync_all": `Synchronize All`,
"wx.sync_fans_all": `Synchronize All Fans Information`,
"wx.sync_fans_all_con": `Are you want to synchronize ? The number of fans is large please wait`,
"wx.sync_fans_openid": `Synchronize All Openids`,
"wx.sync_fans_tip": `The fans information is synchronizing. Once finished the information will be updated automatically.`,
"wx.sync_live_tip": `Please select the live Streaming you want to Synchronize`,
"wx.sync_livestream": `Synchronize Live Streaming`,
"wx.sync_partial": `Partial Synchronize`,
"wx.sync_partial_tip": `Please select at least one item`,
"wx.sync_wechat_assets": `Synchronize WeChat Assets`,
"wx.title": `Title`,
"wx.token": `Token`,
"wx.trans_background": `Transparent background`,
"wx.unionid": `UNIONID`,
"wx.upcoming": `Upcoming`,
"wx.update_time": `Update Time`,
"wx.upload": `Upload`,
"wx.url": `URL`,
"wx.video": `Video`,
"wx.video_assets": `Video Assets`,
"wx.video_message": `Video message`,
"wx.videos": `Videos`,
"wx.view_details": `View Details`,
"wx.view_image": `View Image`,
"wx.view_qr_code": `View QR Code`,
"wx.view_video": `View Video`,
"wx.voice": `Voice`,
"wx.voice_assets": `Voice Assets`,
"wx.voice_message": `Voice message`,
"wx.voices": `Voices`,
"wx.web_redirection": `Web redirection`,
"wx.wechat_account": `Wechat Account`,
"wx.wechat_assets_link": `Wechat Assets Link`,
"wx.wechat_name": `Wechat Name`,
"yourTiers": `Your Tiers`,
"默认重量": `默认重量`,
 }
