import ApiRoot from "./fetcher"
import apis from "@/framework/config/api-config"

export const getTemporaryData = async (params: any) => {
  try {
    let res = await ApiRoot({ url: apis.crm }).crm().temporaryDataQuery(params)
    return res || []
  } catch (e) {
    console.log(e)
    return []
  }
}

export const createTemporaryData = async (params: any) => {
  try {
    let res = await ApiRoot({ url: apis.crm }).crm().temporaryDataCreate(params)
    return res || false
  } catch (e) {
    console.log(e)
    return false
  }
}
