import { Suspense, useEffect, useContext, useState } from 'react'
import { useRoutes, useNavigate, useLocation } from 'react-router-dom'
import Router from './routers/index'
import './App.less'
import '@/assets/css/global.less'
import '@/assets/css/iconfont/iconfont.css'
import Loading from '@/assets/images/loading2.gif'
import intl from 'react-intl-universal'
import { ConfigProvider } from 'antd'
import { useAtom } from 'jotai'
import { userAtom } from './store/user.store'
import { langAtom } from './store/lang.store'
import { TabContext } from './contexts/TabContext'
// import zh_CN from '@/locale/translation/zh-CN'
// import en_US from '@/locale/translation/en-US'
import zh_CN from '@/locale/translation/zh-CH'
import en_US from '@/locale/translation/en-GB'
import locals, { andtdLoacal, TLangKey } from './locale'
import classNames from 'classnames'
import React from 'react'
import useRefreshTime from './contexts/intl'
import { Base64 } from './utils/base64'
import Cookies from 'js-cookie'
import { getDataFormSeamCookie, getFormLocalstorage, removeToken, setToSemeCookie } from './utils/utils'
import queryString from 'query-string'
import { getcliantId, setUserInfofn } from '@/common/components/authloading'
import { modules } from '@/common/components/Header'
import { getMenuData } from './components/common/MenusWrapper'

ConfigProvider.config({
  theme: { primaryColor: getDataFormSeamCookie('theme') || '#ee4d2d', },
});


function App() {
  const [refreshTime, setRefreshTime] = useRefreshTime()
  let appClassName = 'consumer-app-container'
  let Routers = useRoutes(Router)
  const navigate = useNavigate()
  const location = useLocation()
  const [userInfo] = useAtom(userAtom)
  const [lang, setLang] = useAtom(langAtom)
  const [languages, setLanguageList] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  // useEffect(() => {
  //   console.log(process.env.REACT_APP_BESE_URL, 'console.log(process.env.REACT_APP_BASE_URL)')
  //   if (location.pathname === '/') {
  //     if (userInfo?.id) {
  //       navigate('/shipment-list')
  //     } else {
  //       navigate('/login')
  //     }
  //   }
  // }, [location.pathname])

  const checkHasPermistion = async () => {
    const query = queryString.parse(window.location.search)
    const token = query.token ?? ''
    const init_page = query.init_page ?? ''
    if (query.rc_access_ticket) {
      Cookies.set('rc_access_ticket', query.rc_access_ticket, { expires: 1000 * 60 * 60 * 24, path: '/' })
    }
    if (location.pathname === '/campaignviewH5' || location.pathname === '/survey') {
      setToSemeCookie('localStoreId', '200000001')
      setToSemeCookie('rc_sc_login_store_id', '200000001')
      setLoading(false)
    } else {
      if (!Cookies.get('rc_access_token') && !['/login', '/register', '/authloading', '/campaignviewH5'].includes(location.pathname)) {
        if (!Cookies.get('rc_access_ticket')) {
          removeToken()
          if (process.env.NODE_ENV == 'development') {
            setLoading(false)
            navigate('/login')
          } else {
            window.location.href = `${process.env.REACT_APP_AUTHPATH}/oauth/authorized?clientId=${getcliantId()}&loginUrl=${process.env.REACT_APP_AUTHLOGIN}&state=zero`
          }
        }
      } else {
        if (!token && !init_page && process.env.NODE_ENV !== 'development') { //排除九宫格切换，初始化登录。每次刷新都要校验是否已经退出登录
          await setUserInfofn(Cookies.get('rc_access_token'), getcliantId())
        }
        setLoading(false)
      }
    }
  }
  const routerHistory = useLocation()
  const changeSeo = (routerHistory: Location) => {
    if (routerHistory.pathname == '/authloading' || routerHistory.pathname == '/store/select') return true
    const activeTab = modules.find(items => items.url.indexOf(window.location.host) > -1)?.value ?? 0
    const menuData = getMenuData(activeTab)
    const title = document.querySelector('title')
    const findActiveName = menuData.find(i => i.activekey == routerHistory.pathname.split('/')[1])
    const menuName = routerHistory.pathname !== '/' ?
      intl.get((findActiveName?.langKey) ?? 'loading') == '' ? findActiveName?.name : intl.get(findActiveName?.langKey) :
      intl.get(menuData[0].langKey ?? 'loading')
    try {
      title.innerHTML = `${menuName.charAt(0).toUpperCase() + menuName.slice(1)} | ${getDataFormSeamCookie('rc_sc_login_module')} | DTC Platform`
    } catch (error) {
      title.innerHTML = `DTC Platform`
    }
  }
  useEffect(() => {
    checkHasPermistion()
    changeSeo(routerHistory)
  }, [routerHistory])
  useEffect(() => {
    setLang(getDataFormSeamCookie('locale') ?? "en_US")
  }, [])
  useEffect(() => { initIntl(setRefreshTime) }, [lang])
  // const initIntl = async (refreshPage: any) => {
  //   try {
  //     let languageData: any = {}
  //     if (process.env.NODE_ENV === 'production') {
  //       languageData = {
  //         loca: {
  //           zh_CN: zh_CN,
  //           en_US: en_US
  //         }
  //       }
  //     } else {
  //       languageData = await locals(getDataFormSeamCookie('locale') ?? "en_US")
  //     }
  //     intl.init({
  //       currentLocale: lang,
  //       //currentLocale: getDataFormSeamCookie('locale') ?? "en_US",
  //       locales: languageData?.loca,
  //       fallbackLocale: 'en_US'
  //     }).then(() => {
  //       refreshPage(Math.random() * 10000)
  //     })
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const initIntl = async (refreshPage: any) => {
    let languageData = {
      loca: {
        zh_CN: zh_CN,
        en_US: en_US
      }
    }
    intl.init({
      currentLocale: lang,
      locales: languageData?.loca,
      fallbackLocale: 'en_US'
    }).then(() => {
      refreshPage(Math.random() * 10000)
    }).catch(error => {
      console.log(error)
    });

  }

  if (loading) {
    return (
      <div className='w-full h-screen flex justify-center items-center'>
        <img className='mb-15' alt='' style={{ width: '100px' }} src={Loading} />
      </div>
    )
  }

  return (
    <div className='App text-center lanugageVersin5' key={refreshTime}>
      <ConfigProvider locale={andtdLoacal?.antd?.[getDataFormSeamCookie('locale') ?? 'en_US']}>
        <Suspense
          fallback={
            <div className='w-full h-screen flex justify-center items-center'>
              <img className='mb-15' alt='' style={{ width: '100px' }} src={Loading} />
            </div>
          }
        >
          <div className={classNames('text-left', appClassName)}>{Routers}</div>
        </Suspense>
      </ConfigProvider>
    </div>
  )
}

export default App
