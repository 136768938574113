// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/_css-loader@6.11.0@css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/_css-loader@6.11.0@css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boxShodow {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
}
.header-title-tip .ant-tooltip-inner {
  font-size: 13px;
}
.top-header-dropdown .ant-menu-submenu:not(.ant-menu-submenu-selected) .ant-menu-submenu-title .ant-menu-title-content,
.top-header-dropdown .ant-menu-item .ant-menu-title-content {
  font-weight: 400 !important;
  color: #333333;
}
.top-header-dropdown .ant-menu-submenu:not(.ant-menu-submenu-selected) .ant-menu-submenu-title .ant-menu-title-content:hover,
.top-header-dropdown .ant-menu-item .ant-menu-title-content:hover {
  font-weight: 500 !important;
}
.top-header .tab {
  margin-left: 40px;
}
.top-header .tab .tab-menu {
  font-size: 12px;
  padding: 0 10px;
  cursor: pointer;
}
.top-header .tab .tab-menu.active {
  color: var(--ant-primary-color);
}
.top-header .cms-header {
  color: var(--ant-primary-color);
  cursor: pointer;
  font-size: 24px;
  font-weight: 700;
  height: 51px;
  line-height: 51px;
  margin-left: 20px;
}
.moduleMenuPop .ant-popover-inner-content {
  padding: 20px;
}
.userMenuPop .ant-popover-inner-content {
  padding: 0px 10px;
}
.menuIcon {
  padding: 4px;
  border-radius: 3px;
}
.header-menu-flex .ant-menu-submenu-title {
  display: flex;
  align-items: center;
}
.header-menu-flex .ant-menu-submenu-title .ant-menu-title-content {
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/Header/index.less"],"names":[],"mappings":"AAAA;EACE,8CAAA;AACF;AAEA;EAEI,eAAA;AADJ;AAWA;;EAGM,2BAAA;EAEA,cAAA;AAXN;AAaM;;EACE,2BAAA;AAVR;AAiBA;EAII,iBAAA;AAlBJ;AAcA;EAOM,eAAA;EACA,eAAA;EACA,eAAA;AAlBN;AAoBM;EACE,+BAAA;AAlBR;AAMA;EAkBI,+BAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;EACA,iBAAA;AArBJ;AAyBA;EAEI,aAAA;AAxBJ;AA4BA;EAEI,iBAAA;AA3BJ;AA+BA;EACE,YAAA;EACA,kBAAA;AA7BF;AAoCA;EAEI,aAAA;EACA,mBAAA;AAnCJ;AAgCA;EAMM,aAAA;AAnCN","sourcesContent":[".boxShodow {\n  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);\n}\n\n.header-title-tip {\n  .ant-tooltip-inner {\n    font-size: 13px;\n    // background: #fff;\n    // color: #555;\n  }\n\n  // .ant-tooltip-arrow-content::before {\n  //   background: #fff;\n  // }\n}\n\n.top-header-dropdown {\n  .ant-menu-submenu:not(.ant-menu-submenu-selected) .ant-menu-submenu-title , .ant-menu-item {\n    .ant-menu-title-content {\n      font-weight: 400 !important;\n\n      color: #333333;\n\n      &:hover {\n        font-weight: 500 !important;\n      }\n    }\n\n  }\n}\n\n.top-header {\n\n\n  .tab {\n    margin-left: 40px;\n\n    .tab-menu {\n      font-size: 12px;\n      padding: 0 10px;\n      cursor: pointer;\n\n      &.active {\n        color: var(--ant-primary-color);\n      }\n    }\n  }\n\n  .cms-header {\n    color: var(--ant-primary-color);\n    cursor: pointer;\n    font-size: 24px;\n    font-weight: 700;\n    height: 51px;\n    line-height: 51px;\n    margin-left: 20px;\n  }\n}\n\n.moduleMenuPop {\n  .ant-popover-inner-content {\n    padding: 20px;\n  }\n}\n\n.userMenuPop {\n  .ant-popover-inner-content {\n    padding: 0px 10px;\n  }\n}\n\n.menuIcon {\n  padding: 4px;\n  border-radius: 3px;\n\n  // &:hover {\n  //   background-color: #EEEEEE;\n  // }\n}\n\n.header-menu-flex {\n  .ant-menu-submenu-title {\n    display: flex;\n    align-items: center;\n\n    .ant-menu-title-content {\n      display: flex;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
