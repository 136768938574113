import Survey from '@/views/Survey'
import { lazy } from 'react'

const WechatSetting = lazy(() => import('../views/wechatSetting'))
const ComingSoon = lazy(() => import('@/views/comingSoon'))
const Layout = lazy(() => import('../common/components/Layout'))
const Home = lazy(() => import('../views/index'))
const Login = lazy(() => import('../views/login'))
const LoginStore = lazy(() => import('../views/loginStore'))
const SelectStore = lazy(() => import('@/common/components/selectStore'))
const LoginBrand = lazy(() => import('../views/loginBrand'))
const ResetPassword = lazy(() => import('../views/resetPassword'))
const Register = lazy(() => import('../views/register'))
const EmailList = lazy(() => import('../views/emailList/list'))
const SMSList = lazy(() => import('../views/smsList/list'))
const AddOrEditEmail = lazy(() => import('../views/emailList/addAndEdit'))
const AddOrEditSMS = lazy(() => import('../views/smsList/addAndEdit'))
const CompaignPlan = lazy(() => import('../views/compaignPlan'))
const TaskCreateAndEdit = lazy(() => import('../views/smartOperation/addAndE'))
const MessageList = lazy(() => import('@/views/messageCenter/messageList'))
const MessageDetail = lazy(() => import('@/views/messageCenter/messageDetail'))
const ProcessCanvasCreate = lazy(() => import('@/views/processCanvas/create'))
const CampaignDetail = lazy(() => import('@/views/processCanvas/detail'))
const QuickStart = lazy(() => import('@/views/quickStart'))
const SMSDetail = lazy(() => import('@/views/smsList/detail'))
const EmailDetail = lazy(() => import('@/views/emailList/detail'))
const AuthLoading = lazy(() => import('@/views/authLoading'))
const PartnerType = lazy(() => import('@/views/partnerType'))
const PartnerList = lazy(() => import('@/views/partnerList'))
const PartnerDetail = lazy(() => import('@/views/partnerDetail'))
const PetOwnerList = lazy(() => import('@/views/petOwnerList'))
const PetOwnerDetail = lazy(() => import('@/views/petOwnerDetail'))
const PetDetail = lazy(() => import('@/views/petDetail'))
const PetPoints = lazy(() => import('@/views/petPoints'))
const PetReferrals = lazy(() => import('@/views/petReferrals'))
const LoyalProgram = lazy(() => import('@/views/loyalProgram'))
const TierSetting = lazy(() => import('@/views/tierSetting'))
const RewardSettingsList = lazy(() => import('@/views/rewardSettingsList'))
const RewardSettingDetail = lazy(() => import('@/views/rewardSettingsList/components/Detail'))
const RewardHistory = lazy(() => import('@/views/rewardHistory'))
const Dashboard = lazy(() => import('@/views/dashboard'))

const GeneralEvents = lazy(() => import("@/views/dataManagement/metaData/generalEvents"))
const TagList = lazy(() => import("@/views/tagList"))
const TagEdit = lazy(() => import("@/views/tagEdit"))
const GeneralEventsSingleAdd = lazy(() => import('@/views/dataManagement/metaData/generalEvents/singleAdd'))
const AttributeSingleAdd = lazy(() => import('@/views/dataManagement/metaData/generalEvents/singleAdd/attributeSingleAdd'))
const GeneralEventsBulkAdd = lazy(() => import('@/views/dataManagement/metaData/generalEvents/bulkAdd'))
const GeneralEventsEventDetail = lazy(() => import('@/views/dataManagement/metaData/generalEvents/eventDetails'))
const GeneralEventsEventEdit = lazy(() => import('@/views/dataManagement/metaData/generalEvents/eventDetails'))
const EventAttribute = lazy(() => import("@/views/dataManagement/metaData/eventAttribute"))
const PrincipalAttribute = lazy(() => import("@/views/dataManagement/metaData/principalAttribute"))
const TransactionAttribute = lazy(() => import("@/views/dataManagement/metaData/transactionAttribute"))
const IDMapping = lazy(() => import("@/views/dataManagement/idMapping"))

const Content = lazy(() => import('../views/cms/content/index'))
const ContentDetail = lazy(() => import('../views/cms/content/detail'))
const ContentTypes = lazy(() => import('../views/cms/contentTypes/index'))
const ContentType = lazy(() => import('../views/cms/contentTypes/detail'))
const PageComponent = lazy(() => import('@/views/cms-page/pageContent/indexPage'))
const PageContent = lazy(() => import('@/views/cms-page/pageContent'))
const CmsPage = lazy(() => import('@/views/cms-page'))
const MediaLibraryList = lazy(() => import('../views/cms/assets/index'))
const MediaLibraryDetail = lazy(() => import('../views/cms/assets/detail'))

const DataIngestionList = lazy(() => import("@/views/dataManagement/dataIngestionList"))
const SegmentsList = lazy(() => import('@/views/segmentsManagement/segmentsList'))
const SegmentsEdit = lazy(() => import('@/views/segmentsManagement/segmentsEdit'))
const ConsumerInsightsList = lazy(() => import('@/views/ConsumerInsights/consumerInsightsList'))
const ConsumerInsightsDetail = lazy(() => import('@/views/ConsumerInsights/consumerInsightsDetail'))
const CampaignviewH5 = lazy(() => import("@/components/fromCampaign/formCampaign"))

export interface RouteObject {
  caseSensitive?: boolean
  children?: RouteObject[]
  element?: React.ReactNode
  index?: boolean
  path?: string
  breadcrumbName?: string
  Navigate?: string
  key?: string
}

let routes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      { index: true, element: <Dashboard /> },
      { path: 'home', element: <Home /> },
      {
        path: 'template-management',
        breadcrumbName: 'Templates',
        children: [
          { index: true, element: <EmailList /> },
          {
            path: 'email-list',
            breadcrumbName: 'Email Template',
            children: [
              { index: true, element: <EmailList /> },
              { path: 'add-email', element: <AddOrEditEmail />, breadcrumbName: 'Add Email Template' },
              { path: 'edit-email', element: <AddOrEditEmail />, breadcrumbName: 'Edit Email Template' },
              { path: 'email-detail', element: <EmailDetail />, breadcrumbName: 'Email Template Detail' },
            ],
          },
          {
            path: 'sms-list',
            breadcrumbName: 'SMS Template',
            children: [
              { index: true, element: <SMSList /> },
              { path: 'add-sms', element: <AddOrEditSMS />, breadcrumbName: 'Create SMS Template' },
              { path: 'edit-sms', element: <AddOrEditSMS />, breadcrumbName: 'Edit SMS Template' },
              { path: 'sms-detail', element: <SMSDetail />, breadcrumbName: 'SMS Template Detail' },
            ],
          },
          {
            path: 'wechat-setting',
            element: <WechatSetting />,
            breadcrumbName: 'WeCom Setting',
          },
        ],
      },
      {
        path: 'contacts',
        breadcrumbName: 'Contact',
        children: [
          { index: true, element: <PetOwnerList /> },
          {
            path: 'contacts-list', breadcrumbName: 'Contact List'
            , children: [
              {
                index: true,
                element: <PetOwnerList />,
              },
              { path: 'contacts-detail', element: <PetOwnerDetail />, breadcrumbName: 'Contact Detail' },
            ]
          },
          { path: 'pet-detail', element: <PetDetail />, breadcrumbName: 'Pet Detail' },
          {
            path: 'loyalty',
            breadcrumbName: 'Loyalty Programs',
            children: [
              { index: true, element: <LoyalProgram /> },
              { path: 'points', element: <PetPoints />, breadcrumbName: 'Points' },
              { path: 'referrals', element: <PetReferrals />, breadcrumbName: 'Referrals' },
              {
                path: 'loyalty-programs', breadcrumbName: 'Loyalty Programs',
                children: [
                  { index: true, element: <LoyalProgram /> },
                  { path: 'tier-setting', element: <TierSetting />, breadcrumbName: 'Tier Setting' },
                ]
              },
              {
                path: 'reward-settings-list',
                breadcrumbName: 'Reward Settings',
                children: [
                  { index: true, element: <RewardSettingsList /> },
                  { path: 'reward-add', element: <RewardSettingDetail />, breadcrumbName: 'Add Reward' },
                  { path: 'reward-detail', element: <RewardSettingDetail />, breadcrumbName: 'Reward Detail' },
                ],
              },
              { path: 'reward-history', element: <RewardHistory />, breadcrumbName: 'Reward History' },
            ],
          },
        ],
      },
      {
        path: 'entity',
        breadcrumbName: 'Entity',
        children: [
          { index: true, element: <PartnerType /> },
          { path: 'partner-type', element: <PartnerType />, breadcrumbName: 'Entity.Entity Type' },
          {
            path: 'entity-list',
            breadcrumbName: 'Entity List',
            children: [
              { index: true, element: <PartnerList /> },
              { path: 'entity-detail/:id', element: <PartnerDetail />, breadcrumbName: 'Entity Detail' },
            ],
          },
        ],
      },
      {
        path: 'message-center',
        breadcrumbName: 'Message Center',
        children: [
          { index: true, element: <MessageList /> },
          {
            path: 'message-list',
            breadcrumbName: 'Message list',
            children: [
              { index: true, element: <MessageList /> },
              { path: 'message-detail', element: <MessageDetail />, breadcrumbName: 'Message Detail' },
            ],
          },
        ],
      },
      {
        path: 'campaign-plan',
        breadcrumbName: 'Campaign Plan',
        children: [{ index: true, element: <CompaignPlan currentType='COMPAIGNPLAN' processCategory='' /> }],
      },
      {
        path: 'smart-operation',
        breadcrumbName: 'Smart Operation',
        children: [
          { index: true, element: <CompaignPlan currentType='QUICKTASK' processCategory='TASK' /> },
          {
            path: 'quick-task',
            breadcrumbName: 'Quick Task',
            children: [
              { index: true, element: <CompaignPlan currentType='QUICKTASK' processCategory='TASK' /> },
              { path: 'create-task', element: <TaskCreateAndEdit pageStatus={'create'} />, breadcrumbName: 'Create Task' },
              { path: 'edit-task', element: <TaskCreateAndEdit pageStatus={'update'} />, breadcrumbName: 'Edit Task' },
              { path: 'task-detail', element: <TaskCreateAndEdit pageStatus={'detail'} />, breadcrumbName: 'Task Detail' },
            ],
          },
          {
            path: 'process-canvas',
            breadcrumbName: 'Process Canvas',
            children: [
              { index: true, element: <CompaignPlan currentType='PROCESSCANVAS' processCategory='CAMPAIGN' /> },
              { path: 'create', element: <ProcessCanvasCreate />, breadcrumbName: 'Create Process Canvas' },
              { path: 'detail', element: <CampaignDetail />, breadcrumbName: 'Campaign Detail' },
            ],
          },
        ],
      },
      {
        path: 'data-management', breadcrumbName: 'Data Management',
        children: [
          { index: true, element: < GeneralEvents /> },
          {
            path: 'meta-data', breadcrumbName: 'Meta Data',
            children: [
              { index: true, element: < GeneralEvents /> },
              {
                path: 'general-events', breadcrumbName: 'Events',
                children: [
                  { index: true, element: < GeneralEvents /> },
                  { path: 'general-event-details', breadcrumbName: 'Event Detail', element: <GeneralEventsEventDetail /> },
                  { path: 'general-event-edit', breadcrumbName: 'Event Edit', element: <GeneralEventsEventEdit /> },
                  { path: 'general-single-add', breadcrumbName: 'Single Add', element: <GeneralEventsSingleAdd /> },
                  // { path: 'bulk-add', breadcrumbName: 'Bulk Add', element: <GeneralEventsBulkAdd /> },
                ]
              },
              {
                path: 'event-attribute', breadcrumbName: 'Event Attribute',
                children: [
                  { index: true, element: < EventAttribute /> },
                  { path: 'attribute-event-details', breadcrumbName: 'Event Detail', element: <GeneralEventsEventDetail /> },
                  { path: 'attribute-event-edit', breadcrumbName: 'Event Edit', element: <GeneralEventsEventEdit /> },
                  { path: 'attribute-single-add', breadcrumbName: 'Single Add', element: <AttributeSingleAdd /> },
                  // { path: 'attribute-bulk-add', breadcrumbName: 'Bulk Add', element: <GeneralEventsBulkAdd /> },
                ]
              },
              {
                path: 'principal-attribute', breadcrumbName: 'Principal Attribute',
                children: [
                  { index: true, element: < PrincipalAttribute /> },
                  { path: 'principal-event-details', breadcrumbName: 'Event Detail', element: <GeneralEventsEventDetail /> },
                  { path: 'principal-event-edit', breadcrumbName: 'Event Edit', element: <GeneralEventsEventEdit /> },
                  { path: 'principal-single-add', breadcrumbName: 'Single Add', element: <AttributeSingleAdd /> },
                  // { path: 'principal-bulk-add', breadcrumbName: 'Bulk Add', element: <GeneralEventsBulkAdd /> },
                ]
              },
              {
                path: 'transaction-attribute', breadcrumbName: 'Transaction Attribute',
                children: [
                  { index: true, element: < TransactionAttribute /> },
                  { path: 'transaction-event-details', breadcrumbName: 'Event Detail', element: <GeneralEventsEventDetail /> },
                  { path: 'transaction-event-edit', breadcrumbName: 'Event Edit', element: <GeneralEventsEventEdit /> },
                  { path: 'transaction-single-add', breadcrumbName: 'Single Add', element: <AttributeSingleAdd /> },
                  // { path: 'transaction-bulk-add', breadcrumbName: 'Bulk Add', element: <GeneralEventsBulkAdd /> },
                ]
              },
            ]
          }, {
            path: 'data-ingestion', breadcrumbName: 'Data Ingestion', element: <DataIngestionList />
          }, {
            path: 'id-mapping', breadcrumbName: 'ID Mapping', element: <IDMapping />
          }]
      },
      {
        path: 'tag-management',
        breadcrumbName: 'Tag Management',
        children: [
          { index: true, element: <TagList /> },
          {
            path: 'list',
            breadcrumbName: 'Tags List',
            children: [
              { index: true, element: <TagList /> },
              {
                path: 'edit',
                element: <TagEdit />,
                breadcrumbName: 'Edit Tags',
              },
            ]
          },
          {
            path: 'add',
            element: <TagEdit />,
            breadcrumbName: 'Ceate Tags',
          }]
      },
      {
        path: 'segments',
        breadcrumbName: 'Segments Management',
        children: [
          { index: true, element: <SegmentsList /> },
          {
            path: 'segments-list',
            breadcrumbName: 'Segments List',
            children: [
              {
                index: true,
                element: <SegmentsList />
              },
              {
                path: 'segments-detail',
                breadcrumbName: 'Segments Detail',
                element: <SegmentsEdit key="segments-detail" />
              }
            ]
          },
          {
            path: 'segments-add',
            breadcrumbName: 'Create New Segments',
            element: <SegmentsEdit key="segments-add" />
          },
        ]
      },
      {
        path: 'insights',
        breadcrumbName: 'insights',
        children: [
          { index: true, element: <ConsumerInsightsList /> },
          {
            path: 'consumer-insights-list',
            breadcrumbName: 'Customer Insights List',
            children: [
              {
                index: true,
                element: <ConsumerInsightsList />
              }, {
                path: 'detail',
                breadcrumbName: 'Customer Insights',
                element: <ConsumerInsightsDetail key={Math.random()} />
              }
            ]
          }
        ]
      },
      {
        path: 'cms-pages',
        breadcrumbName: 'Pages',
        children: [
          {
            index: true,
            element: <Content />,
            breadcrumbName: 'Pages',
          },
          {
            path: 'list',
            element: <Content />,
            breadcrumbName: 'Page List',
            children: [
              {
                index: true,
                element: <Content />,
                breadcrumbName: 'Pages',
              },
              {
                path: 'pageTypes',
                element: <ContentDetail />,
                breadcrumbName: 'New Page',
              },
            ]
          },

          {
            path: 'detail',
            element: <CmsPage />,
            breadcrumbName: 'Pages',
          },
          {
            path: 'detailNew',
            element: <PageComponent />,
            breadcrumbName: 'PageComponent',
          },
          {
            path: 'carousel',
            element: <PageContent />,
            breadcrumbName: 'PageContent',
          },
        ]
      },
      {
        path: 'cms-collections',
        breadcrumbName: 'Collections',
        children: [
          {
            index: true,
            element: <Content />,
            breadcrumbName: 'Collections',
          },
          {
            path: 'collection',
            element: <ContentDetail />,
            breadcrumbName: 'Collections',
          },
        ]
      },
      {
        path: 'cms-contentTypes',
        breadcrumbName: 'Content Types',
        children: [
          {
            index: true,
            element: <ContentTypes />,
            breadcrumbName: 'Content Types',
          },
          { path: 'contentTypes-component', element: <ContentType />, breadcrumbName: 'Content Types' },
          { path: 'contentTypes-collection', element: <ContentType />, breadcrumbName: 'Content Types' },
          { path: 'contentTypes-pageTypes', element: <ContentType />, breadcrumbName: 'Content Types' },
        ]
      },
      {
        path: 'digital-assets',
        breadcrumbName: 'menu.cmsAsets',
        children: [
          {
            index: true,
            element: <MediaLibraryList />,
            breadcrumbName: 'assets.mediaLibrary',
          },
          {
            path: 'media-library', breadcrumbName: 'assets.mediaLibrary',
            children: [{
              index: true, element: <MediaLibraryList />
            },
            {
              path: 'edit',
              element: <MediaLibraryDetail />,
              breadcrumbName: 'assets.mediaLibrary edit',
            }
            ]
          }
        ]
      },
    ],
    key: 'store',
  },
  { path: '/authloading', element: <AuthLoading />, key: 'store' },
  { path: '/login', element: <Login />, key: 'store' },
  { path: '/store/select', element: <SelectStore />, key: 'store' },
  { path: '/login/store', element: <LoginStore />, key: 'store' },
  { path: '/login/brand', element: <LoginBrand />, key: 'store' },
  { path: '/resetPassword', element: <ResetPassword />, key: 'store' },
  { path: '/register', element: <Register />, key: 'store' },
  { path: '/campaignviewH5', element: <CampaignviewH5 />, key: 'store' },
  { path: '/survey', element: <Survey />, key: 'store' },
  { path: '*', element: <Home />, key: 'store' },
]

// The useRoutes() hook allows you to define your routes as JavaScript objects
// instead of <Routes> and <Route> elements. This is really just a style
// preference for those who prefer to not use JSX for their routes config.

export default routes
