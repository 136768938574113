/*
 * @Author: yuszhou yuszhou@deloitte.com.cn
 * @Date: 2023-02-21 10:51:40
 * @LastEditors: yuszhou yuszhou@deloitte.com.cn
 * @LastEditTime: 2023-03-21 21:23:43
 * @FilePath: \rc_china_storeportal_ma\src\lib\menus.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
interface MenuObject {
  name: string
  value: string
  url: string
  key: string // auth permission key.
  icon?: any
  activekey: string, // menu active key.
  langKey: string
  children?: MenuObject[]
}

export const menus: MenuObject[] = [
  {
    name: 'Home',
    value: 'quickStart',
    url: '',
    key: 'quickStart',
    activekey: 'quickStart',
    icon: <span className='icon iconfont text-[18px] icon-quickstart' />,
    langKey: 'menu.quickStart',
  },
  {
    name: 'Account',
    value: 'Account',
    url: '',
    icon: <span className='icon iconfont text-[18px] icon-insight' />,
    key: 'Account',
    activekey: 'Account',
    // lottieIcon: consumer,
    langKey: 'Account',
    children: [
      {
        name: 'Entity List',
        value: 'partner_list',
        url: '/entity/entity-list',
        activekey: 'partner-list',
        key: 'partner.PartnerList',
        langKey: 'menu.partner_list'
      }
      // {
      //   name: 'Pet Owner',
      //   value: 'petOwner',
      //   url: '',
      //   key: 'consumer',
      //   activekey: 'consumer',
      //   // icon: <span className="icon iconfont icon-a-bianzu12-1" />,
      //   // lottieIcon: consumer,
      //   langKey: 'menu.pet_owner',
      //   children: [
      //     {
      //       name: 'My Pet Owner',
      //       value: 'my_pet_owner',
      //       url: '/consumer/consumer-list',
      //       activekey: 'consumer-list',
      //       key: 'consumer.consumerList',
      //       langKey: 'menu.my_pet_owner',
      //     },
      //     {
      //       name: 'Tagging Setting',
      //       value: 'tagging_setting',
      //       activekey: 'tag-list',
      //       url: '/tags/tag-list',
      //       key: 'consumer.taggingSetting',
      //       langKey: 'menu.tagging_setting',
      //     },
      //     {
      //       name: 'Loyalty',
      //       value: 'loyalty',
      //       url: '',
      //       key: 'consumer.loyalty',
      //       activekey: 'loyalty',
      //       langKey: 'menu.loyalty',
      //       children: [
      //         {
      //           name: 'Loyalty Program',
      //           value: 'loyalty_program',
      //           activekey: 'loyalty-programs',
      //           url: '/consumer/loyalty/loyalty-programs',
      //           key: '',
      //           langKey: 'menu.loyalty_program',
      //         },
      //         {
      //           name: 'Points',
      //           value: 'points',
      //           url: '/consumer/loyalty/points',
      //           activekey: 'points',
      //           key: '',
      //           langKey: 'menu.points',
      //         },
      //         {
      //           name: 'Referrals',
      //           value: 'referrals',
      //           url: '/consumer/loyalty/referrals',
      //           activekey: 'referrals',
      //           key: '',
      //           langKey: 'menu.referrals',
      //         },
      //         {
      //           name: 'Reward History',
      //           value: 'rewardHistory',
      //           activekey: 'reward-history',
      //           url: '/consumer/loyalty/reward-history',
      //           key: '',
      //           langKey: 'menu.rewardHistory',
      //         },
      //         {
      //           name: 'Reward Settings',
      //           value: 'rewardSettings',
      //           url: '/consumer/loyalty/reward-settings-list',
      //           activekey: 'reward-settings-list',
      //           langKey: 'menu.rewardSettings',
      //           key: ''
      //         }]
      //     },

      //   ],
      // },
      // {
      //   name: 'Entity',
      //   value: 'partner',
      //   url: '',
      //   key: 'partner',
      //   // icon: <span className='iconfont icon icon-_partner  text-[17px]'></span>,
      //   // icon: <img src={partnerSvg} alt="" width={18} style={{ margin: '0px 2px 0 1px' }} />,
      //   langKey: 'menu.partner',
      //   activekey: 'partner',
      //   children: [
      //     {
      //       name: 'Entity Type',
      //       value: 'partner_type',
      //       url: '/partner/partner-type',
      //       key: 'partner.partnerType',
      //       langKey: 'menu.partner_type',
      //       activekey: 'partner-type',
      //     },
      //     {
      //       name: 'Entity List',
      //       value: 'partner_list',
      //       url: '/entity/entity-list',
      //       activekey: 'partner-list',
      //       key: 'partner.PartnerList',
      //       langKey: 'menu.partner_list'
      //     }
      //   ]
      // },
    ],
  },
  {
    name: 'Contacts',
    value: 'customer',
    key: 'customer',
    url: '/contacts/contacts-list',
    activekey: 'customer',
    icon: <span className="icon iconfont icon-a-bianzu12-1" />,
    langKey: 'Customer Service',
    // children: [
    //   {
    //     name: 'My Pet Owner',
    //     value: 'my_pet_owner',
    //     url: '/consumer/consumer-list',
    //     activekey: 'consumer-list',
    //     key: 'consumer.consumerList',
    //     langKey: 'menu.my_pet_owner',
    //   },
    // ]
  },
  {
    name: 'Insights',
    value: 'insights',
    url: '',
    key: 'insights',
    activekey: 'insights',
    icon: <span className='icon iconfont text-[18px] icon-insight' />,
    langKey: 'menu.insights',
    children: [{
      name: 'Customer Insights',
      value: 'consumerInsights',
      url: '/insights/consumer-insights-list',
      activekey: 'consumer-insights-list',
      key: 'insights.insights',
      langKey: 'Customer Insights',
    },
    {
      name: 'Tag Management',
      value: 'tagManagement',
      url: '',
      key: 'tagManagement',
      activekey: 'tag-management',
      langKey: 'menu.tagManagement',
      children: [
        {
          name: 'Tags List',
          value: 'tagsList',
          url: '/tag-management/list',
          key: 'tagManagement.tagsList',
          activekey: 'list',
          langKey: 'menu.tagsList',
        },
        {
          name: 'Add New Tags',
          value: 'addNewTags',
          activekey: 'add',
          url: '/tag-management/add',
          key: 'tagManagement.addNewTags',
          langKey: 'menu.addNewTags',
        },
      ]
    },
    {
      name: 'Segments Management',
      value: 'segmentsManagement',
      url: '',
      key: 'segmentsManagement',
      activekey: 'segments',
      langKey: 'menu.segmentsManagement',
      children: [
        {
          name: 'Segments List',
          value: 'segmentsList',
          url: '/segments/segments-list',
          key: 'segmentsManagement.segmentsList',
          activekey: 'segments-list',
          langKey: 'menu.segmentsList',
        },
        {
          name: 'Add New Segments',
          value: 'addNewSegments',
          url: '/segments/segments-add',
          activekey: 'segments-add',
          key: 'segmentsManagement.segments-add',
          langKey: 'menu.addNewSegments',
        }
      ]
    }]
  },
  {
    name: 'Contents',
    value: 'contents',
    url: '',
    key: 'contents',
    activekey: 'contents',
    icon: <span className='icon iconfont text-xl icon-contentype' />,
    langKey: 'menu.contents',
    children: [
      {
        name: 'Pages',
        value: 'pages',
        url: '',
        key: 'pages',
        activekey: 'cms-pages',
        // icon: <span className='icon iconfont text-xl icon-pages' />,
        langKey: 'menu.cmsPages',
        children: [
          {
            name: 'Page List',
            value: 'pageList',
            url: '/cms-pages/list',
            key: 'pages.pageList',
            activekey: 'cms-page-list',
            langKey: 'menu.cmsPages.pageList',
          },
          {
            name: 'Add New Page',
            value: 'addNewPage',
            url: '/cms-pages/detail',
            key: 'pages.addNewPage',
            activekey: 'cms-page-add-new',
            langKey: 'menu.cmsPages.addNewPage',
          }
        ]
      },
      {
        name: 'Collections',
        value: 'collections',
        url: '/cms-collections',
        activekey: 'cms-collections',
        key: 'collections',
        icon: <span className='icon iconfont text-xl icon-collections1' />,
        langKey: 'menu.cmsCollections',
        // children: []
      },
      {
        name: 'Content Types',
        value: 'contentTypes',
        url: '/cms-contentTypes',
        activekey: 'cms-contentTypes',
        key: 'contentTypes',
        icon: <span className='icon iconfont text-xl icon-contentype' />,
        langKey: 'menu.contentTypes',
        // children: []
      },
    ]
  },
  {
    name: 'Campaign',
    value: 'campaign',
    url: '/campaign-plan',
    key: "campaign",
    activekey: 'campaign-plan',
    icon: <span className="icon iconfont text-xl icon-a-compaignplan" />,
    langKey: 'menu.Campaign plan'
  },

  // {
  //   name: 'Smart Operation',
  //   value: 'Smart Operation',
  //   url: '/smart-operation',
  //   key: 'smartOperation',
  //   activekey: 'smart-operation',
  //   icon: <span className="icon iconfont text-xl icon-a-smartopersation" />,
  //   langKey: 'menu.Smart Operation',
  //   children: [
  //     {
  //       name: 'Quick Task',
  //       value: 'Quick Task',
  //       activekey: 'quick-task',
  //       url: '/smart-operation/quick-task',
  //       key: 'smartOperation.quickTask',
  //       langKey: 'menu.Quick Task',
  //     },
  //     {
  //       name: 'Process Canvas',
  //       value: 'Process Canvas',
  //       activekey: 'process-canvas',
  //       url: '/smart-operation/process-canvas',
  //       key: 'smartOperation.processCanvas',
  //       langKey: 'menu.Process Canvas',
  //     }
  //   ],
  // },

  {
    name: 'Message Center',
    value: 'messageCenter',
    url: '/message-center',
    key: 'messageCenter',
    langKey: 'menu.messageCenter',
    icon: <span className="icon iconfont text-xl icon-Frame7" />,
    children: [
      {
        name: 'Message Center',
        value: 'messagelist',
        url: '/message-center/message-list',
        key: 'messagelist',
        langKey: 'menu.messagelist',
      }
    ]
  },

  {
    name: 'Digital Assets',
    value: 'digitalAssets',
    url: '/digital-assets',
    activekey: 'digital-assets',
    key: 'digitalAssets',
    icon: <span className="icon iconfont text-xl icon-assets" />,
    langKey: 'menu.digitalAssets',
    children: [
      {
        name: 'Media Library',
        value: 'mediaLibrary',
        url: '/digital-assets/media-library',
        activekey: 'cms-assets',
        key: 'assets.mediaLibrary',
        langKey: 'assets.mediaLibrary'
      },
    ]
  },

  {
    name: 'Settings',
    value: 'templateManagement',
    url: '/template-management',
    activekey: 'template-management',
    key: 'templates',
    icon: <span className="icon iconfont text-xl icon-bulkadd-copy" />,
    langKey: 'menu.templateManagement',
    children: [
      {
        name: 'Data Management',
        value: 'dataManagement',
        url: '',
        key: 'dataManagement',
        activekey: 'data-management',
        langKey: 'menu.dataManagement',
        children: [{
          name: 'Meta Data',
          value: 'metaData',
          url: '',
          key: 'dataManagement.metaData',
          activekey: 'meta-data',
          langKey: 'menu.metaData',
          children: [{
            name: 'General Events',
            value: 'generalEvents',
            url: '/data-management/meta-data/general-events',
            activekey: 'general-events',
            key: 'dataManagement.events',
            langKey: 'menu.events',
          }, {
            name: 'Event attribute',
            value: 'eventAttribute',
            url: '/data-management/meta-data/event-attribute',
            activekey: 'event-attribute',
            key: 'dataManagement.eventattributes',
            langKey: 'menu.eventAttributes',
          }, {
            name: 'Principal Attribute',
            value: 'principalAttribute',
            url: '/data-management/meta-data/principal-attribute',
            activekey: 'principal-attribute',
            key: 'dataManagement.principalattributes',
            langKey: 'menu.principalAttributes',
          }, {
            name: 'Transaction Attribute',
            value: 'transactionAttribute',
            activekey: 'transaction-attribute',
            url: '/data-management/meta-data/transaction-attribute',
            key: 'dataManagement.transactionAttribute',
            langKey: 'menu.transactionAttributes',
          }]
        }, {
          name: 'Data Ingestion',
          value: 'dataIngestion',
          url: '/data-management/data-ingestion',
          activekey: 'data-ingestion',
          key: 'dataManagement.dataIngestion',
          langKey: 'menu.dataIngestion',
        }, {
          name: 'ID Mapping',
          value: 'idMapping',
          url: '/data-management/id-mapping',
          activekey: 'id-mapping',
          key: 'dataManagement.idMapping',
          langKey: 'menu.idMapping',
        }]
      },
      {
        name: 'Email',
        value: 'emailList',
        url: '/template-management/email-list',
        key: 'templates.emailTemplate',
        langKey: 'menu.emailList',
        activekey: 'email-list',
      },
      {
        name: 'SMS',
        value: 'smsList',
        url: '/template-management/sms-list',
        key: 'templates.smsTemplate',
        langKey: 'menu.smsList',
        activekey: 'sms-list',
      },
      {
        name: 'WeCom Setting',
        value: 'wechatSetting',
        url: '/template-management/wechat-setting',
        key: 'templates.wechatSetting',
        langKey: 'menu.wechatSetting',
        activekey: 'wechat-setting',
      }
    ],
  },
]

// export const initActive = (pathname: string) => {
//   let selectedKeys: string[] = []
//   menus.forEach((menu) => {
//     menu.children.forEach((subMenu) => {
//       if (subMenu.url === pathname) {
//         selectedKeys = [menu.key, subMenu.key]
//       }
//     })
//   })
//   return selectedKeys
// }
