import React, { useState } from 'react';
import { Form, Input, Radio, Checkbox, Button, Space, Typography, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { createTemporaryData } from '@/framework/api/temporaryData';

const { Title, Paragraph } = Typography;

const Survey = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const onFinish = (values: any) => {
        createTemporaryData({ type: 'starSurvey', key: values.phone + 'startSurvey', value: JSON.stringify(values) }).then(() => {
            message.success('感谢您的反馈!')
            form.resetFields()
        }).catch(() => {
            message.error('当前网络不稳定，请稍后再试')
        })
    };

    return (
        <div className="max-w-3xl mx-auto p-8">
            <Typography>
                <Title level={2}>客户满意度调查</Title>
                <Paragraph>感谢您参与本次调查，您的反馈对我们非常重要。</Paragraph>
            </Typography>

            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item
                    label="您的姓名"
                    name="name"
                    rules={[{ required: true, message: '请输入您的姓名' }]}
                >
                    <Input placeholder="请输入您的姓名" />
                </Form.Item>

                <Form.Item
                    label="您的电话号码"
                    name="phone"
                    rules={[
                        { required: true, message: '请输入您的电话号码' },
                        { pattern: /^1[3-9]\d{9}$/, message: '请输入有效的手机号码' }
                    ]}
                >
                    <Input placeholder="请输入您的电话号码" />
                </Form.Item>

                <Form.Item
                    label="您的年龄段"
                    name="ageGroup"
                    rules={[{ required: true, message: '请选择您的年龄段' }]}
                >
                    <Radio.Group>
                        <Space direction="vertical">
                            <Radio value="18-25">18-25岁</Radio>
                            <Radio value="26-35">26-35岁</Radio>
                            <Radio value="36-45">36-45岁</Radio>
                            <Radio value="46+">46岁以上</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label="您最关注的产品特性是什么？"
                    name="productFeatures"
                    rules={[{ required: true, message: '请至少选择一项产品特性' }]}
                >
                    <Checkbox.Group>
                        <Space direction="vertical">
                            <Checkbox value="quality">产品质量</Checkbox>
                            <Checkbox value="price">价格合理性</Checkbox>
                            <Checkbox value="service">客户服务</Checkbox>
                            <Checkbox value="design">产品设计</Checkbox>
                        </Space>
                    </Checkbox.Group>
                </Form.Item>

                <Form.Item
                    label="您对我们的产品有什么建议？"
                    name="suggestions"
                >
                    <Input.TextArea rows={4} placeholder="请输入您的建议" />
                </Form.Item>

                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit">
                            提交问卷
                        </Button>
                        <Button onClick={() => form.resetFields()}>
                            重置
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Survey;
